import { useAtomValue } from 'jotai';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useProxyList } from '../../../../state/proxy/proxy-list.atom';
import { IProfileProxy } from '../../../proxy/components/interfaces/IProfileProxy';
import { ITableCell } from '../../interfaces';
import { TextCell } from '../../table/styles';

const getFormatProxyMode = (proxyMode: string, translation: any): string => {
  if (!proxyMode || proxyMode === 'none') {
    return '';
  }

  if (['http', 'socks4', 'socks5'].includes(proxyMode)) {
    return proxyMode;
  }

  if (proxyMode === 'http') {
    return translation('proxy.connection.http.title');
  }

  if (proxyMode === 'socks4' || proxyMode === 'socks5') {
    return translation(`proxy.connection.${proxyMode}.title`);
  }

  if (proxyMode === 'gologin') {
    return 'GoLogin';
  }

  if (proxyMode === 'tor') {
    return translation('proxy.connection.tor.title');
  }

  return proxyMode[0]?.toUpperCase() + proxyMode?.slice(1);
};

const renderProxy = (proxy: IProfileProxy, translation: any): string => {
  const proxyMode = getFormatProxyMode(proxy.mode, translation);

  if (!proxyMode) {
    return '-';
  }

  if (!proxy.country) {
    if (proxy.mode === 'tor') {
      proxy.country = proxy.torProxyRegion;
    } else if (proxy.mode === 'gologin') {
      proxy.country = proxy.autoProxyRegion;
    }
  }

  if (!proxy.country) {
    return proxyMode;
  }

  return proxyMode + ' • ' + proxy.country.toUpperCase();
};

const ProxyTypeColumn: FC<ITableCell> = (props) => {
  const { profileAtom } = props;

  let { proxy } = useAtomValue(profileAtom);
  const proxyList = useProxyList();

  const { t: translation } = useTranslation();

  let proxyRes = proxy;
  if (proxyRes.id) {
    proxyRes = proxyList.find((listedProxy) => listedProxy.id === proxyRes.id);
    if (!proxyRes) {
      proxyRes = proxy;
    }
  }

  proxy = proxyRes;

  return (
    <TextCell>
      {renderProxy(proxy, translation)}
    </TextCell>
  );
};

export default ProxyTypeColumn;
