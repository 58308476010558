import { sendActionAnalytics } from '../../../features/common/api';
import { IProfileProxy } from '../../../features/proxy/components/interfaces/IProfileProxy';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { EMPTY_PROXY } from '../../../features/proxy/proxy-helpers';
import { updateProfileProxy } from '../../../features/quickProfiles/api';
import { IProfile } from '../../../interfaces';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../../profiles-list.atom';
import { updateProxySelectionDate } from '../proxy-groups/proxy-selection-dates.atom';
import { decrementProxyProfilesCount, incrementProxyProfilesCount } from '../proxy-list.atom';

export const selectProfileProxyInState = (profileId: string, proxy: IProfileProxy, profilesListArg?: IProfile[]): IProfileProxy|null => {
  const profilesList = profilesListArg || getProfilesList();

  const profileInfo = profilesList.find((profile) => profile.id === profileId);
  const prevProfileProxyId = profileInfo?.proxy?.id || '';
  const noNeedToUpdate = prevProfileProxyId === (proxy.id || ' ');
  if (!profileInfo || noNeedToUpdate) {
    return null;
  }

  if (proxy.id) {
    updateProxySelectionDate(proxy);
    incrementProxyProfilesCount(proxy?.id);
  }

  if (prevProfileProxyId) {
    decrementProxyProfilesCount(prevProfileProxyId);
  }

  let profileProxy: IProfileProxy|null = null;
  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    if (profile.id !== profileId) {
      return profile;
    }

    const proxyToAdd: IProxy = {
      id: proxy.id,
      customName: proxy.customName,
      mode: proxy.mode,
      host: proxy.host,
      port: proxy.port,
      username: proxy.username,
      password: proxy.password,
      country: proxy.country,
      city: proxy.city,
      autoProxyRegion: proxy.autoProxyRegion,
      torProxyRegion: proxy.torProxyRegion,
      profilesCount: proxy.profilesCount || 0,
    };

    profileProxy = proxyToAdd;

    return {
      ...profile,
      proxyType: proxy.mode,
      proxyEnabled: proxy.mode !== 'none',
      proxy: proxyToAdd,
    };
  }));

  return profileProxy;
};

const selectProfileProxyInBackend = async (profileId: string, proxy: IProfileProxy, profilesListArg?: IProfile[]): Promise<void> => {
  const profilesList = profilesListArg || getProfilesList();
  await updateProfileProxy(profileId, proxy).catch(() => setProfilesList(profilesList));
  sendActionAnalytics('chose proxy via proxy manager');
};

export const selectProfileProxy = async (profileId: string, proxy: IProfileProxy): Promise<void> => {
  const profilesList = getProfilesList();
  const profileProxy = selectProfileProxyInState(profileId, proxy, profilesList);
  if (!profileProxy) {
    return;
  }

  await selectProfileProxyInBackend(profileId, proxy, profilesList);
};

export const unselectProfileProxyInState = (profileId: string): void => {
  selectProfileProxyInState(profileId, EMPTY_PROXY);
};

export const unselectProfileProxy = (profileId: string): Promise<void> => selectProfileProxy(profileId, EMPTY_PROXY);
