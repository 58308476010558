/* eslint-disable max-lines */
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Button, Icon, Input, Select, Tooltip } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader, { TLoaderStatus } from './Loader';
import IconCopyProxyInDrawer from '../../../ui/icons/IconCopyProxyInDrawer';
import IconSelectProxyInDrawer from '../../../ui/icons/IconSelectProxyInDrawer';
import { getCheckProxy } from '../../../utils/check-proxy';
import { updateProfileProxy } from '../../quickProfiles/api';

const LoaderDiv = styled('div')`
  display: inline-block;
  margin-left: 10px;
  margin-top: 6px;
`;

const ProxyCheckResultDiv = styled('div')<{ fontColor: string }>`
  margin-left: 10px;
  margin-top: 6px;
  ${props => css`color: ${props.fontColor};`}
`;

const CountryFlagContainer = styled('div')`
  margin-left: 10px;
  display: inline;
  cursor: alias;
`;

const ArrowDrawer = styled('div')`
  border: solid var(--01AD7F);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  opacity: 1;
  cursor: pointer;
`;

const IconClearForm = styled(Icon)`
  color: var(--ACACAC);
  :hover {
    color: var(--00A987);
    cursor: pointer;
  }
`;

const ElementContainer = styled('div')`
  font-family: 'Roboto';
  margin-top: 10px;
  margin-bottom: 22px;
  margin-left: 12px;
`;

const TitleTooltipIcon = styled('span')`
  font-size: 12px;
  color: var(--666666);
  font-family: 'Roboto';
  font-weight: 700;
`;

const TitleTooltipIconClear = styled('span')`
  font-size: 12px;
  opacity: 0.6;
`;

const ContainerTooltipIconClear = styled('span')`
  padding-top: 6px;
  padding-left: 14px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 22px;
  margin-left: 36px;
`;

const TittleSetting = styled('div')`
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 20px;
  color: var(--000000);
  margin-left: 12px;
  margin-top: -2px;
`;

const TittleInput = styled('div')`
  font-weight: 500;
  font-size: 12px;
  color: var(--000000);
  margin-left: 12px;
  padding-top: 6px;
`;

const ContainerIcon = styled('div')`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
`;

const PROXY_CHECKED_INIT = {
  status: '',
  origin: '',
  country: '',
  timezone: '',
  error: '',
};

declare interface IProxyChecked {
  status: string;
  origin: string;
  country: string;
  timezone: string;
  error: string;
}

const PROXY_SETTINGS = {
  mode: '',
  host: '',
  port: 80,
  username: '',
  password: '',
  changeIpUrl: '',
  autoProxyRegion: '',
  torProxyRegion: '',
  wasSaved: false,
};

let saveTimeout: any;
let proxyUpdateCounter = 0;
let loaderStatusTimeout: any;

declare interface IProfileDrawerProxy {
  profileInfo?: any;
  changeVisibility: () => void;
  updateProfileInfoProxy: (id: string, proxy: any) => any;
  setPasteProxy: () => any;
  showProxyManager: () => any;
}

export const DrawerProxy: FC<IProfileDrawerProxy> = (props) => {
  const [loaderStatus, setLoaderStatus] = useState<TLoaderStatus>('default');
  const [isLoading, changeLoading] = useState<boolean>(false);
  const [checkButtonDisabled, setCheckButtonDisabled] = useState<boolean>(false);
  const [proxyChecked, setProxyChecked] = useState<IProxyChecked>(PROXY_CHECKED_INIT);
  const [modeState, setModeState] = useState<string>(PROXY_SETTINGS.mode);
  const [username, setUsername] = useState<string>(PROXY_SETTINGS.username);
  const [password, setPassword] = useState<string>(PROXY_SETTINGS.password);
  const [changeIpUrl, setChangeIpUrl] = useState<string>(PROXY_SETTINGS.changeIpUrl);
  const [host, setHost] = useState<string>(PROXY_SETTINGS.host);
  const [port, setPort] = useState<number>(PROXY_SETTINGS.port);
  const [isProxyCheckInterrupted, setIsProxyCheckInterrupted] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (isProxyCheckInterrupted) {
      changeLoading(false);
      setCheckButtonDisabled(false);
    }
  }, [isProxyCheckInterrupted]);

  const checkProxy = async () => {
    changeLoading(true);
    setCheckButtonDisabled(true);
    setIsProxyCheckInterrupted(false);
    setProxyChecked(PROXY_CHECKED_INIT);

    const result = await getCheckProxy(props.profileInfo);

    setProxyChecked(result);
    changeLoading(false);
    setCheckButtonDisabled(false);
  };

  const getLoader = () => {
    if (isLoading) {
      return (
        <LoaderDiv>
          <Icon type='loading' />
        </LoaderDiv>
      );
    }

    if (proxyChecked?.status && !isProxyCheckInterrupted) {
      const proxyCheckedStatus = proxyChecked.status;
      if (proxyCheckedStatus === 'success') {
        return (
          <ProxyCheckResultDiv fontColor='green'>
            Success
            <Tooltip title={`IP: ${proxyChecked.origin}. ${translation('base.country')}: ${proxyChecked.country}. ${translation('base.timezone')}: ${proxyChecked.timezone}`}>
              <CountryFlagContainer>
                {getUnicodeFlagIcon(proxyChecked.country)}
              </CountryFlagContainer>
            </Tooltip>
          </ProxyCheckResultDiv>
        );
      }

      return (
        <ProxyCheckResultDiv fontColor='red'>
          {proxyChecked.error}
        </ProxyCheckResultDiv>
      );
    }

    return null;
  };

  const clearProxy = () => {
    PROXY_SETTINGS.username = '';
    PROXY_SETTINGS.password = '';
    PROXY_SETTINGS.host = '';
    PROXY_SETTINGS.port = 80;
    PROXY_SETTINGS.changeIpUrl = '';
    PROXY_SETTINGS.autoProxyRegion = 'us';
    PROXY_SETTINGS.torProxyRegion = 'us';
    PROXY_SETTINGS.wasSaved = true;

    setUsername(PROXY_SETTINGS.username);
    setPassword(PROXY_SETTINGS.password);
    setChangeIpUrl(PROXY_SETTINGS.changeIpUrl);
    setPort(PROXY_SETTINGS.port);
    setHost(PROXY_SETTINGS.host);

    saveProxy();
  };

  const setPasteProxy = async () => {
    setIsProxyCheckInterrupted(true);
    const dataProxy = await props.setPasteProxy();
    PROXY_SETTINGS.username = dataProxy.username || '';
    PROXY_SETTINGS.password = dataProxy.password || '';
    PROXY_SETTINGS.mode = dataProxy.mode || '';
    PROXY_SETTINGS.host = dataProxy.host || '';
    PROXY_SETTINGS.port = dataProxy.port || '80';
    PROXY_SETTINGS.wasSaved = true;

    setModeState(dataProxy.mode || '');
    setUsername(PROXY_SETTINGS.username);
    setPassword(PROXY_SETTINGS.password);
    setPort(PROXY_SETTINGS.port);
    setHost(PROXY_SETTINGS.host);
  };

  const saveProxy = async () => {
    if (PROXY_SETTINGS.host.replace(/[' ']/g, '') || ['none', 'gologin', 'tor'].includes(PROXY_SETTINGS.mode)) {
      clearTimeout(loaderStatusTimeout);
      setLoaderStatus('processing');

      loaderStatusTimeout = setTimeout(() => {
        setLoaderStatus('done');
        loaderStatusTimeout = setTimeout(() => setLoaderStatus('default'), 0.5 * 1000);
      }, 0.5 * 1000);

      props.updateProfileInfoProxy(props.profileInfo.id, {
        mode: PROXY_SETTINGS.mode,
        password: PROXY_SETTINGS.password || '',
        username: PROXY_SETTINGS.username || '',
        host: PROXY_SETTINGS.host || '',
        changeIpUrl: PROXY_SETTINGS.changeIpUrl || '',
        port: +PROXY_SETTINGS.port || 80,
        autoProxyRegion: PROXY_SETTINGS.autoProxyRegion,
        torProxyRegion: PROXY_SETTINGS.torProxyRegion,
      });

      await updateProfileProxy(props.profileInfo.id, {
        mode: (PROXY_SETTINGS.mode as any),
        password: PROXY_SETTINGS.password || '',
        username: PROXY_SETTINGS.username || '',
        changeIpUrl: PROXY_SETTINGS.changeIpUrl || '',
        host: PROXY_SETTINGS.host || '',
        port: +PROXY_SETTINGS.port || 80,
        autoProxyRegion: PROXY_SETTINGS.autoProxyRegion,
        torProxyRegion: PROXY_SETTINGS.torProxyRegion,
      });
    }
  };

  const updateSaveTimeout = () => {
    if (saveTimeout !== undefined) {
      clearTimeout(saveTimeout);
    }

    saveTimeout = setTimeout(() => {
      saveProxy();
    }, 500);
  };

  const onModeChanged = (mode: string) => {
    PROXY_SETTINGS.mode = mode;
    PROXY_SETTINGS.wasSaved = true;
    setModeState(mode);
    saveProxy();
  };

  const onHostChanged = (e: ChangeEvent<HTMLInputElement>) => {
    PROXY_SETTINGS.host = e.target.value || '';
    PROXY_SETTINGS.wasSaved = true;
    setHost(e.target.value);
    updateSaveTimeout();
  };

  const onPortChanged = (e: any) => {
    PROXY_SETTINGS.port = e.target.value || '';
    PROXY_SETTINGS.wasSaved = true;
    setPort(e.target.value);
    updateSaveTimeout();
  };

  const onUsernameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    PROXY_SETTINGS.username = e.target.value || '';
    PROXY_SETTINGS.wasSaved = true;
    setUsername(e.target.value);
    updateSaveTimeout();
  };

  const onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    PROXY_SETTINGS.password = e.target.value || '';
    PROXY_SETTINGS.wasSaved = true;
    setPassword(e.target.value);
    updateSaveTimeout();
  };

  const onChangeIpUrlChanged = (e: ChangeEvent<HTMLInputElement>) => {
    PROXY_SETTINGS.changeIpUrl = e.target.value || '';
    PROXY_SETTINGS.wasSaved = true;
    setChangeIpUrl(e.target.value);
    updateSaveTimeout();
  };

  const onAutoProxyRegionChanged = (selectedRegionGologin: string) => {
    PROXY_SETTINGS.autoProxyRegion = selectedRegionGologin;
    PROXY_SETTINGS.wasSaved = true;
    saveProxy();
  };

  const onTorProxyRegionChanged = (selectedRegionTor: string) => {
    PROXY_SETTINGS.torProxyRegion = selectedRegionTor;
    PROXY_SETTINGS.wasSaved = true;
    saveProxy();
  };

  const setIconPasteProxy = () => (
    <Tooltip title={<TitleTooltipIcon>
      {translation('editProfile.proxy.paste.title')}
      <br />
      <TitleTooltipIcon style={{ fontWeight: 400 }}>
        {translation('editProfile.proxy.paste.contentText')}
        <br />
        <br />
        {translation('editProfile.proxy.paste.help')}
      </TitleTooltipIcon>
                    </TitleTooltipIcon>}>
      <div
        onClick={setPasteProxy}
        style={{ marginLeft: 14 }}
      >
        <IconCopyProxyInDrawer />
      </div>
    </Tooltip>
  );

  const setIconExistingProxy = () => (
    <Tooltip title={<TitleTooltipIcon >
      {translation('proxyManager.title')}
      <br />
      <TitleTooltipIcon style={{ fontWeight: 400 }}>
        {translation('proxyManager.help')}
      </TitleTooltipIcon>
                    </TitleTooltipIcon>}>
      <div
        style={{ marginLeft: 13 }}
        onClick={() => props.showProxyManager()}
      >
        <IconSelectProxyInDrawer />
      </div>
    </Tooltip>
  );

  useEffect(() => {
    PROXY_SETTINGS.username = props?.profileInfo?.proxy?.username || '';
    PROXY_SETTINGS.password = props?.profileInfo?.proxy?.password || '';
    PROXY_SETTINGS.mode = props?.profileInfo?.proxy?.mode || '';
    PROXY_SETTINGS.host = props?.profileInfo?.proxy?.host || '';
    PROXY_SETTINGS.changeIpUrl = props?.profileInfo?.proxy?.changeIpUrl || '';
    PROXY_SETTINGS.port = props?.profileInfo?.proxy?.port || '80';
    PROXY_SETTINGS.autoProxyRegion = props?.profileInfo?.proxy?.autoProxyRegion || 'us';
    PROXY_SETTINGS.torProxyRegion = props?.profileInfo?.proxy?.torProxyRegion || 'us';
    PROXY_SETTINGS.wasSaved = !!proxyUpdateCounter;
    setModeState(props?.profileInfo?.proxy?.mode || '');
    setUsername(PROXY_SETTINGS.username);
    setPassword(PROXY_SETTINGS.password);
    setHost(PROXY_SETTINGS.host);
    setPort(PROXY_SETTINGS.port);
    setChangeIpUrl(PROXY_SETTINGS.changeIpUrl);

    // Re-render input fields on proxy change
    if (
      ['http', 'socks4', 'socks5', 'geolocation'].includes(modeState)
        && username
        && password
        && port
        && host
    ) {
      setUsername(PROXY_SETTINGS.username);
      setPassword(PROXY_SETTINGS.password);
      setPort(PROXY_SETTINGS.port);
      setHost(PROXY_SETTINGS.host);
      setChangeIpUrl(PROXY_SETTINGS.changeIpUrl);
    }

    proxyUpdateCounter++;
  }, [props.profileInfo]);

  const renderProxyCredentials = () => (
    <>
      <TittleInput>
        {translation('editProfile.proxy.hostAndPortLabel')}
      </TittleInput>
      <ElementContainer style={{ paddingBottom: 2 }}>
        <Input.Group compact={true} style={{ width: 326 }}>
          <Input
            value={host.replace(/[' ']/g, '')}
            placeholder={translation('editProfile.proxy.hostInputPlaceholder')}
            style={{ width: 190 }}
            defaultValue={props?.profileInfo?.proxy?.host}
            onChange={onHostChanged}
          />
          <Input
            style={{
              width: 10,
              padding: 3,
              borderLeft: 0,
              pointerEvents: 'none',
              backgroundColor: 'var(--FFFFFF)',
            }}
            placeholder=':'
            disabled={true}
          />
          <Input
            value={port}
            placeholder={translation('editProfile.proxy.portInputPlaceholder')}
            style={{ width: 78, borderLeft: 0 }}
            defaultValue={props?.profileInfo?.proxy?.port}
            onChange={onPortChanged}
          />
          <ContainerTooltipIconClear>
            <Tooltip title={<TitleTooltipIconClear>
              {translation('base.cleanForm')}
                            </TitleTooltipIconClear>}>
              <IconClearForm type='delete' style={{ fontSize: 18, cursor: 'pointer' }} onClick={clearProxy} />
            </Tooltip>
          </ContainerTooltipIconClear>
        </Input.Group>
      </ElementContainer>
      <TittleInput>
        {translation('base.login')}
      </TittleInput>
      <ElementContainer>
        <Input
          value={username}
          placeholder={translation('editProfile.proxy.loginInputPlaceholder')}
          style={{ width: 278 }}
          defaultValue={props?.profileInfo?.proxy?.username}
          onChange={onUsernameChanged}
        />
      </ElementContainer>
      <TittleInput>
        {translation('base.password')}
      </TittleInput>
      <ElementContainer>
        <Input
          value={password}
          placeholder={translation('editProfile.proxy.passwordInputPlaceholder')}
          style={{ width: 278 }}
          defaultValue={props?.profileInfo?.proxy?.password}
          onChange={onPasswordChanged}
        />
      </ElementContainer>

      <TittleInput>
        {translation('proxyForm.changeIPURL')}
      </TittleInput>
      <ElementContainer style={{ marginBottom: 20 }}>
        <Input
          value={changeIpUrl}
          placeholder={translation('proxyForm.changeIP')}
          style={{ width: 278 }}
          defaultValue={props?.profileInfo?.proxy?.changeIpUrl}
          onChange={onChangeIpUrlChanged}
        />
      </ElementContainer>
    </>
  );

  const renderAutoProxyRegion = () => (
    <ElementContainer>
      <Select
        onSelect={onAutoProxyRegionChanged}
        defaultValue={props?.profileInfo?.proxy?.autoProxyRegion || 'any'}
        style={{ width: 278 }}
      >
        <Select.Option value='us'>
          US
        </Select.Option>
        <Select.Option value='ca'>
          CA
        </Select.Option>
        <Select.Option value='uk'>
          UK
        </Select.Option>
        <Select.Option value='de'>
          DE
        </Select.Option>
        <Select.Option value='in'>
          IN
        </Select.Option>
      </Select>
    </ElementContainer>
  );

  const renderTorProxyRegion = () => (
    <ElementContainer>
      <Select
        onSelect={onTorProxyRegionChanged}
        defaultValue={props?.profileInfo?.proxy?.torProxyRegion || 'any'}
        style={{ width: 278 }}
      >
        <Select.Option value='us'>
          US
        </Select.Option>
        <Select.Option value='uk'>
          UK
        </Select.Option>
        <Select.Option value='de'>
          DE
        </Select.Option>
        <Select.Option value='fr'>
          FR
        </Select.Option>
        <Select.Option value='eu'>
          EU
        </Select.Option>
      </Select>
    </ElementContainer>
  );

  const showContent = () => (
    <>
      <TitleContainer>
        <ArrowDrawer onClick={props.changeVisibility} />
        <TittleSetting>
          {translation('base.proxyType')}
        </TittleSetting>
        <Loader
          state={loaderStatus}
        />
      </TitleContainer>
      <ElementContainer style={{ marginLeft: 12, paddingTop: 6 }}>
        <TittleInput>
          {translation('proxy.connection.title')}
        </TittleInput>
        <ElementContainer style={{ paddingBottom: 2, display: 'flex', flexDirection: 'row' }}>
          <Select
            style={{ width: 278 }}
            value={modeState}
            onChange={onModeChanged}
          >
            <Select.Option value='none'>
              {translation('proxy.connection.none.title')}
            </Select.Option>
            <Select.Option value='gologin'>
              {translation('proxy.connection.gologin.title')}
            </Select.Option>
            <Select.Option value='tor'>
              {translation('proxy.connection.tor.title')}
            </Select.Option>
            <Select.Option value='http'>
              {translation('proxy.connection.http.title')}
            </Select.Option>
            <Select.Option value='socks4'>
              {translation('proxy.connection.socks4.title')}
            </Select.Option>
            <Select.Option value='socks5'>
              {translation('proxy.connection.socks5.title')}
            </Select.Option>
          </Select>
          <ContainerIcon>
            {setIconPasteProxy()}
            {setIconExistingProxy()}
          </ContainerIcon>
        </ElementContainer>

        {['http', 'socks4', 'socks5', 'geolocation'].includes(modeState) && renderProxyCredentials()}
        {modeState === 'gologin' && renderAutoProxyRegion()}
        {modeState === 'tor' && renderTorProxyRegion()}

        {['http', 'socks4', 'socks5', 'geolocation'].includes(props?.profileInfo?.proxy?.mode) && (
          <>
            <ElementContainer style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{
                  cursor: 'pointer',
                  fontSize: 12 ,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                type='primary'
                disabled={checkButtonDisabled}
                onClick={checkProxy}
              >
                {translation('editProfile.proxy.checkProxyBtnText')}
              </Button>
              {getLoader()}
            </ElementContainer>
          </>
        )}
      </ElementContainer>
    </>
  );

  return (
    <>
      {showContent()}
      {/* {props.showProxyManager()}*/}
    </>
  );
};
