import { message } from 'antd';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  ProxyGroupExpandButton,
  ProxyGroupExpandButtonWrapper,
  ProxyGroupHeaderWrapper,
  ProxyGroupHint,
  ProxyGroupTitle,
} from './styles';
import { GeoProxyType } from '../../../../../../common/constants/types';
import { getGeoProxyLastSelectedType } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IGroupedProxy, IProxyGroupHeader as IProxyGroupHeaderEntity } from '../../../../../state/proxy/proxy-groups/interfaces';
import { findUnusedGeoProxy } from '../../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { toggleProxyGroup, useVisibleProxyGroupIds } from '../../../../../state/proxy/proxy-groups/proxy-visible-groups.atom';
import { closeProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { createGeoProxy } from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { selectProfileProxy } from '../../../../../state/proxy/proxy-operations/select-proxies.operations';
import { IconArrowDown } from '../../../../../ui/gologin-header/icons/icon-arrow-down';
import ProxyFlag from '../../../components/proxy-flag';

// eslint-disable-next-line no-shadow
enum GroupHeaderHint {
  seeAllProxies = 'proxies.groupHeaderHint.seeAllProxies',
  selectPrivateProxy = 'proxies.groupHeaderHint.selectPrivateProxy',
}

interface IProxyGroupHeader {
  proxyGroupHeader: IProxyGroupHeaderEntity;
  currentProfileId: string|null;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupHeader: React.FC<IProxyGroupHeader> = (props) => {
  const { proxyGroupHeader, currentProfileId = '', availableTypes, style } = props;

  const visibleProxyGroupIds = useVisibleProxyGroupIds();

  const [groupHeaderHintKey, setGroupHeaderHintKey] = useState<GroupHeaderHint|null>(null);

  const handleMouseOverRow: React.MouseEventHandler<HTMLDivElement> = () =>
    setGroupHeaderHintKey(GroupHeaderHint.selectPrivateProxy);

  const handleMouseOverArrow: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setGroupHeaderHintKey(GroupHeaderHint.seeAllProxies);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => setGroupHeaderHintKey(null);

  const handleGroupRowClick: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    closeProxyManager();
    if (!(currentProfileId && proxyGroupHeader)) {
      return;
    }

    const { groupId } = proxyGroupHeader;
    const unusedGeoProxy = findUnusedGeoProxy(groupId);
    if (unusedGeoProxy) {
      // TODO: share
      const selectedUnusedProxy: IGroupedProxy = { ...unusedGeoProxy, profilesCount: unusedGeoProxy.profilesCount + 1 };
      await Promise.all([
        updateProxyStatuses([selectedUnusedProxy], currentProfileId, false),
        selectProfileProxy(currentProfileId, selectedUnusedProxy),
      ]);

      return;
    }

    const geoproxyTypeAddedLast = getGeoProxyLastSelectedType();
    if (!availableTypes.includes(geoproxyTypeAddedLast)) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      // TODO:
      // sendReactErrorToSentry({
      //   transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      //   message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      //   tags: [
      //     [PROXY_ID_TAG, proxy.id],
      //     [GEOPROXY_TYPE_TAG, proxy.connectionType],
      //     [PROXY_COUNTRY_TAG, proxy.country],
      //     [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      //   ],
      // });

      return;
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country: proxyGroupHeader.country,
      connectionType: geoproxyTypeAddedLast,
      profileId: currentProfileId,
      checkTooltipView: 'selector-profile-table', // TODO: can be other places as well
    });

    if (typeof geoProxyFormSubmitResult === 'string') {
      message.error(geoProxyFormSubmitResult);
    }
  };

  const handleGroupArrowClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    toggleProxyGroup(proxyGroupHeader.groupId);
  };

  return (
    <ProxyGroupHeaderWrapper
      // TODO: needed?
      style={{
        ...style,
        paddingLeft: 0,
        height: 36,
        margin: 4,
      }}
      onClick={handleGroupRowClick}
      onMouseOver={handleMouseOverRow}
      onMouseLeave={handleMouseLeave}
    >
      <ProxyGroupExpandButtonWrapper>
        <ProxyGroupExpandButton
          onClick={handleGroupArrowClick}
          onMouseOver={handleMouseOverArrow}
          isRotated={visibleProxyGroupIds.includes(proxyGroupHeader.groupId)}
        >
          <IconArrowDown
            padding={0}
            styleType='darkGray'
            iconColor='var(--767676-proxy-group-header-arrow)'
          />
        </ProxyGroupExpandButton>
      </ProxyGroupExpandButtonWrapper>
      <ProxyFlag region={proxyGroupHeader.country} />
      <ProxyGroupTitle>
        <Trans i18nKey={`proxy.countries.${proxyGroupHeader.country.toLowerCase()}`} />
      </ProxyGroupTitle>
      {groupHeaderHintKey ? (
        <ProxyGroupHint>
          <Trans i18nKey={groupHeaderHintKey} />
        </ProxyGroupHint>
      ) : null}
    </ProxyGroupHeaderWrapper>
  );
};

export default ProxyGroupHeader;
