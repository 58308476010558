/* eslint-disable max-lines */
import { TFunction } from 'i18next';
import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';

import { NEW_FEATURES } from './feature-toggle/new-features';
import { getFolderById } from './folders/folders-list.atom';
import { resetProfileRunStatuses } from './profile-run-statuses.atom';
import { getProfilesTableProxyIdFilter, resetProfilesTableProxyIdFilter } from './profiles-proxy-filter.atom';
import { closeProfilesSettings } from './profiles-settings-atom';
import { isGroupHeader } from './profiles-table/basic-table-entities-subtypes';
import { getBasicTableProfileIds, useBasicTableEntities } from './profiles-table/basic-table-entities.atom';
import { generateGroupHeaders, isNoEntitiesGroupHeader } from './profiles-table/generate-group-headers';
import { openProfilesTableGroupHeader } from './profiles-table/group-headers.operations';
import { loadGroupsFromLocalStorage, saveGroupsToLocalStorage } from './profiles-table/groups-local-storage';
import { resetProfilesQueryOffset } from './profiles-table/profiles-query';
import { getProfilesTableGroupField } from './profiles-table/profiles-table-group-field.atom';
import { closeProfileTableModal } from './profiles-table-modal.atom';
import { resetProfilesTableSelectedIds, toggleProfilesSelected } from './profiles-table-selected-ids.atom';
import { closeQuickPricing } from './quick-pricing.atom';
import { resetToDefaultSearchState } from './search-state.atom';
import { NEW_PROFILE_PAGE_CUSTOM_STATUS_ID, NEW_PROFILE_PAGE_FOLDER_NAME } from '../../common/constants/constants';
import { IUpdateProfileStatusEvent } from '../../electron/interfaces/profile.status.manager.interfaces';
import { EMPTY_PROXY } from '../features/proxy/constants';
import { IProfilesResponse } from '../features/quickProfiles/api';
import { IArchivedProxyInBrowser, IProfile } from '../interfaces';
import { GroupHeader, GroupHeaderLoadingStatus, GroupFilterType } from '../interfaces/group-header.interface';
import { IWorkspaceProfilePermissions } from '../interfaces/workspaces';
import { ProfileStatusType } from '../types';
import getProfileWorkspaceFolderIds from '../utils/get-profile-workspace-folder-ids';
import { removeArrayDuplicates } from '../utils/remove-array-duplicates';

export const profilesListAtom = atom<IProfile[]>([]);
export const profileAtomListAtom = splitAtom(profilesListAtom);

export const profilesTableGroupHeadersAtom = atom<GroupHeader[]>([]);
export const profilesTableGroupHeadersListAtom = splitAtom(profilesTableGroupHeadersAtom);

export const profilesTableHiddenGroupsAtom = atom<GroupHeader[]>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const initializingGroupIndex = groupHeaders.findIndex((groupHeader) => groupHeader.loadingStatus === 'loading-initiated');

  return groupHeaders.filter((groupHeader, idx) => (!groupHeader.isOpen || idx < initializingGroupIndex) && !isNoEntitiesGroupHeader(groupHeader));
});

export const filterProfileByGroupHeader = (groupHeader: GroupHeader, profile: IProfile): boolean => {
  const { filter: groupFilter } = groupHeader;
  const { type } = groupFilter;

  switch (type) {
    case 'custom-status':
      if (!groupFilter.customStatusId) {
        return !profile.tags.find(tag => tag.field === type);
      }

      return !!profile.tags.find(tag => tag.field === type && tag.id === groupFilter.customStatusId);
    case 'folder': {
      const profileFolderIds = getProfileWorkspaceFolderIds(profile);
      if (!groupFilter.folderId) {
        return !profileFolderIds.length;
      }

      return !!profileFolderIds.find(folderId => folderId === groupFilter.folderId);
    }
    default:
      console.warn(`unrecognized group type: ${type}`);

      return false;
  }
};

export const groupProfilesTable = (groupBy?: GroupFilterType | null): void => {
  if (!groupBy) {
    return;
  }

  const groupHeaders = generateGroupHeaders(groupBy);
  if (!groupHeaders) {
    return;
  }

  setProfilesTableGroupHeaders(groupHeaders);
};

export const reconcileGroups = (): void => {
  const groupBy = getProfilesTableGroupField();
  const prevGroupHeaders = getProfilesTableGroupHeaders();
  const generatedGroupHeaders = generateGroupHeaders(groupBy);
  if (!generatedGroupHeaders) {
    return;
  }

  const newGroupHeaders = generatedGroupHeaders.map((generatedGroupHeader) => {
    const prevGroupHeader = prevGroupHeaders.find(({ id }) => id === generatedGroupHeader.id);
    let groupStatusFields = { ...prevGroupHeader };
    if (!prevGroupHeader) {
      const createdGroupProfiles = getProfilesByGroupHeaderObj(generatedGroupHeader);
      groupStatusFields = { totalProfiles: createdGroupProfiles.length, loadingStatus: 'loaded' };
    }

    return {
      ...generatedGroupHeader,
      ...groupStatusFields,
    };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const reconcileCustomStatusGroups = (): void => {
  if (getProfilesTableGroupField() !== 'custom-status') {
    return;
  }

  reconcileGroups();
};

export const reconcileFolderGroups = (): void => {
  if (getProfilesTableGroupField() !== 'folder') {
    return;
  }

  reconcileGroups();
};

export const resetProfilesTableGroups = (): void => {
  setProfilesTableGroupHeaders([]);
};

export const reloadProfilesTableGroups = (): void => {
  // if filters by proxy id - we can't yet show reliable groups
  if (getProfilesTableProxyIdFilter()) {
    resetProfilesTableGroups();

    return;
  }

  const localStorageGroupsState = loadGroupsFromLocalStorage();
  if (!localStorageGroupsState) {
    return;
  }

  const groupHeaders = generateGroupHeaders(localStorageGroupsState.field) || [];
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    const localGroupHeader = localStorageGroupsState.groupHeaders.find((localHeader) => localHeader.id === groupHeader.id);
    const isOpen = localGroupHeader?.isOpen ?? true;

    return {
      ...groupHeader,
      isOpen,
    };
  }));
};

export const resetProfilesTableGroupStatus = (): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const unloadedStatus: GroupHeaderLoadingStatus = 'unloaded';
  const newGroupHeaders = groupHeaders.map((groupHeader) => ({
    ...groupHeader,
    loadingStatus: unloadedStatus,
    totalProfiles: null,
  }));

  setProfilesTableGroupHeaders(newGroupHeaders);
};

// TODO: move from this file
export interface IProfileRunStatus {
  id: IProfile['id'];
  status: ProfileStatusType;
  statusMessage?: IUpdateProfileStatusEvent['message'];
  isWeb?: boolean;
  remoteOrbitaUrl?: string;
}

export const useProfilesList = (): IProfile[] => useAtomValue(profilesListAtom);
export const getProfilesList = (): IProfile[] => getDefaultStore().get(profilesListAtom);
export const setProfilesList = (newProfiles: IProfile[]): void => getDefaultStore().set(profilesListAtom, newProfiles);

export const appendDeduplicatedProfilesToList = (profilesToAppend: IProfile[]): void => {
  const combinedProfilesList = getProfilesList().concat(profilesToAppend);
  setProfilesList(removeArrayDuplicates(combinedProfilesList));
};

export const useProfilesTableGroupHeaders = (): GroupHeader[] => useAtomValue(profilesTableGroupHeadersAtom);
export const getProfilesTableGroupHeaders = (): GroupHeader[] => getDefaultStore().get(profilesTableGroupHeadersAtom);
export const setProfilesTableGroupHeaders = (newGroups: GroupHeader[]): void => {
  if (!NEW_FEATURES.header) {
    getDefaultStore().set(profilesTableGroupHeadersAtom, []);

    return;
  }

  getDefaultStore().set(profilesTableGroupHeadersAtom, newGroups);
  saveGroupsToLocalStorage(newGroups);
  saveFirstGroupToNewProfileStorage();
};

export const calculateIsProfilesTableIniting = (): boolean => {
  if (getBasicTableProfileIds().length) {
    return false;
  }

  const groupHeaders = getProfilesTableGroupHeaders();
  if (!groupHeaders.length) {
    return true;
  }

  return !groupHeaders.some((groupHeader) => (groupHeader.totalProfiles || groupHeader.loadingStatus === 'loaded'));
};

// new profile page is such a mess, it is too costly to pass it in some other way
// specifically because, at the time of this writing, groups reset when you switch to it
const saveFirstGroupToNewProfileStorage = (): void => {
  let folderToSet = null;
  let customStatusToSet = null;

  const [firstGroupHeader] = getProfilesTableGroupHeaders();
  switch (firstGroupHeader?.filter.type) {
    case 'folder': {
      const { folderId } = firstGroupHeader.filter;
      const folder = getFolderById(folderId);
      folderToSet = folder?.name || null;
      break;
    }
    case 'custom-status': {
      const { customStatusId } = firstGroupHeader.filter;
      customStatusToSet = customStatusId;
      break;
    }
    default:
      break;
  }

  if (folderToSet) {
    sessionStorage.setItem(NEW_PROFILE_PAGE_FOLDER_NAME, folderToSet);
  } else {
    sessionStorage.removeItem(NEW_PROFILE_PAGE_FOLDER_NAME);
  }

  if (customStatusToSet) {
    sessionStorage.setItem(NEW_PROFILE_PAGE_CUSTOM_STATUS_ID, customStatusToSet);
  } else {
    sessionStorage.removeItem(NEW_PROFILE_PAGE_CUSTOM_STATUS_ID);
  }
};

export const doesGroupHaveVisibleContent = (groupHeader: GroupHeader | null): boolean => {
  if (!groupHeader) {
    return false;
  }

  const { isOpen, totalProfiles, loadingStatus } = groupHeader;

  return !!(isOpen && totalProfiles && loadingStatus !== 'loading-initiated');
};

export const getGroupHeaderById = (groupHeaderId: GroupHeader['id']): GroupHeader | null => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeader = groupHeaders.find((gh) => gh.id === groupHeaderId);

  return groupHeader || null;
};

export const mapAndSetProfilesList = (mapProfilesList: (prevProfiles: IProfile[]) => IProfile[]): void =>
  getDefaultStore().set(profilesListAtom, mapProfilesList(getDefaultStore().get(profilesListAtom)));

export const editProfilesListFields = (profileIds: string[], newData: Partial<IProfile>): void =>
  mapAndSetProfilesList((profiles) => profiles.map((profile) => {
    if (profileIds.includes(profile.id)) {
      return { ...profile, ...newData };
    }

    return profile;
  }));

export const filterProfilesByPermission = (
  profilesIds: string[],
  permission: keyof IWorkspaceProfilePermissions,
): IProfile[] => getProfilesList()
  .filter(profile => profilesIds.find(profileId => profileId === profile.id && profile.permissions[permission]));

export const resetProfilesList = (): void => {
  setProfilesList([]);
  resetProfilesTableSelectedIds();
  resetProfileRunStatuses();
  resetProfilesQueryOffset();
  resetProfilesTableGroupStatus();
};

export const resetProfilesTable = (): void => {
  resetProfilesList();
  closeProfilesSettings();
  closeProfileTableModal();
  closeQuickPricing();
  resetToDefaultSearchState();
  resetProfilesTableProxyIdFilter();
  resetProfilesTableGroups();
};

const getAllProfilesGroups = (): Array<{ profileId: string; profileGroupIds: string[] }> => {
  const profiles = getProfilesList();
  const groupHeaders = getProfilesTableGroupHeaders();

  return profiles.map((profile) => {
    const profileGroupIds = groupHeaders.reduce<string[]>((acc, groupHeader) => {
      if (filterProfileByGroupHeader(groupHeader, profile)) {
        acc.push(groupHeader.id);
      }

      return acc;
    }, []);

    return {
      profileId: profile.id,
      profileGroupIds,
    };
  });
};

const getProfilesByGroupHeaderObj = (groupHeader: GroupHeader): IProfile[] => {
  const profiles = getProfilesList();

  return profiles.filter((profile) => filterProfileByGroupHeader(groupHeader, profile));
};

export const getGroupProfiles = (groupHeaderId: string): IProfile[] => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader) {
    return [];
  }

  return getProfilesByGroupHeaderObj(groupHeader);
};

export const getGroupProfilesCount = (groupHeaderId: string): number => getGroupProfiles(groupHeaderId).length;

type IUpdateGroupHeadersStatus = Pick<IProfilesResponse, 'groupsMetadata'>;

export const updateProfilesTableGroupHeadersStatus = (profilesResponse: IUpdateGroupHeadersStatus): void => {
  const { groupsMetadata } = profilesResponse;
  if (!groupsMetadata) {
    return;
  }

  const newGroupHeaders = getProfilesTableGroupHeaders().map((groupHeader) => {
    const { id: groupId, isOpen, loadingStatus: prevLoadingStatus } = groupHeader;
    const isNoEntitiesGroup = isNoEntitiesGroupHeader(groupHeader);
    let groupMetadata = groupsMetadata.find((gm) => gm.groupId === groupId || (!gm.groupId && isNoEntitiesGroup));
    if (!groupMetadata) {
      groupMetadata = { groupId, filteredProfilesCount: 0 };
    }

    const { filteredProfilesCount } = groupMetadata;
    const newProfilesCount = getGroupProfilesCount(groupId);
    let newStatus: GroupHeaderLoadingStatus = isOpen ? 'unloaded' : prevLoadingStatus;
    if (isOpen && newProfilesCount && newProfilesCount < filteredProfilesCount) {
      newStatus = 'loading';
    } else if (isOpen && newProfilesCount >= filteredProfilesCount) {
      newStatus = 'loaded';
    }

    return { ...groupHeader, loadingStatus: newStatus, totalProfiles: filteredProfilesCount };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const getProfileNamesForNotifications = (profileIds: string[], translation: TFunction): string => {
  const profiles = getProfilesList().filter(profile => profileIds.includes(profile.id));
  const selectedProfilesName = profiles
    .map(profile => profile?.name.length > 15 ? profile?.name.slice(0, 12) + '...' : profile?.name);

  if (selectedProfilesName.length >= 2) {
    return selectedProfilesName.length + ' ' + translation('base.profiles');
  }

  return selectedProfilesName[0];
};

export const toggleGroupProfilesSelection = (groupHeaderId: string): void => {
  const profileIds = getGroupProfiles(groupHeaderId).map(({ id }) => id);
  openProfilesTableGroupHeader(groupHeaderId);
  toggleProfilesSelected(profileIds);
};

export const updateProfilesArchivedProxy = (archivedProxies: IArchivedProxyInBrowser[]): void => {
  const archivedProxiesHash = archivedProxies.reduce<Record<string, IArchivedProxyInBrowser>>((hash, proxy) => {
    const { id } = proxy;
    hash[id] = proxy;

    return hash;
  }, {});

  const updatedProfileList = getProfilesList().map(profile => {
    const proxyId = profile?.proxy?.id;
    if (proxyId && archivedProxiesHash[proxyId]) {
      return ({
        ...profile,
        archivedProxy: archivedProxiesHash[proxyId],
        proxyEnabled: false,
        proxy: EMPTY_PROXY,
      });
    }

    return profile;
  });

  setProfilesList(updatedProfileList);
};

export const useProfileRowGroupKey = (rowIdx: number): number => {
  const basicEntities = useBasicTableEntities();
  let lastGroupIdx = 0;
  basicEntities.slice(0, rowIdx).forEach(({ idx, atom: entityAtom }) => {
    const entityContent = getDefaultStore().get(entityAtom);
    if (isGroupHeader(entityContent)) {
      lastGroupIdx = idx;
    }
  });

  return lastGroupIdx;
};
