import React, { useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';

import EmptyList from './empty-list';
import ProxyRow from './proxy-row';
import { ProxyListContentEl } from './styles/proxy-list';
import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { useProxyManagerListEntities } from '../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { setProxyManagerCurrentProxy } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import {
  switchIsSelectProxyModeOpened,
  useConfirmBlockVisible,
  useIsProxyContextMenuVisible, hideProxyContextMenu,
} from '../../../../state/proxy-select-menu.atom';
import ContextMenu from '../../components/context-menu';
import { IProfileProxy } from '../../components/interfaces/IProfileProxy';
import ProxyListMenu from '../../components/proxy-list-menu';
import RemovingConfirm from '../../components/removing-confirm';
import { GROUPED_PROXY_MANAGER_SIZE, UNGROUPED_PROXY_MANAGER_SIZE } from '../styles';

export interface IProxyList {
  currentProxy?: IProfileProxy;
  localProxyElementLocation: string | null;
}

const ProxyList: React.FC<IProxyList> = (props) => {
  const { currentProxy, localProxyElementLocation } = props;

  const confirmBlockVisible = useConfirmBlockVisible();
  const isProxyContextMenuVisible = useIsProxyContextMenuVisible();
  const { selectedProxies } = useSelectedProxies();
  const proxyManagerListEntities = useProxyManagerListEntities();
  console.log('proxyManagerListEntities', proxyManagerListEntities);

  const ref = useRef<HTMLDivElement>(null);

  const itemCount = proxyManagerListEntities.length;

  useEffect(() => {
    if (currentProxy?.mode !== 'none') {
      setProxyManagerCurrentProxy(currentProxy);
    }

    return () => {
      setProxyManagerCurrentProxy(currentProxy);
    };
  }, []);

  useEffect(() => {
    if (!selectedProxies.length) {
      switchIsSelectProxyModeOpened(false);
    }
  }, [selectedProxies]);

  useEffect(() => {
    const handleScroll = (): void => {
      hideProxyContextMenu();
    };

    if (ref?.current) {
      if (isProxyContextMenuVisible) {
        ref.current.addEventListener('scroll', handleScroll);
      } else {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    }

    return (): void => {
      if (ref?.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isProxyContextMenuVisible]);

  const renderConfirm = (): JSX.Element | null => {
    if (!confirmBlockVisible) {
      return null;
    }

    return <RemovingConfirm />;
  };

  if (!proxyManagerListEntities.length) {
    return <EmptyList proxySelectorLocation={localProxyElementLocation} />;
  }

  return (
    <>
      <ProxyListContentEl isProxyGroups={!!NEW_FEATURES.proxyGroups}>
        <div style={{ width: NEW_FEATURES.proxyGroups ? GROUPED_PROXY_MANAGER_SIZE.width : UNGROUPED_PROXY_MANAGER_SIZE.width }}>
          <FixedSizeList
            height={NEW_FEATURES.proxyGroups ? GROUPED_PROXY_MANAGER_SIZE.height : UNGROUPED_PROXY_MANAGER_SIZE.height}
            width={NEW_FEATURES.proxyGroups ? GROUPED_PROXY_MANAGER_SIZE.width : UNGROUPED_PROXY_MANAGER_SIZE.width}
            itemCount={itemCount}
            itemData={{
              proxyManagerEntities: proxyManagerListEntities,
              currentProxy,
              localProxyElementLocation,
            }}
            itemSize={NEW_FEATURES.proxyGroups ? 40 : 41}
            outerRef={ref}
          >
            {ProxyRow}
          </FixedSizeList>
        </div>
        <ProxyListMenu />
      </ProxyListContentEl>
      <ContextMenu />
      {renderConfirm()}
    </>
  );
};

export default ProxyList;
