import { v4 as uuidv4 } from 'uuid';

import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import { sendReactAnalyticsError } from '../../utils/sentry.helper';

export const checkProxyRequest = async (options: any) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/check_proxy`, {
      method: 'POST',
      body: JSON.stringify(options),
    });

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = async (profileId: string, profileData: any) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/${profileId}`, {
      method: 'PUT',
      body: JSON.stringify(profileData),
    });

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const sendAnalytics = (profileId: string, updatedFields: any) => {
  const body = Object.keys(updatedFields).map((key: string) => ({
    field: key,
    count: updatedFields.key,
    profile: profileId,
    type: 'fingerprint-update',
    status: 'success',
    deduplicationKey: uuidv4(),
  }));

  return http(`${API_BASE_URL}/analytics/many`, {
    method: 'POST',
    body: JSON.stringify({
      analytics: body,
    }),
  }).catch(sendReactAnalyticsError);
};

export const updateMultipleFingerprints = async (profileData: any): Promise<void> => {
  await http(`${API_BASE_URL}/browser/fingerprint/multi`, {
    method: 'POST',
    body: JSON.stringify(profileData),
  });
};

interface IUpdateProfileName {
  name: string;
  id: string;
}
export const updateProfileName = async (info: IUpdateProfileName) => {
  const request = await http(`${API_BASE_URL}/browser/${info.id}/name`, {
    method: 'PATCH',
    body: JSON.stringify({
      name: info.name,
    }),
  });

  return request.body;
};

interface IUpdateProfileNotes {
  notes: string;
  id: string;
}
export const updateProfileNotes = async (info: IUpdateProfileNotes) => {
  const request = await http(`${API_BASE_URL}/browser/${info.id}/notes`, {
    method: 'PATCH',
    body: JSON.stringify({
      notes: info.notes,
    }),
  });

  return request.body;
};

export const updateProfileGeolocation = async (profileId: string, geolocation: any) => {
  const request = await http(`${API_BASE_URL}/browser/${profileId}/geolocation`, {
    method: 'PATCH',
    body: JSON.stringify(geolocation),
  });

  return request.body;
};

interface ITimezone {
  fillBasedOnIp: boolean;
  timezone: string;
}
export const updateProfileTimezone = async (profileId: string, timezone: ITimezone) => {
  const request = await http(`${API_BASE_URL}/browser/${profileId}/timezone`, {
    method: 'PATCH',
    body: JSON.stringify(timezone),
  });

  return request.body;
};

export const refreshFingerprintRequest = async (profileId: string, template: string | null) => {
  const request = await http(`${API_BASE_URL}/browser/fingerprints`, {
    method: 'PATCH',
    body: JSON.stringify({
      browsersIds: [profileId],
      template,
    }),
  });

  return request.body;
};

export const restoreOldProfileVersion = async (profileId: string) => http(`${API_BASE_URL}/browser/${profileId}/restore`);

export const deleteProfiles = async (profilesToDelete: string[]) => {
  try {
    await http(`${API_BASE_URL}/browser`, {
      method: 'DELETE',
      body: JSON.stringify({
        profilesToDelete,
      }),
    });

    return null;
  } catch (error) {
    throw error;
  }
};

export const cloneProfileMulti = async (profileIds: string[], folderName = '') => {
  try {
    await http(`${API_BASE_URL}/browser/clone_multi`, {
      method: 'POST',
      body: JSON.stringify({
        browsersIds: profileIds,
        folderName,
      }),
    });
  } catch (error) {
    throw error;
  }
};

export const removeMultipleInvites = async (invites: string[]): Promise<void> => {
  try {
    const requestBody = JSON.stringify({
      invitesForDelete: invites,
    });

    await http(`${API_BASE_URL}/share`, {
      method: 'DELETE',
      body: requestBody,
    });
  } catch (error) {
    throw error;
  }
};

export const fetchPatchProfileLanguage = async (profileId: string, language: string, autoLang: boolean) => {
  const fetchBody = JSON.stringify({
    language,
    autoLang,
  });

  return http(`${API_BASE_URL}/browser/${profileId}/language`, {
    method: 'PATCH',
    body: fetchBody,
  });
};

export const updateProfileResolution = async (profileId: string, resolution: string) => {
  const fetchBody = JSON.stringify({
    resolution,
  });

  return http(`${API_BASE_URL}/browser/${profileId}/resolution`, {
    method: 'PATCH',
    body: fetchBody,
  });
};
