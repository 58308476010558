import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { linkProfileProxy, linkProfileProxyInState, unlinkProfileProxyInState } from './link-proxy.operations';
import { GeoProxyType } from '../../../../common/constants/types';
import { getVpnUfoProxy } from '../../../features/profileSettingsComponents/proxyTab/api';
import { IVpnUfoProxyOptions } from '../../../features/profileSettingsComponents/proxyTab/interfaces/vpn-ufo.interfaces';
import { updateProxyRequest } from '../../../features/proxy/api';
import {
  GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
  GEOPROXY_TYPE_TAG,
  PROXY_CHECK_ERROR_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_ID_TAG,
} from '../../../features/proxy/constants';
import { calculateSelectableConnectionTypes, generateGeoProxyCustomName } from '../../../features/proxy/proxy-helpers';
import { generateArtificialGeoProxyId } from '../../../features/proxy/utils/proxy-id';
import { IGeolocationProxyFullData, IProxy } from '../../../interfaces';
import { IGeoProxyWithTraffic } from '../../../interfaces/geoproxy-with-traffic.interface';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { setGeoProxyLastSelectedType } from '../geoproxy-form-data.atom';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { pushManyProxies, removeProxiesFromList, updateProxyInListById, updateProxyItem } from '../proxy-list.atom';

const getGeoProxyWithCredentials = async (
  country: string,
  connectionType: GeoProxyType,
  profileId: string,
): Promise<IGeoProxyWithTraffic> => {
  const parameters: IVpnUfoProxyOptions = {
    countryCode: country,
    browserId: profileId,
    isMobile: connectionType === 'mobile',
    isDC: connectionType === 'dataCenter',
  };

  return getVpnUfoProxy(parameters);
};

type GeoProxySubmitParams = {
  groupId: string;
  profileId: string;
  country: string;
  selectedConnectionType: GeoProxyType;
  availableConnectionTypes: GeoProxyType[];
  trafficData: IGeolocationProxyFullData;
  checkTooltipView: ProxyCheckTooltipView;
}

type GeoProxyMockParams = {
  country: string;
  connectionType: GeoProxyType;
  profilesCount: number;
  groupId: string;
}

const mockGeoProxy = ({ country, connectionType, profilesCount, groupId }: GeoProxyMockParams): IProxy => {
  const proxyId = generateArtificialGeoProxyId();
  const customName = generateGeoProxyCustomName(country);

  return {
    id: proxyId,
    groupId,
    host: '',
    port: 80,
    connectionType,
    mode: 'geolocation',
    customName,
    country,
    profilesCount,
    positionInGroup: 'end',
    createdAt: new Date(),
    selectionDate: profilesCount ? Date.now() : 0,
  };
};

const handleGeoProxyWithCredentialsError = (mockedProxy: IProxy, profileId: string): void => {
  removeProxyStatuses([mockedProxy], [profileId]);
  removeProxiesFromList([mockedProxy.id]);
  if (profileId) {
    unlinkProfileProxyInState(profileId);
  }
};

const checkGeoProxy = async (proxy: IProxy, profileId: string, checkTooltipView: ProxyCheckTooltipView): Promise<IProxy | string> => {
  // TODO: consider shared proxies before the proxyGroups release
  const checkedProxy = await updateProxyStatuses({
    proxies: [proxy],
    profileId,
    isSharedProxy: false,
    // TODO: shouldRestoreProxy
  }).catch(() => ({ status: false, error: '' }));

  const checkedFullProxy: IProxy = { ...proxy, ...checkedProxy, checkDate: moment().toDate() };
  if (!(checkedProxy && checkedProxy.status)) {
    sendReactErrorToSentry({
      transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      tags: [
        [PROXY_ID_TAG, proxy.id],
        [GEOPROXY_TYPE_TAG, proxy.connectionType],
        [PROXY_COUNTRY_TAG, proxy.country],
        [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      ],
    });

    return 'tableProfiles.notification.proxyNotFoundForCountryAndType';
  }

  showProxyCheckTooltip({
    profileIds: [profileId || ''],
    proxies: [checkedFullProxy],
    view: checkTooltipView,
    timeout: 2000,
  });

  return checkedFullProxy;
};

type TranslationKey = string;

export const createGeoProxy = async ({
  groupId,
  profileId,
  country,
  selectedConnectionType,
  availableConnectionTypes,
  trafficData,
  checkTooltipView,
}: GeoProxySubmitParams): Promise<IProxy | TranslationKey | null> => {
  let selectableConnectionType = selectedConnectionType;
  if (!availableConnectionTypes.includes(selectedConnectionType)) {
    const selectableConnectionTypes = calculateSelectableConnectionTypes(trafficData, selectedConnectionType);
    if (!selectableConnectionTypes.length) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      // TODO: sendReactErrorToSentry({ ... }) before the proxyGroups release

      return null;
    }

    [selectableConnectionType] = selectableConnectionTypes;
    setGeoProxyLastSelectedType(selectableConnectionType);
    console.log('!!! new selectableConnectionType', selectableConnectionType);
  }

  const profilesCount = profileId ? 1 : 0;
  const mockedProxy = mockGeoProxy({
    country,
    connectionType: selectableConnectionType,
    groupId,
    profilesCount,
  });

  addProxyStatuses([mockedProxy], [profileId]);
  if (profileId) {
    pushManyProxies([mockedProxy]);
    linkProfileProxyInState(profileId, mockedProxy);
  } else {
    pushManyProxies([mockedProxy]);
  }

  const geoProxyWithCredentialsResponse = await getGeoProxyWithCredentials(country, selectableConnectionType, profileId).catch((error) => {
    handleGeoProxyWithCredentialsError(mockedProxy, profileId);
    let errorMessage = 'tableProfiles.notification.trafficLimit';
    if (typeof error?.body?.message === 'string') {
      errorMessage = error.body.message;
    }

    return errorMessage;
  });

  if (typeof geoProxyWithCredentialsResponse === 'string') {
    return geoProxyWithCredentialsResponse;
  }

  const proxyWithCredentials = geoProxyWithCredentialsResponse;
  const fullProxy: IProxy = {
    ...mockedProxy,
    ...proxyWithCredentials,
    mode: 'geolocation',
  };

  const checkResult = await checkGeoProxy(fullProxy, profileId, checkTooltipView);
  if (typeof checkResult === 'string') {
    handleGeoProxyWithCredentialsError(mockedProxy, profileId);

    return checkResult;
  }

  const checkedProxy = checkResult;

  updateProxyInListById(mockedProxy.id, checkedProxy);
  removeProxyStatuses([mockedProxy], [profileId]);
  if (profileId) {
    linkProfileProxy(profileId, checkedProxy);
  }

  const proxyCreated = await updateProxyRequest(
    checkedProxy.id,
    checkedProxy,
    profileId,
  );

  updateProxyItem({ ...fullProxy, ...checkedProxy, ...proxyCreated });

  return { ...proxyWithCredentials, profilesCount };
};
