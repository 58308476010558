import { Popconfirm } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemRow } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { E_ANALYTICS_ACTIONS, E_ANALYTICS_ACTIONS_INFO } from '../../../../common/constants/analytics';
import { PROXY_PAGE } from '../../../../common/constants/routes';
import { sendActionAnalytics } from '../../../features/common/api';
import { history } from '../../../services';
import { resetToDefaultSearchState, workspaceContext } from '../../../state';
import { checkAndLogout } from '../../../utils/logout';
import { IconAffilate, IconApi, IconFolder, IconImport, IconInvite, IconLogout, IconSettings, IconSwitch } from '../icons';
import { IconProxyDataCenter } from '../icons/icon-proxy-datacenter';
import { typeColorIcon } from '../icons/wrapper';

export const itemRowIconProps: { padding: number; styleType: typeColorIcon; iconColor: string } = {
  padding: 0,
  styleType: 'lightGray',
  iconColor: 'var(--B5B5BA-header-account-menu)',
};

interface IItem {
  icon: JSX.Element;
  onClick: () => void;
  text: string;
  isHidden?: boolean;
  hasConfirm?: boolean;
}

interface IMenuItems {
  onClose: () => void;
  toggleShareAccountModal: (state: boolean) => void;
  toggleAccountModal: (state: boolean) => void;
  visibleSwitchAccount: boolean;
  importForPaidUsersPopUp: boolean;
  setImportForPaidUsersPopUp: (state: boolean) => void;
  openBrowserImport: () => void;
  redirectToSite: () => void;
  openInviteMembers: () => void;
  openCreateWorkspace: () => void;
  setIsLogoutModalVisible: (state: boolean) => void;
}

const MenuItems: FC<IMenuItems> = (props): JSX.Element => {
  const workspaceCtx = useContext(workspaceContext);
  const { permissions, isUnpaid } = workspaceCtx;

  const { t: translation } = useTranslation();

  const {
    onClose,
    toggleShareAccountModal,
    toggleAccountModal,
    visibleSwitchAccount,
    importForPaidUsersPopUp,
    setImportForPaidUsersPopUp,
    openBrowserImport,
    redirectToSite,
    openInviteMembers,
  } = props;

  const isInviteItemShown = NEW_FEATURES.workspaces && permissions.inviteMembers && !isUnpaid;

  const items: IItem[] = [{
    icon: <IconProxyDataCenter {...itemRowIconProps} />,
    onClick: (): void => {
      const currentToggle = localStorage.getItem('new-proxy-manager') ? localStorage.getItem('new-proxy-manager') === 'true' : true;
      localStorage.setItem('new-proxy-manager', `${!currentToggle}`);
      window.location.reload();
    },
    text: 'TOGGLE PROXY GROUPS',
  }, {
    icon: <IconProxyDataCenter {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      resetToDefaultSearchState();
      history.push(PROXY_PAGE);
    },
    text: translation('userMenu.proxyPage'),
  }, {
    icon: <IconSettings {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      history.push('/members');
    },
    isHidden: !NEW_FEATURES.workspaces,
    text: translation('userMenu.workspaceSettings'),
  }, {
    icon: <IconInvite {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      openInviteMembers();
    },
    text: translation('userMenu.inviteMembers'),
    isHidden: !isInviteItemShown,
  }, {
    icon: <IconFolder {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      history.push('/folders');
    },
    text: translation('header.folders'),
  }, {
    icon: <IconImport {...itemRowIconProps} />,
    onClick: (): void => openBrowserImport(),
    text: translation('header.import'),
    hasConfirm: importForPaidUsersPopUp,
  }, {
    icon: <IconApi {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      history.push('/personalArea/TokenApi');
    },
    text: translation('userMenu.API'),
  }, {
    icon: <IconAffilate {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      history.push('/personalArea/AffiliateProgram');
    },
    text: translation('userMenu.affiliate'),
  }, {
    icon: <IconSettings {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      history.push('/personalArea');
    },
    isHidden: NEW_FEATURES.workspaces,
    text: translation('userMenu.settings'),
  }, {
    icon: <IconInvite {...itemRowIconProps} />,
    onClick: () => toggleShareAccountModal(true),
    text: translation('userMenu.inviteMembers'),
    isHidden: NEW_FEATURES.workspaces,
  }, {
    icon: <IconSwitch {...itemRowIconProps} />,
    onClick: () => toggleAccountModal(true),
    text: translation('userMenu.switchAccount'),
    isHidden: !visibleSwitchAccount,
  }, {
    icon: <IconLogout {...itemRowIconProps} />,
    onClick: (): void => {
      onClose();
      checkAndLogout(workspaceCtx, props.setIsLogoutModalVisible);
    },
    text: translation('userMenu.logout'),
  }];

  const openCheckoutPage = (): void => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedBuyPlan, { actionInfo: E_ANALYTICS_ACTIONS_INFO.importCookies });
    redirectToSite();
  };

  const menuItem = (item: IItem): JSX.Element | null => {
    const { icon, onClick, text, isHidden, hasConfirm } = item;
    if (isHidden) {
      return null;
    }

    return (
      <Popconfirm
        key={item.text}
        placement='bottomRight'
        title={translation('header.importNotAllowed')}
        visible={hasConfirm}
        onConfirm={openCheckoutPage}
        onCancel={(): void => setImportForPaidUsersPopUp(false)}
        okText={translation('header.changePlan')}
      >
        <ItemRow onClick={onClick} newStyle={NEW_FEATURES.header}>
          {icon}
          <span style={{ marginLeft: 10 }}>
            {text}
          </span>
        </ItemRow>
      </Popconfirm>
    );
  };

  return (
    <>
      {items.map(item => menuItem(item))}
    </>
  );
};

export default MenuItems;
