import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const SavedProxySearchWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  width: 100%;
  height: 21px;

  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? null : css`
    margin-left: -8px;
  `};

  display: flex;
  align-items: center;
`;
