import { API_BASE_URL } from '../../../../common/constants/constants';
import { GeoProxyType } from '../../../../common/constants/types';
import { safeStringify } from '../../../../common/utils';
import { GEOPROXY_TRAFFIC_DATA_TIMEOUT_ERROR, GEOPROXY_TRAFFIC_DATA_UNKNOWN_ERROR } from '../../../features/proxy/constants';
import { IGeolocationProxyFullData } from '../../../interfaces';
import { http } from '../../../services';
import { fetchWithTimeout } from '../../../services/http/client';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';

const TRAFFIC_DATA_REQUEST_TIMEOUT = 15 * 1000;

const TrafficDataErrors = <const>{
  timeout: 'timeout',
  error: 'error',
};

export type TrafficDataError = keyof typeof TrafficDataErrors;

export const getTrafficData = async (): Promise<IGeolocationProxyFullData | TrafficDataError> =>
  fetchWithTimeout<IGeolocationProxyFullData>(`${API_BASE_URL}/users-proxies/geolocation/traffic`, {
    method: 'GET',
    timeout: TRAFFIC_DATA_REQUEST_TIMEOUT,
  }).catch((error) => {
    const isError = error instanceof Error;
    if (isError && error.name === 'AbortError') {
      sendReactErrorToSentry({
        transactionName: GEOPROXY_TRAFFIC_DATA_TIMEOUT_ERROR,
        message: error.message,
      });

      return TrafficDataErrors.timeout;
    }

    sendReactErrorToSentry({
      transactionName: GEOPROXY_TRAFFIC_DATA_UNKNOWN_ERROR,
      message: safeStringify(error),
    });

    return TrafficDataErrors.error;
  });

interface IGetPaymentURL {
  dataGbCount: number;
  dataType: GeoProxyType;
  path: string;
}

export const getPaymentURL = (params: IGetPaymentURL): Promise<{ checkoutUrl: string }> =>
  http(`${API_BASE_URL}/billing/proxies/checkout_session_data`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((res: any) => res.body);
