import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxyStatusWrapper = styled.div<{ isProxyManagerWithGroups: boolean }>`
  height: 100%;
  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    width: 38px;
    min-width: 38px;
  ` : css`
    width: 32px;
    min-width: 32px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;
`;
