import { css } from '@emotion/core';

let flagsBasePath = '/flags/';
const isElectron = !!window.require;
if (isElectron) {
  const nodeJsPath = require('path');
  const flagsBasePathSlash = nodeJsPath.sep;
  flagsBasePath = nodeJsPath.join(__dirname, 'flags').split(flagsBasePathSlash).join('/') + '/';
}

export const COUNTRIES_WITH_FLAGS = [
  'ad',
  'ae',
  'af',
  'ag',
  'al',
  'am',
  'ao',
  'ar',
  'at',
  'au',
  'aw',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bn',
  'bo',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'ca',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cu',
  'cv',
  'cw',
  'cy',
  'cz',
  'de',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ec',
  'ee',
  'eg',
  'er',
  'es',
  'et',
  'fi',
  'fj',
  'fm',
  'fr',
  'ga',
  'gd',
  'ge',
  'gh',
  'gm',
  'gn',
  'gq',
  'gr',
  'gt',
  'gw',
  'gy',
  'hk',
  'hn',
  'hr',
  'ht',
  'hu',
  'id',
  'ie',
  'il',
  'in',
  'iq',
  'ir',
  'is',
  'it',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kp',
  'kr',
  'kw',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mr',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'ne',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nz',
  'om',
  'pa',
  'pe',
  'pg',
  'ph',
  'pk',
  'pl',
  'ps',
  'pt',
  'pw',
  'py',
  'qa',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'si',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'ss',
  'st',
  'sv',
  'sx',
  'sy',
  'sz',
  'td',
  'tg',
  'th',
  'tj',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tz',
  'ua',
  'ug',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vi',
  'vn',
  'vu',
  'ws',
  'ye',
  'za',
  'zm',
  'zw',
  'eu',
];

export const flagIcons = css`
  .fib {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .fi {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  .fi:before {
    content: '\\00a0';
  }

  ${COUNTRIES_WITH_FLAGS.map((countryCode) => css`
    .fi-${countryCode} {
      background-image: url('${flagsBasePath}${countryCode}.svg');
    }
  `)}

  .fi-uk,
  .fi-gb {
    background-image: url('${flagsBasePath}gb.svg');
  }
`;
