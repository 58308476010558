/* eslint-disable id-length */
export const LOAD_PROXY_TRANSACTION = 'load-proxies';
export const LOAD_TRAFFIC_DATA_TRANSACTION = 'load-traffic-data';
export const LOAD_GEOPROXY_COUNTRIES_TRANSACTION = 'load-geoproxy-countries';

export const PROXY_LOAD_OPERATION = 'http-and-ui';

export const LOAD_PROXIES_PAGES_SPAN = 'load-proxies-pages-iteratively';
export const LOAD_PROXIES_SHARED_AND_FREE_SPAN = 'load-proxies-free-and-from-shared-profiles';

export const PROXY_ID_TAG = 'proxy-id';
export const PROXY_GROUP_ID_TAG = 'proxy-group-id';
export const GEOPROXY_TYPE_TAG = 'geoproxy-type';
export const PROXY_COUNTRY_TAG = 'proxy-country';
export const PROXY_CHECK_ERROR_TAG = 'proxy-check-error';
export const PAGES_LOADED_COUNT_TAG = 'pages-loaded-count';
export const PROXIES_LOADED_COUNT_TAG = 'proxies-loaded-count';
export const HAS_PAGES_NOT_LOADED_TAG = 'has-pages-not-loaded';
export const SHARED_PROXIES_LOADED_COUNT_TAG = 'shared-proxies-loaded-count';
export const FREE_PROXIES_LOADED_COUNT_TAG = 'free-proxies-loaded-count';
export const PROXY_PAGE_NOT_LOADED_TAG = 'proxy-page-not-loaded-index';
export const PROXY_MODE_TAG = 'proxy-mode';
export const PROFILES_OFFSET_TAG = 'profiles-offset';
export const PROXIES_NOT_FOUND_COUNT = 'proxies-not-found-count';

export const PROXY_PAGE_LOADING_ERROR = 'proxy-page-loading-error';
export const GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR = 'geoproxy-type-icon-not-shown-error';
export const GEOPROXY_TYPE_ICON_NOT_SHOWN_ERROR_MESSAGE = 'connectionType not found in props';
export const GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR = 'geoproxy-check-failed-when-creating-error';

export const PROXY_LOADING_DELAYED_WARNING = 'profile-proxy-loading-delayed';
export const PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING = 'proxy-group-not-found-therefore-created-warning';
export const PROXY_GROUP_NOT_FOUND_THEREFORE_CREATED_WARNING_MESSAGE =
    'proxy group not found for the specified group-id when initializing groups in state';
export const CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING = 'current-profile-geoproxy-country-empty-warning';
export const CURRENT_PROFILE_GEOPROXY_COUNTRY_EMPTY_WARNING_MESSAGE =
    'current profile geoproxy country is empty when moving the proxy to the beginning of the list';

export const START_APP_TO_LOAD_PROFILES_TABLE = 'start-app-to-load-profiles';
export const START_APP_TO_LOAD_SIGN_UP_PAGE = 'start-app-to-load-sign-up';
