import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SavedProxySearchWrapper } from './styles';
import { IProfile, IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { useProfilesList } from '../../../../state/profiles-list.atom';
import { ProxySelectorLocation } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import CheckProxyBtn from '../../check-proxy-button';
import CheckProxyStatusDot from '../../check-proxy-button/check-proxy-status-dot';
import ProxyFlag from '../../proxy-flag';
import { makeProxyTitle } from '../../proxy-helpers';
import { ProxyStatusWrapper } from '../../styles';
import { ProxySearchInputEl } from '../../styles/proxy-search-input';
import { PROXY_SAVED_SELECTOR_WIDTH } from '../constants';

const SURROUNDING_ELEMENTS_WIDTH_SUM = 66;
const PROXY_SAVED_SEARCH_WIDTH = PROXY_SAVED_SELECTOR_WIDTH - SURROUNDING_ELEMENTS_WIDTH_SUM;

type ProxySavedSearchProps = {
  proxy: IProxy;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  selectorContainerElement: React.RefObject<HTMLDivElement|null>;
  editingProfileId: string|null;
}

const ProxySavedSearch: React.FC<ProxySavedSearchProps> = (props) => {
  const { proxy, searchValue, setSearchValue, selectorContainerElement, editingProfileId } = props;

  const profilesList = useProfilesList();

  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { t: translation } = useTranslation();

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }

    return () => {
      setSearchValue('');
      setIsInputChanged(false);
    };
  }, [searchInputRef]);

  let currentProfile: IProfile|null = null;
  if (editingProfileId) {
    currentProfile = profilesList.find(({ id }) => id === editingProfileId) || null;
  }

  const isSharedProxy = currentProfile ? currentProfile.role !== 'owner' : false;

  const proxyCountry = proxy.country || '';
  const searchInputPlaceholder = translation('proxies.searchForProxy');
  const proxyTitle = makeProxyTitle(proxy);
  const searchInputValue = searchValue || isInputChanged ? searchValue : proxyTitle;

  const handleChangeSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsInputChanged(true);
    setSearchValue(event.target.value);
  };

  const handleFocusSearch: React.FocusEventHandler<HTMLInputElement> = (event): void => event.currentTarget.select();

  const renderLeftControls = (): JSX.Element => {
    const profileId = editingProfileId || '';
    if (proxy.mode !== 'none') {
      return (
        <ProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
          <CheckProxyBtn
            proxy={proxy}
            profileId={profileId}
            proxyTooltipView={ProxySelectorLocation.profileSettingsPage}
            selectorContainerElement={selectorContainerElement}
            isSharedProxy={isSharedProxy}
          />
        </ProxyStatusWrapper>
      );
    }

    return (
      <ProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
        <CheckProxyStatusDot
          proxy={proxy}
          isSpinnerVisible={false}
          isChecking={false}
          profileId={profileId}
          proxyTooltipView={ProxySelectorLocation.profileSettingsPage}
          isSharedProxy={isSharedProxy}
        />
      </ProxyStatusWrapper>
    );
  };

  return (
    <SavedProxySearchWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
      {renderLeftControls()}
      <ProxyFlag
        countryCode={proxyCountry}
        width={NEW_FEATURES.proxyGroups ? 18 : 14}
      />
      <ProxySearchInputEl
        newStyle={!!NEW_FEATURES.header}
        ref={searchInputRef}
        placeholder={searchInputPlaceholder}
        value={searchInputValue}
        onChange={handleChangeSearch}
        onFocus={handleFocusSearch}
        width={PROXY_SAVED_SEARCH_WIDTH}
        truncate={true}
        isTransparent={false}
        isInDrawer={false}
        newDrawer={!!NEW_FEATURES.drawer}
        style={{ marginLeft: 0 }}
      />
    </SavedProxySearchWrapper>
  );
};

export default ProxySavedSearch;
