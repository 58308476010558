import { ProxyMode } from '../../../interfaces';
import { IProxy } from '../components/interfaces/IProxy';

export const getProxyGroupId = (mode: ProxyMode, country: string): string => [mode, country.toUpperCase()].join('-');

type INoIdProxyIdParams = Pick<IProxy, 'mode'|'autoProxyRegion'|'torProxyRegion'>;

// TODO: prevent `none-US`
export const getNoIdProxyId = (proxy: INoIdProxyIdParams): string => {
  const country = (proxy.autoProxyRegion || proxy.torProxyRegion || '').toUpperCase();

  return `${proxy.mode}-${country}`;
};

export const mockGeoProxyId = (): string => Date.now().toString();
