import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxyStatusDot = styled('div')<{ status?: string }>`
  width: 16px;
  height: 16px;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    margin: 4px;
    display: block;
    border-radius: 50%;
    background: var(--D9D9D9-proxy-manager-check-proxy-button);

    ${(props): SerializedStyles => css`
      ${(props.status === 'success') ? 'background: var(--00A987-proxy-manager);' : ''}
      ${(props.status === 'fail') ? 'background: var(--F5222D-proxy-manager-check-proxy-button);' : ''}
    `}
  }
`;

export const TooltipEl = styled('div')<{ isDarkTheme: boolean }>`
  min-height: 32px;
  padding: 6px 8px;
  color: var(--000000-proxy-manager-check-proxy-button);
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: var(--FFFFFF-proxy-manager-check-proxy-button);
  border-radius: 4px;

  ${(props) => props.isDarkTheme ? css`
    box-shadow: -2px 0 8px var(--00000026-antd);
  ` : css`
    box-shadow: 0px 1px 4px var(--00000003-proxy-manager-check-proxy-button), 0px 4px 8px var(--00000005-proxy-manager-check-proxy-button), 0px 1px 12px var(--0000001F-proxy-manager-check-proxy-button);
  `}
  
`;

export const TooltipWrapperEl = styled.div<{
  isProxyManagerWithGroups: boolean;
  marginTop?: number;
  marginLeft?: number;
}>`
  width: 318px;

  ${(props): SerializedStyles => props.isProxyManagerWithGroups ? css`
    padding: 0 8px;
    margin-top: ${props.marginTop || -18}px;
    ${props.marginLeft ? css`
      transform: translateX(${props.marginLeft}px);
    ` : null};
  ` : css`
    padding: 8px 0;
  `}
`;
