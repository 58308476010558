import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { proxyListItemActiveStyles } from '../styles/proxy-list-item';

export const ProxyGroupHeaderWrapper = styled.div`
  height: 36px;
  padding: 0 0 0 4px;
  border-radius: 4px;
  margin-bottom: 4px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    ${proxyListItemActiveStyles}
  }
`;

export const ProxyGroupStatusDotWrapper = styled.div`
  width: 36px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProxyGroupTitle = styled.span`
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ProxyGroupHint = styled.span`
  margin-right: 8px;

  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--98989F-proxy-group-header-hint);
`;

export const ProxyGroupExpandButtonWrapper = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const expandButtonArrowActiveStyles = css`
  svg path {
    stroke: var(--36363D-proxy-group-header-arrow-active);
  }
`;

export const ProxyGroupExpandButton = styled.div<{ isRotated: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props): SerializedStyles => props.isRotated ? css`
    ${expandButtonArrowActiveStyles}

    svg {
      transform: rotate(0);
    }
  ` : css`
    transform: rotate(-90deg);
  `}

  :hover {
    background-color: var(--E9E9E7-proxy-page-chip-check-hovered); // TODO: make different var()

    ${expandButtonArrowActiveStyles}
  }
`;

