import { selectProfileProxy } from './select-proxies.operations';
import { createProxiesRequest, updateProxyRequest } from '../../../features/proxy/api';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { handleProxiesLimitError } from '../../../features/proxy/utils/handle-proxies-limit-error';
import { isProxyDataValid } from '../../../utils/proxy.validation-message';
import { getProxyForm } from '../proxy-form.atom';
import { getProxyList, updateProxyItem, upsertOneProxy } from '../proxy-list.atom';
import { closeProxyManager, getProxyManagerState, openProxyManagerListView } from '../proxy-manager-modal-status.atom';
import { closeProxySettingsModal } from '../proxy-settings-modal.atom';
import { mapAndSetProxiesTableList } from '../proxy-table/proxies-table-list.atom';

interface ISubmitParams {
  proxyId: string;
  isProxyPage: boolean;
  profileId?: string;
}

export const handleProxyFormSubmit = async ({ proxyId, profileId = '', isProxyPage }: ISubmitParams): Promise<IProxy|null> => {
  const {
    customName,
    mode,
    host,
    port,
    username,
    password,
    changeIpUrl,
  } = getProxyForm();

  const { modalView } = getProxyManagerState();
  const proxyList = getProxyList();

  if (!(host || port || username || password)) {
    isProxyPage ? closeProxySettingsModal() : closeProxyManager();

    return null;
  }

  const isProxyValid = isProxyDataValid({ host, port: port.toString() });
  if (!isProxyValid) {
    return null;
  }

  const proxyItem = {
    mode,
    customName: (customName || '').trim(),
    host: host.replaceAll(' ', ''),
    port: parseInt(`${port}`.replaceAll(' ', ''), 10),
    username: (username || '').replaceAll(' ', ''),
    password: (password || '').replaceAll(' ', ''),
    changeIpUrl: (changeIpUrl || '').replaceAll(' ', ''),
  };

  if (proxyId && modalView === 'proxy-edit') {
    const selectedProxy = proxyList.find(({ id }) => id === proxyId);

    if (
      selectedProxy &&
      selectedProxy.mode === proxyItem.mode &&
      selectedProxy.customName === proxyItem.customName &&
      selectedProxy.host === proxyItem.host &&
      selectedProxy.port === proxyItem.port &&
      selectedProxy.username === proxyItem.username &&
      selectedProxy.password === proxyItem.password &&
      selectedProxy.changeIpUrl === proxyItem.changeIpUrl
    ) {
      isProxyPage ? closeProxySettingsModal() : openProxyManagerListView();

      return null;
    }
  }

  let newProxy: IProxy|null = null;
  // TODO: refactor nesting
  if (proxyId) {
    newProxy = await updateProxyRequest(proxyId, proxyItem, profileId);
    // something with perfomance (long timing to save edited proxy)

    if (newProxy) {
      if (!isProxyPage) {
        updateProxyItem(newProxy);
      }

      mapAndSetProxiesTableList((prevProxies) => prevProxies.map((prevProxy) => {
        if (prevProxy.id === newProxy.id) {
          return { ...prevProxy, ...newProxy };
        }

        return prevProxy;
      }), [], isProxyPage);
    } else {
      // TODO: sentry
    }
  } else {
    [newProxy] = await createProxiesRequest([proxyItem]).catch((error) => {
      handleProxiesLimitError(error);

      return null;
    });

    if (newProxy) {
      selectProfileProxy(profileId, newProxy);
      upsertOneProxy(newProxy);
    } else {
      // TODO: sentry
    }
  }

  isProxyPage ? closeProxySettingsModal() : openProxyManagerListView();

  return newProxy;
};
