import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { useBasicTableProxies } from './proxies-table-list.atom';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getProxyManagerListEntities } from '../proxy-groups/proxy-groups.atom';

const proxyTableSelectedIdsAtom = atom<string[]>([]);

export const useProxyTableSelectedIds = (): string[] => useAtomValue(proxyTableSelectedIdsAtom);

export const getProxyTableSelectedIds = (): string[] => getDefaultStore().get(proxyTableSelectedIdsAtom);
const setProxyTableSelectedIds = (newSelectedIds: string[]): void => getDefaultStore().set(proxyTableSelectedIdsAtom, newSelectedIds);

export const updateProxiesTableSelectedIds = (newSelectedIds: string[]): void => {
  setProxyTableSelectedIds(newSelectedIds);
};

export const resetProxiesTableSelectedIds = (): void => {
  const selectedIds = getProxyTableSelectedIds();
  if (!selectedIds.length) {
    return;
  }

  updateProxiesTableSelectedIds([]);
};

export const getProxiesTableSelectedProxies = (): IProxy[] => {
  const proxyManagerListEntities = getProxyManagerListEntities();
  const selectedProxiesIds = getProxyTableSelectedIds();

  return proxyManagerListEntities.filter(proxy => proxy && selectedProxiesIds.includes(proxy.id));
};

export const useProxyTableSelectedRowIndexes = (): number[] => {
  const basicTableProxies = useBasicTableProxies();
  const basicTableProxyIds = basicTableProxies.map((basicTableProxy) => getDefaultStore().get(basicTableProxy.atom).id);
  const selectedProxyIds = useProxyTableSelectedIds();

  return selectedProxyIds.reduce<number[]>((acc, proxyId) => {
    const idx = basicTableProxyIds.findIndex(basicTableProxyId => basicTableProxyId === proxyId);
    if (idx >= 0) {
      acc.push(idx);
    }

    return acc;
  }, []);
};
