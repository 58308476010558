import { IProxyGroupsObject } from './interfaces';

export const EMPTY_PROXY_GROUPS: IProxyGroupsObject = {
  geolocationProxies: {},
  gologinProxies: [],
  torProxies: [],
  userProxies: [],
};

export const PROXY_GROUP_HEADER_ID_POSTFIX = '-header';
export const PROXY_GROUP_ADD_BUTTON_ID_POSTFIX = '-add-button';

export const EMPTY_PROXY_LIST_ROW = null;
