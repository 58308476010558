import { useEffect } from 'react';

import { loadTrafficData } from '../state/proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { clearTrafficData } from '../state/proxy/traffic-data.atom';

export const useInitTrafficData = (userId?: string): void => {
  useEffect(() => {
    if (!userId) {
      return clearTrafficData();
    }

    loadTrafficData();
  }, [userId]);
};
