import { IFreeProxy } from './interfaces/IFreeProxy';
import { IProxyStatusParams, ISharedProxyStatusParams } from './interfaces/proxy-status-params.interface';
import { API_BASE_URL } from '../../../common/constants/constants';
import { IProxy } from '../../features/proxy/components/interfaces/IProxy';
import { ISharedProxy } from '../../features/proxy/components/interfaces/shared-proxy.interfaces';
import { DEFAULT_PROXIES_LIST } from '../../features/proxy/constants';
import { IProfile } from '../../interfaces';
import { http } from '../../services';

export const requestProxiesList = async (): Promise<any> => {
  const response = await http(`${API_BASE_URL}/proxy`);

  return response.body;
};

export const requestProxiesPaginated = async (page = 1): Promise<{ proxies: IProxy[]; hasMore: boolean }> => {
  const response = await http(`${API_BASE_URL}/proxy/v2?page=${page}`);

  return response.body;
};

export const requestProxiesFree = async (): Promise<IFreeProxy[]> => {
  const response = await http(`${API_BASE_URL}/proxy/free`).catch(() => ({ body: DEFAULT_PROXIES_LIST }));

  return response.body;
};

export const requestProxiesShared = async (): Promise<ISharedProxy[]> => {
  const response = await http(`${API_BASE_URL}/proxy/shared`).catch(() => ({ body: DEFAULT_PROXIES_LIST }));

  return response.body;
};

export type ITableProxyConnectedProfile = Pick<IProfile, 'id'|'name'>;

interface ITableProxy {
  id: string;
  connectedProfiles: ITableProxyConnectedProfile[];
}

interface ITableProxiesResponse {
  proxies: ITableProxy[];
}

export const requestTableProxies = async (proxiesIds: string[] = [], workspaceId = ''): Promise<ITableProxy[]> => {
  const url = new URL(`${API_BASE_URL}/proxy/table-proxies`);
  url.searchParams.append('proxiesIds', proxiesIds.join(','));
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  return http(url.toString())
    .then(({ body }: { body: ITableProxiesResponse }) => body.proxies)
    .catch(() => DEFAULT_PROXIES_LIST);
};

export const setProxyStatuses = async (proxies: IProxyStatusParams[]): Promise<void> => {
  const data = await http(`${API_BASE_URL}/proxy/set_proxy_statuses`, {
    method: 'POST',
    body: JSON.stringify({ proxies }),
  });

  return data.body;
};

export const setSharedProxyStatuses = async (proxies: ISharedProxyStatusParams[]): Promise<void> => {
  const data = await http(`${API_BASE_URL}/proxy/set_shared_proxy_statuses`, {
    method: 'POST',
    body: JSON.stringify({ proxies }),
  });

  return data.body;
};

export const getProxyRequest = async (id: string): Promise<IProxy> => {
  const response = await http(`${API_BASE_URL}/proxy/${id}`, {
    method: 'GET',
  });

  return response.body;
};
