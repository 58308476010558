import styled from '@emotion/styled';

import { proxyListItemActiveStyles } from '../styles/proxy-list-item';

export const UnroupedProxyItemWrapper = styled.div`
  display: flex;
  align-items: center;

  /* TODO: cloned from header */
  height: 36px;
  padding: 0 0 0 36px; // TODO: but not this and no gap
  border-radius: 4px;
  margin-bottom: 4px;

  :hover {
    ${proxyListItemActiveStyles}  
  }
`;

export const GroupedProxyTitle = styled.span`
  max-width: 218px;
  flex-grow: 1;
`;

