import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  AddButtonPopoverRow,
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
  PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH,
} from './styles';
import { ProxyGroupAddButtonProps } from '..';
import { GeoProxyType } from '../../../../../../../common/constants/types';
import { setGeoProxyLastSelectedType } from '../../../../../../state/proxy/geoproxy-form-data.atom';
import { createGeoProxy } from '../../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { useTrafficData } from '../../../../../../state/proxy/traffic-data.atom';
import { openQuickPricing } from '../../../../../../state/quick-pricing.atom';
import { itemRowIconProps } from '../../../../../../ui/gologin-header/account-menu/menu-items';
import { IconCheckSmall } from '../../../../../../ui/gologin-header/icons/icon-check-small';
import { IconHome } from '../../../../../../ui/gologin-header/icons/icon-home';
import { IconProxyDataCenter } from '../../../../../../ui/gologin-header/icons/icon-proxy-datacenter';
import { IconProxyMobile } from '../../../../../../ui/gologin-header/icons/icon-proxy-mobile';
import GologinPopover from '../../../../../../ui/gologin-popover';
import { GologinPopoverHint } from '../../../../../../ui/gologin-popover/gologin-popover-hint';
import { IMenuItem } from '../../../../../quickProfiles/profile-dropdown-menu/interfaces';
import { TRAFFIC_LIMIT } from '../../../../constants/settings';
import { calculateRemainingTrafficLimit, stringifyRemainingTrafficLimit } from '../../../../proxy-helpers';
import PurchaseTrafficButton from '../../purchase-traffic-button';
import { PurchaseTrafficButtonText } from '../../purchase-traffic-button/styles';

const POPOVER_DEFAULT_PADDING: React.CSSProperties['padding'] = '6px 12px';

const GEO_PROXY_TYPE_HINT_KEY_BASE = 'proxies.proxyGroupAddButtonMenuText.';
const GEO_PROXY_TYPE_HINTS = {
  resident: `${GEO_PROXY_TYPE_HINT_KEY_BASE}resident`,
  mobile: `${GEO_PROXY_TYPE_HINT_KEY_BASE}mobile`,
  dataCenter: `${GEO_PROXY_TYPE_HINT_KEY_BASE}dataCenter`,
} as const;

type GeoProxyTypeHint = typeof GEO_PROXY_TYPE_HINTS[keyof typeof GEO_PROXY_TYPE_HINTS];

type GeoProxyTypeItem = Omit<IMenuItem, 'name'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
}

export type ProxyGroupAddButtonPopoverPropsBase = Pick<ProxyGroupAddButtonProps, 'groupId' | 'country' | 'availableTypes'> & {
  geoProxyLastSelectedType: GeoProxyType;
};

type ProxyGroupAddButtonPopoverProps = ProxyGroupAddButtonPopoverPropsBase & {
  anchorElement: HTMLElement | null;
  handleClose: () => void;
}

const ProxyGroupAddButtonPopover: React.FC<ProxyGroupAddButtonPopoverProps> = (props) => {
  const { groupId, country, geoProxyLastSelectedType, anchorElement, availableTypes, handleClose } = props;

  const trafficData = useTrafficData();

  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint>(GEO_PROXY_TYPE_HINTS[geoProxyLastSelectedType]);

  const handleItemClick = (connectionType: GeoProxyType): void => {
    setGeoProxyLastSelectedType(connectionType);
    createGeoProxy({
      groupId,
      profileId: '',
      country,
      selectedConnectionType: connectionType,
      availableConnectionTypes: availableTypes,
      trafficData,
      checkTooltipView: 'proxy-list-item', // TODO: make sure it works properly
    });

    handleClose();
  };

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
      onAction: () => handleItemClick(GeoProxyType.Resident),
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
      onAction: () => handleItemClick(GeoProxyType.Mobile),
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenter {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
      onAction: () => handleItemClick(GeoProxyType.DataCenter),
    },
  ];

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, geoProxyType);
    if (remainingTrafficLimit <= TRAFFIC_LIMIT) {
      return <PurchaseTrafficButton />;
    }

    return (
      <PurchaseTrafficButtonText>
        <Trans
          i18nKey='proxies.trafficRemainingLimit'
          values={{ limit: stringifyRemainingTrafficLimit(remainingTrafficLimit) }}
        />
      </PurchaseTrafficButtonText>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    return (
      <AddButtonPopoverRow
        key={name}
        style={{ padding: POPOVER_DEFAULT_PADDING }}
        onClick={(event): void => {
          event.stopPropagation();
          const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, name);
          // TODO: remove cloning
          if (remainingTrafficLimit > TRAFFIC_LIMIT) {
            return onActionMenuItem(onAction, isDisabled);
          }

          openQuickPricing();
        }}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
      >
        {icon}
        <AddButtonPopoverRowContent>
          <AddButtonPopoverRowTitle>
            <Trans i18nKey={translationKey} />
          </AddButtonPopoverRowTitle>
          <AddButtonPopoverRowRightControls>
            {name === geoProxyLastSelectedType ? (
              <IconCheckSmall
                padding={0}
                iconColor='var(--2B2B31-header)'
              />
            ) : null}
            {renderMenuItemRightControls(name)}
          </AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </AddButtonPopoverRow>
    );
  };

  return (
    <GologinPopover
      anchorEl={anchorElement}
      onClose={(event): void => {
        event.stopPropagation();
        handleClose();
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      extraTranslate={{ bottom: 4 }}
      width={PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH}
      zIndex={1060}
    >
      {geoProxyTypeItems.map(renderMenuItem)}
      {activeGeoProxyHint ? (
        <GologinPopoverHint
          style={{ padding: POPOVER_DEFAULT_PADDING }}
          onClick={(event): void => event.stopPropagation()}
        >
          <Trans i18nKey={activeGeoProxyHint} />
        </GologinPopoverHint>
      ) : null}
    </GologinPopover>
  );
};

export default ProxyGroupAddButtonPopover;
