import styled from '@emotion/styled';

import { proxyListItemActiveStyles } from '../styles/proxy-list-item';

export const GroupedProxyItemWrapper = styled.div`
  display: flex;
  align-items: center;

  /* TODO: cloned from header */
  height: 36px;
  padding: 0 0 0 36px; // TODO: but not this
  border-radius: 4px;
  margin-bottom: 4px;

  :hover {
    ${proxyListItemActiveStyles}  
  }

  /* gap */
  & > * + * {
    margin-left: 8px;
  }
`;

export const GeoProxyIconWrapper = styled.div`
  
`;

export const GroupedProxyTitle = styled.span`
  max-width: 186px;
  flex-grow: 1;
`;

export const ProxyEditButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  .proxy-update-btn {
    display: none;
  }
`;
