import { IVpnUfoCountry, IVpnUfoCountryWithTypes, IVpnUfoProxyOptions } from './interfaces/vpn-ufo.interfaces';
import { API_BASE_URL } from '../../../../common/constants/constants';
import { IGeneratedGeoProxy } from '../../../interfaces';
import { http } from '../../../services';

export const getProxy = async () => {
  const url = `${API_BASE_URL}/users-proxies/profiles-proxies`;

  try {
    const data = await http(url, {
      method: 'GET'
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

export const checkProxyRequest = async (options: any) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/check_proxy`, {
      method: 'POST',
      body: JSON.stringify(options)
    });

    return data.body;
  } catch (error) {
    throw error;
  }
};

interface IVpnUfoCountriesResponse<CountryType> {
  countryList: CountryType[];
}

export const getVpnUfoCountries = async (query: string): Promise<IVpnUfoCountry[]> => {
  const countries: IVpnUfoCountry[] = await http(`${API_BASE_URL}/users-proxies/mobile-countries${query}`)
    .then(({ body }: { body: IVpnUfoCountriesResponse<IVpnUfoCountry> }) => body.countryList)
    .catch(() => []);

  const normalizedCountries = countries.map(country => country.countryCode === 'GB' ?
    { ...country, countryCode: 'UK' } : country);

  return normalizedCountries;
};

export const getAllVpnUfoCountries = async (): Promise<IVpnUfoCountryWithTypes[]> => {
  const countries: IVpnUfoCountryWithTypes[] = await http(`${API_BASE_URL}/users-proxies/countries`)
    .then(({ body }: { body: IVpnUfoCountriesResponse<IVpnUfoCountryWithTypes> }) => body.countryList)
    .catch(() => []);

  const normalizedCountries = countries.map(country => country.countryCode === 'GB' ?
    { ...country, countryCode: 'UK' } : country);

  return normalizedCountries;
};

export const getVpnUfoProxy = async (options: IVpnUfoProxyOptions): Promise<IGeneratedGeoProxy> => {
  let { countryCode } = options;
  if (options.countryCode?.toUpperCase() === 'UK') {
    countryCode = 'GB';
  }

  const normalizedOptions: IVpnUfoProxyOptions = { ...options, countryCode };
  const data = await http(`${API_BASE_URL}/users-proxies/mobile-proxy`, {
    method: 'POST',
    body: JSON.stringify(normalizedOptions),
  });

  return data.body;
};

export const getProfileGeoProxyParams = async (query: string): Promise<any> => {
  const data = await http(`${API_BASE_URL}/users-proxies/get_profile_geo_proxy_params?${query}`);

  return data.body;
};
