import React, { FC, useState, memo, SyntheticEvent, ChangeEvent, ClipboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckProxyButtonMarginWrapper, ProxyFormContainerEl, ProxyFormEl } from './styles';
import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { ProxyCheckTooltipView } from '../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { useProxyList } from '../../../../state/proxy/proxy-list.atom';
import { closeProxyManager, useProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { pasteProxies } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { unselectProfileProxy } from '../../../../state/proxy/proxy-operations/select-proxies.operations';
import { searchProxy } from '../../../../state/proxy/proxy-search.atom';
import { useSharedProxies } from '../../../../state/proxy/shared-proxies.atom';
import CheckProxyBtn from '../../check-proxy-button';
import { IProfileProxy } from '../../components/interfaces/IProfileProxy';
import { IProxy } from '../../components/interfaces/IProxy';
import { ISharedProxy } from '../../components/interfaces/shared-proxy.interfaces';
import ProxyFlag from '../../components/proxy-flag';
import { ProxyTitleBlockEl, ProxyTitleWrapperEl } from '../../proxy-manager/proxy-list/styles/proxy-item';
import { ProxyFlagBlockEl } from '../../proxy-manager/proxy-list/styles/proxy-list-item';
import { ProxySearchInputEl } from '../../styles/proxy-search-input';
import { getProxyTitle } from '../../utils/proxy-title';

interface IProxyForm {
  currentProxy?: IProfileProxy;
  profileId: string;
  isSharedProxy: boolean;
  editProxyMode: boolean;
  isInDrawer: boolean;
  localProxySelectorLocation: string;
  selectorContainerElement: React.RefObject<HTMLDivElement>;
}

const ProxyForm: FC<IProxyForm> = (props) => {
  const {
    currentProxy,
    profileId,
    isSharedProxy,
    editProxyMode,
    isInDrawer,
    localProxySelectorLocation,
    selectorContainerElement,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const input = useRef<HTMLInputElement>(null);

  const sharedProxies = useSharedProxies();
  const proxyList = useProxyList();
  const { currentProfileId } = useProxyManagerState();

  const { t: translation } = useTranslation();
  const placeholder = translation('proxies.searchForProxy');

  useEffect((): void => {
    setInputChanged(false);
    setSearchValue('');

    if (editProxyMode && input?.current) {
      input.current.focus();
    }
  }, [editProxyMode]);

  useEffect((): void => {
    setInputChanged(false);
    setSearchValue('');
  }, [proxyList]);

  useEffect(() => {
    searchProxy(searchValue);
  }, [searchValue, editProxyMode]);

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();

    if (inputChanged && !searchValue && currentProfileId) {
      unselectProfileProxy(currentProfileId);
      closeProxyManager();
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputChanged(true);

    setSearchValue(event.target.value);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>): void => event.currentTarget.select();

  const handlePaste = async (event: ClipboardEvent<HTMLInputElement>): Promise<void> => {
    event.persist();
    const proxies = await pasteProxies({
      profileId,
      localProxySelectorLocation,
      isInDrawer,
    });

    if (proxies.length) {
      event.preventDefault();
    }
  };

  const title = getProxyTitle(currentProxy, translation);
  const noProxy = (!currentProxy || currentProxy.mode === 'none');

  let inputWidth;
  if (noProxy) {
    inputWidth = 123;
  }

  let sharedProxy: ISharedProxy | undefined;
  let { country } = currentProxy as IProxy;
  let checkProxy = currentProxy as IProxy;
  if (isSharedProxy) {
    sharedProxy = sharedProxies.find((el) => el.profileId === profileId);
    if (sharedProxy) {
      ({ country } = sharedProxy);
      checkProxy = { ...sharedProxy, ...currentProxy };
    }
  }

  const isFailed = (currentProxy?.status === false);
  const isTransparent = (isFailed && !editProxyMode);

  const renderFlagBlock = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    let proxyTooltipView: ProxyCheckTooltipView = 'selector-profile-table';
    if (isInDrawer) {
      proxyTooltipView = localProxySelectorLocation === 'pinned-proxy' ? 'selector-profile-settings-favourite' : 'selector-profile-settings-proxy';
    }

    return (
      <ProxyFlagBlockEl newStyle={!!NEW_FEATURES.header} isFailed={isTransparent}>
        <CheckProxyButtonMarginWrapper>
          <CheckProxyBtn
            proxy={checkProxy}
            profileId={profileId}
            proxyTooltipView={proxyTooltipView}
            selectorContainerElement={selectorContainerElement}
            isSharedProxy={isSharedProxy}
          />
        </CheckProxyButtonMarginWrapper>
        <ProxyFlag region={country} />
      </ProxyFlagBlockEl>
    );
  };

  const renderInvisibleTitle = (): JSX.Element | null => {
    if (noProxy) {
      return null;
    }

    return (
      <ProxyTitleBlockEl className='proxy-title' style={{ opacity: 0 }}>
        <div className='nowrap'>
          {title || '-'}
        </div>
      </ProxyTitleBlockEl>
    );
  };

  return (
    <ProxyFormContainerEl addMaxWidth={editProxyMode} newStyle={!!NEW_FEATURES.header} isEditProxy={editProxyMode}>
      {renderFlagBlock()}
      <ProxyTitleWrapperEl newStyle={!!NEW_FEATURES.header} noProxy={noProxy}>
        <ProxyFormEl onSubmit={handleSubmit}>
          {renderInvisibleTitle()}
          <ProxySearchInputEl
            newStyle={!!NEW_FEATURES.header}
            ref={input}
            placeholder={placeholder}
            value={(!searchValue && !inputChanged) ? title : searchValue}
            onChange={handleInputChange}
            onPaste={handlePaste}
            onFocus={handleFocus}
            width={inputWidth}
            truncate={!editProxyMode}
            isTransparent={isTransparent}
            isInDrawer={isInDrawer}
            newDrawer={!!NEW_FEATURES.drawer}
          />
        </ProxyFormEl>
      </ProxyTitleWrapperEl>
    </ProxyFormContainerEl>
  );
};

export default memo(ProxyForm);
