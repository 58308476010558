/* eslint-disable max-lines */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button, Drawer, Icon, Input, message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DrawerBackdrop from './drawer-backdrop';
import { DrawerGeolocation } from './drawer-geolocation';
import DrawerHeaderButtons from './drawer-header-buttons';
import { DrawerLanguage } from './drawer-language';
import { DrawerProxy } from './drawer-proxy';
import { DrawerTimezone } from './drawer-timezone';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import { checkDoesLangExist, locales } from '../../../../common/locales';
import { IProfile } from '../../../interfaces';
import { templatesContext } from '../../../state';
import { useLocalUserDevice } from '../../../state/local-user-device.atom';
import { mapAndSetProfilesList } from '../../../state/profiles-list.atom';
import { closeProfilesSettings, useIsProfilesSettingsOpen, useProfilesSettingsProfile } from '../../../state/profiles-settings-atom';
import { useProxyList } from '../../../state/proxy/proxy-list.atom';
import IconClose from '../../../ui/icons/IconClose';
import IconRemoveFolderFromRow from '../../../ui/icons/IconRemoveFolderFromRow';
import { proxyFromClipboard } from '../../../utils/proxy-string';
import { parseTranslatedKey } from '../../../utils/translation.utils';
import { sendActionAnalytics, requestShortCookies } from '../../common/api';
import { getLanguageByBasedIp } from '../../common/get-data-by-based-ip';
import { randomWords } from '../../common/randomizer';
import ResolutionManager from '../../common/resolution-manager';
import timezones from '../../common/timezones';
import CookiesList from '../../modalsComponents/components/cookies-manager/cookies-list';
import LocalesManager from '../../modalsComponents/components/locales-manager';
import ProfileSettings from '../../profile-settings';
import ProxySelector from '../../proxy/proxy-selector';
import { updateProfileProxy as updateProfileProxyRequest } from '../../quickProfiles/api';
import Notes from '../../quickProfiles/notes-cell/notes';
import {
  fetchPatchProfileLanguage,
  refreshFingerprintRequest,
  updateProfileName,
  updateProfileResolution,
} from '../api';
import { IProfileDrawer } from '../interfaces';

const DrawerProfileHeader = styled('div')<{ newStyle: boolean }>`
  margin: ${props => props.newStyle ? '20px 0 12px 25px' : '20px 0 20px 25px'};
`;

const DrawerProfileName = styled('div')<{ newStyle: boolean }>`
  display: flex;
  font-weight: 500;
  margin-bottom: ${props => props.newStyle ? '9px' : '17px'};
  font-size: 20px;
  line-height: 24px;
  color: var(--000000);
  width: 390px;
  max-height: 100px;
  cursor: pointer;
  padding: 5px;
  border: 1px dashed transparent;
  margin-left: -6px;
  white-space: normal;
  overflow-wrap: anywhere;
  overflow: hidden;

  :hover {
    border: 1px dashed var(--CDCDCD);
  }
`;



const DrawerDotsIcon = styled('div')`
  position: absolute;
  top: 4px;
  right: 7px;
`;

const DrawerDotsButton = styled('button')`
  font-size: 12px;
  line-height: 14px;
  height: 25px;
  min-width: 31px;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: var(--828282);
  margin-left: 9px;
  transform: rotate(-90deg);
  margin-bottom: 1px;
`;





const DrawerCancel = styled('div')<{ newStyle: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  opacity: 0.7;
  right: ${props => props.newStyle ? '37px' : '23px'};
  top: 28px;
`;

const DrawerProfileBody = styled('div')`
  padding: 0;
`;

const ArrowDrawer = styled('div')`
  position: absolute;
  right: 21px;
  top: 47%;
  border: solid var(--01AD7F);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  opacity: 0;
`;

const ContainerFolderList = styled('div')`
  width: 260px;
  lineHeight: 24px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--000000);
  font-weight: 400;
`;

const ContainerParams = styled('div')`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background-color: var(--F9F9F9);
  }
  &:hover div {
    opacity: 1;
  }
`;

const ContainerParamsNote = styled('div')`
  padding-top: 20px;
  padding-bottom: 9px;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const ContainerParamsFingerprint = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-top: 25px;
`;

const DrawerNameParams = styled('span')`
  width: 80px;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  opacity: 0.5;
  margin-left: 25px;
`;

const DrawerParams = styled('span')`
  font-weight: 500;
  width: 330px;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  margin-left: 2px;
  letter-spacing: 0.25px;
`;

const DrawerProxyParams = styled('span')`
  font-weight: 500;
  width: 327px;
  font-size: 12px;
  line-height: 1.5;
  color: var(--000000);
  display: flex;
  align-items: center;
  margin-left: 2px;
  height: 59px;
  position: relative;
`;

const DrawerLanguageParams = styled('span')`
  display: flex;
  flex-wrap: wrap;
  width: 230px;
  font-size: 12px;
  color: var(--000000);
  font-weight: 500;
  margin-left: 1px;
  margin-bottom: 8px;
`;

const DrawerLanguageParamsRegion = styled('span')`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
`;

const DrawerLanguageParamsDomain = styled('span')`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--848484);
  margin-left: 5px;
  margin-right: 10px;
`;

const DrawerCookiesParams = styled('span')`
  display: flex;
  flex-wrap: wrap;
  width: 290px;
  font-size: 12px;
  color: var(--000000);
  font-weight: 500;
  margin-left: 1px;
`;

const NoCookiesSpan = styled('span')`
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  opacity: 0.5;
  border-bottom: 1px dashed var(--7E7E7E);
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const DrawerCookiesParamUrl = styled('span')`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
`;

const DrawerCookiesParamCount = styled('span')`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--848484);
  margin-left: 4px;
  margin-right: 11px;
`;

const DrawerFolderName = styled('span')`
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 40px;
`;

const DrawerRemoveProfileFromFolder = styled('span')`
  cursor: pointer;
  position: absolute;
  margin-top: 2px;
  margin-left: 6px;
`;

const FingerprintText = styled('div')`
  position: absolute;
  left: 106px;
  top: 57px;
  font-size: 12px;
  line-height: 16px;
  color: var(--7E7E7E);
  width: 328px;
`;

const DrawerDivider = styled('div')`
  border-top: 1px solid var(--E8E8E8);
`;

const ResultFingerprint = styled('span')<{ isFingerprintResult: boolean | undefined }>`
  color: var(--00A987);
  font-size: 12px;
  line-height: 16px;
  margin-left: 142px;
  transition: 0.3s;
  ${props =>
    css`
      opacity: ${props.isFingerprintResult === true ? 1 : 0};
    `}
`;

const ButtonFingerprint = styled(Button)<{ isFingerprintRefreshing: boolean | undefined }>`
  position: absolute;
  left: 106px;
  top: 20px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  height: 25px;
  min-width: 54px;
  outline: none;
  border: 1px solid var(--828282);
  border-radius: 4px;
  background-color: var(--FFFFFF);
  color: var(--828282);
  padding: 6px 12px 5px 11px;
  margin-bottom: 11px;
  :hover {
    background-color: var(--00A987);
    border-color: var(--00A987);
    color: var(--FFFFFF);
    transition: 0.3s;
  }
  ${props =>
    css`
      background-color: ${props.isFingerprintRefreshing ? 'var(--00A987)' : 'var(--FFFFFF)'};
      border-color: ${props.isFingerprintRefreshing ? 'var(--00A987)' : 'var(--828282)'};
      color: ${props.isFingerprintRefreshing ? 'var(--00A987)' : 'var(--828282)'};
    `}
`;

const ButtonLine = styled('span')`
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  opacity: 0.5;
  border-bottom: 1px dashed var(--7E7E7E);
  cursor: pointer;
  margin-left: 2px;

  :hover {
    opacity: 1;
  }
`;

const DrawerContainerProfileName = styled('div')`
  display: flex;
  font-weight: 500;
  margin-bottom: 22px;
  font-size: 20px;
  line-height: 24px;
  color: var(--000000);
  width: 400px;
`;

const AreaProfileName = styled(Input.TextArea)`
  width: 392px;
  font-size: 20px;
  color: var(--000000);
  font-weight: 500;
  padding: 5px 7px 7px 7px;
  box-shadow: none;
  resize: none;

  :focus {
    outline: none !important;
    border: 1px solid var(--CDCDCD);
    box-shadow: none;
    line-height: 24px;
    padding: 5px 7px 7px 7px;
  }

  :hover {
    outline: none !important;
    border: 1px solid var(--CDCDCD);
    box-shadow: none;
  }
`;

const ButtonRun = styled(Button)<{ width: number }>`
  background: var(--FFFFFF);
  border: 0.6px solid var(--00B86D);
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  padding-top: 2px;
  font-size: 12px;
  line-height: 13px;
  color: var(--00B86D);
  height: 25px;
  cursor: pointer;
  ${props =>
    css`
    width: ${props.width}px;
  `}

  :hover {
    background: var(--00B86D);
    color: var(--FFFFFF);
    transition: 0.2s;
  }
`;

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

let PROFILE_NAME = '';
let PROFILE_NOTES = '';
let FINGERPRINT_REFRESH_TIMEOUT: any;

const PROXY_MODES = {
  tor: 'TOR Proxy',
  gologin: 'FREE Proxy',
  http: 'HTTP Proxy',
  socks4: 'Socks4 Proxy',
  socks5: 'Socks5 Proxy',
  none: 'None',
  geolocation: 'Geolocation',
};

const ProfileDrawer: FC<IProfileDrawer> = (props) => {
  const profileInfo = useProfilesSettingsProfile();
  const profileData = profileInfo || {} as Partial<IProfile>;

  const isProfileDrawerOpen = useIsProfilesSettingsOpen();
  const localUserDevice = useLocalUserDevice();
  const { languages: realLocalLanguages = '', country: realLocalCountry = '' } = localUserDevice;

  const [profileDrawerProxyVisible, setProfileDrawerProxyVisible] = useState<boolean>(false);
  const [profileDrawerLanguageVisible, setProfileDrawerLanguageVisible] = useState<boolean>(false);
  const [profileDrawerTimezoneVisible, setProfileDrawerTimezoneVisible] = useState<boolean>(false);
  const [profileDrawerGeolocationVisible, setProfileDrawerGeolocationVisible] = useState<boolean>(false);
  const [notes, setNotes] = useState<boolean>(false);
  const [changeProfileName, setChangeProfileName] = useState<boolean>(false);
  const [cookies, setCookies] = useState<any>([]);
  const [cookiesLoading, setCookiesLoading] = useState<boolean>(true);
  const [isFingerprintRefreshing, setIsFingerprintRefreshing] = useState<boolean>(false);
  const [isFingerprintResult, setIsFingerprintResult] = useState<boolean>(false);
  const [isCookiesListModalVisible, setIsCookiesListModalVisible] = useState<boolean>(false);
  const [proxyManagerVisible, setProxyManagerVisible] = useState<boolean>(false);
  const [isLocalesManagerVisible, setIsLocalesManagerVisible] = useState<boolean>(false);
  const [isResolutionEditOpen, setIsResolutionEditOpen] = useState<boolean>(false);
  const [languagesBasedOnIp, setLanguagesBasedOnIp] = useState<string>('');

  const proxyList = useProxyList();

  const { selectedTemplate } = useContext(templatesContext);

  const { t: translation } = useTranslation();

  const { proxy: profileProxy = { id: '', mode: 'none' } } = profileData;
  const proxyProfileFromCtx = proxyList.find(listedProxy => listedProxy.id === profileProxy.id);
  const isFreeOrTorProxy = ['gologin', 'tor'].includes(profileProxy.mode || '');

  const requestCookiesProfile = async (): Promise<void> => {
    const cookiesData = await requestShortCookies(profileData.id);
    setCookies(cookiesData);
    setCookiesLoading(false);
  };

  const setVariables = (): void => {
    PROFILE_NAME = profileData?.name || '';
    PROFILE_NOTES = profileData?.notes || '';
  };

  useEffect(() => {
    if (NEW_FEATURES.drawer) {
      return;
    }

    if (!profileData.id) {
      closeProfilesSettings();
    }

    PROXY_MODES.none = translation('base.none');

    if (changeProfileName) {
      const profileNameElement = document.getElementById('profileNameId') as HTMLInputElement;
      profileNameElement?.setSelectionRange && profileNameElement?.setSelectionRange(PROFILE_NAME.length, PROFILE_NAME.length);
    }

    if (notes) {
      const noteElement = document.getElementById('noteId');
      const range = document.createRange();
      const sel = window.getSelection();

      if (PROFILE_NOTES) {
        range.setStart((noteElement as any).childNodes[0], 0);
        range.setEnd((noteElement as any).childNodes[0], PROFILE_NOTES.length);
        (sel as any).removeAllRanges();
        (sel as any).addRange(range);
      } else {
        (noteElement as any).focus();
      }
    }
  }, [profileData?.id, notes, changeProfileName]);

  useEffect(() => {
    if (NEW_FEATURES.drawer || !profileData?.id) {
      return;
    }

    setCookiesLoading(true);
    setCookies([]);
    requestCookiesProfile();
    setVariables();
  }, [profileData?.id]);

  const getData = async (): Promise<void> => {
    let languagesByBasedIp = translation('quickSettings.parameters.automatic');
    if (!isFreeOrTorProxy) {
      languagesByBasedIp = getLanguageByBasedIp({
        proxyFromCtx: proxyProfileFromCtx,
        realLocalLanguages,
        realLocalCountry,
      });
    }

    setLanguagesBasedOnIp(languagesByBasedIp);
  };

  useEffect(() => {
    getData();
  }, [profileData.proxy, profileData.id, proxyProfileFromCtx, isFreeOrTorProxy]);

  if (NEW_FEATURES.drawer) {
    return <ProfileSettings {...props} />;
  }

  const setPasteProxy = async () => {
    const pastedProxy = await proxyFromClipboard();

    sendActionAnalytics('pasted proxy from buffer');

    if (!pastedProxy) {
      message.error(translation('notifications.error.invalidProxy'));

      return;
    }

    const proxyObj = {
      ...pastedProxy,
      port: +pastedProxy.port,
      autoProxyRegion: 'us',
      torProxyRegion: 'us',
      id: profileData.id,
    };

    if (profileData.id) {
      updateProfileProxy(profileData.id, proxyObj);
      updateProfileProxyRequest(profileData.id, proxyObj);
    }

    return proxyObj;
  };

  const FingerprintResult = () => (
    <ResultFingerprint isFingerprintResult={isFingerprintResult}>
      <Icon type='check' style={{ paddingLeft: 10, fontSize: 9, position: 'relative', bottom: 2 }} />
      {' '}
      {translation('quickSettings.refreshFingerprint.successText')}
    </ResultFingerprint>
  );

  const refreshFingerprint = async () => {
    try {
      if (FINGERPRINT_REFRESH_TIMEOUT !== undefined) {
        clearTimeout(FINGERPRINT_REFRESH_TIMEOUT);
      }

      setIsFingerprintRefreshing(true);
      const [profileInfo] = await refreshFingerprintRequest(profileData?.id, selectedTemplate.id);
      updateProfileData(profileData?.id, profileInfo);
    } finally {
      setIsFingerprintRefreshing(false);
      setIsFingerprintResult(true);

      // Set notification timeout
      FINGERPRINT_REFRESH_TIMEOUT = setTimeout(() => setIsFingerprintResult(false), 1000);
    }
  };

  const saveProfileName = async () => {
    const name = PROFILE_NAME.trim().replace(/\s{2,}/g, ' ') || randomWords();
    updateProfileName({
      id: profileData.id,
      name,
    });

    mapAndSetProfilesList(profiles => profiles.map(profile => {
      if (profile?.id === profileData?.id) {
        return {
          ...profile,
          name,
          defaultProps: {
            ...profile?.defaultProps,
            profileNameIsDefault: false,
          },
        };
      }

      return profile;
    }));
  };

  const updateProfileData = (id: string, currentProfileData: any) => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === id) {
        return {
          ...profile,
          ...currentProfileData,
        };
      }

      return profile;
    }));
  };

  const updateProfileProxy = (id: string, proxy: any) => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === id) {
        return {
          ...profile,
          proxy,
          proxyEnabled: proxy.mode !== 'none',
          proxyType: proxy.mode === 'none' ? '-' : proxy.mode,
          proxyRegion:
            (proxy.mode === 'gologin' && (proxy.autoProxyRegion || 'US'))
            || (proxy.mode === 'tor' && (proxy.torProxyRegion || 'US')),
        };
      }

      return profile;
    }));
  };

  const changeProfileResolution = async (resolution: string) => {
    await updateProfileResolution(profileData.id, resolution);
    mapAndSetProfilesList(profiles => profiles.map(profile => {
      if (profile?.id !== profileData?.id) {
        return profile;
      }

      return {
        ...profile,
        navigator: {
          ...profile?.navigator,
          resolution,
        },
      };
    }));

    setIsResolutionEditOpen(false);
  };

  const setProfileNavigatorLanguage = (save: boolean, autoLang = true, language = ''): void => {
    setIsLocalesManagerVisible(false);

    if (!(save && language)) {
      return;
    }

    fetchPatchProfileLanguage(profileData.id, language, autoLang);

    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === profileData?.id) {
        return {
          ...profile,
          navigator: {
            ...profile?.navigator,
            language,
          },
          autoLang,
        };
      }

      return profile;
    }));
  };

  const updateProfileGeolocation = (id: string, geolocation: any) => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === id) {
        return {
          ...profile,
          geolocation,
        };
      }

      return profile;
    }));
  };

  const updateProfileTimezone = (id: string, timezone: any) => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === id) {
        return {
          ...profile,
          timezone,
        };
      }

      return profile;
    }));
  };

  const ChangeProfileName = () => {
    let profileName = profileData.name || '';
    const { isAutoGenerated, defaultProps = {} } = profileData;
    if (isAutoGenerated && defaultProps.profileNameIsDefault) {
      profileName = parseTranslatedKey(translation('profiles.firstProfileName'));
      PROFILE_NAME = profileName;
    } else {
      PROFILE_NAME = profileData.name || '';
    }

    if (changeProfileName) {
      return (
        <>
          <DrawerContainerProfileName style={{ marginLeft: -8, marginBottom: 15 }}>
            <AreaProfileName
              id={'profileNameId'}
              autoFocus={true}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  saveProfileName();
                  setChangeProfileName(false);
                }
              }}
              onChange={(event => PROFILE_NAME = event.target.value)}
              autoSize={{ minRows: 1, maxRows: 4 }}
              onBlur={() => {
                saveProfileName();
                setTimeout(() => setChangeProfileName(false), 0);
              }}
              defaultValue={PROFILE_NAME}
              placeholder={translation('base.profileName')}
              style={{ lineHeight: '24px' }}
            />
            <DrawerCancel onClick={closeProfilesSettings} newStyle={!!NEW_FEATURES.header}>
              <IconClose />
            </DrawerCancel>
          </DrawerContainerProfileName>
        </>
      );
    }

    return (
      <>
        <DrawerProfileName
          onClick={(): void => setChangeProfileName(true)}
          newStyle={!!NEW_FEATURES.header}
        >
          {profileName}
        </DrawerProfileName>
        <DrawerCancel onClick={closeProfilesSettings} newStyle={!!NEW_FEATURES.header}>
          <IconClose />
        </DrawerCancel>
      </>
    );
  };

  const showNotes = (): JSX.Element => {
    const { isAutoGenerated, defaultProps = {} } = profileData;

    return (
      <ContainerParamsNote style={{ paddingBottom: 19 }}>
        <DrawerNameParams style={{ width: 73 }}>
          {translation('quickSettings.notes.title')}
        </DrawerNameParams>
        <div className="ql-drawer-container">
          <Notes
            notes={profileData.notes}
            profileId={profileData.id}
            saveProfileNotes={props.saveProfileNotes}
            isDefaultNotes={!!(isAutoGenerated && defaultProps?.profileNotesIsDefault)}
            location='old-drawer'
          />
        </div>
      </ContainerParamsNote>
    );
  };

  const renderFolderName = () => profileData.folders.map((folderName: string, index: number) => {
    const folderNameEmoji = folderName.length >= 15 ? folderName.substr(0, 15) + '...' : folderName;

    return (
      <div key={`renderFolderName-${index}`}>
        <div>
          <DrawerFolderName key={folderName}>
            {folderNameEmoji}
          </DrawerFolderName>
          <DrawerRemoveProfileFromFolder
            onClick={(): Promise<void> => props.removeProfilesFromFolder(folderName, [profileData.id])}
          >
            <IconRemoveFolderFromRow />
          </DrawerRemoveProfileFromFolder>
        </div>
      </div>
    );
  });

  const foldersList = () => {
    if (!(profileData.folders?.length)) {
      return (
        <>
          <DrawerNameParams>
            {translation('folders.title')}
          </DrawerNameParams>
          <ButtonLine onClick={(): void => props.openFoldersModal([profileData.id])}>
            {translation('quickSettings.folders.actionAddText')}
          </ButtonLine>
        </>
      );
    }

    return (
      <>
        <DrawerNameParams style={{ width: 41 }}>
          {translation('folders.title')}
        </DrawerNameParams>
        <ContainerFolderList>
          {renderFolderName()}
        </ContainerFolderList>
        <ButtonLine onClick={(): void => props.openFoldersModal([profileData.id])}>
          {translation('quickSettings.folders.actionAddText')}
        </ButtonLine>
      </>
    );
  };

  const showProfileDrawerChild = (Child: any) => {
    Child(true);
  };

  const closeProfileDrawerСhildren = (): void => {
    setProfileDrawerProxyVisible(false);
    setProfileDrawerLanguageVisible(false);
    setProfileDrawerTimezoneVisible(false);
    setProfileDrawerGeolocationVisible(false);
  };

  const [profileTimezone] = timezones.filter(tz => tz.name === profileData?.timezone?.timezone);
  const currentUsingLanguages = profileData.autoLang ? languagesBasedOnIp : profileData?.navigator?.language;
  const separatedLanguages = (currentUsingLanguages || '')
    .split(',')
    .map(item => item.split(';')[0])
    .filter(key => checkDoesLangExist(key));

  let proxyRes = profileData?.proxy;
  if (proxyRes?.id) {
    proxyRes = proxyList.find(listedProxy => listedProxy.id === proxyRes.id);
    if (!proxyRes) {
      proxyRes = profileData?.proxy;
    }
  }

  const renderLanguageItem = (language: string, index: number): JSX.Element | null => {
    const languageLimit = 9;

    if (index > languageLimit) {
      return null;
    }

    return (
      <div>
        <DrawerLanguageParamsRegion>
          {locales[language]?.replace(/\(.*\)/g, '')?.trim()}
        </DrawerLanguageParamsRegion>
        <DrawerLanguageParamsDomain>
          {language}
          {languageLimit === index ? (
            <DrawerLanguageParamsRegion>
              ...
            </DrawerLanguageParamsRegion>
          ) : null}
        </DrawerLanguageParamsDomain>
      </div>
    );
  };



  const renderLanguages = (): JSX.Element => {
    if (isFreeOrTorProxy && profileData.autoLang) {
      return (
        <span>
          {languagesBasedOnIp}
        </span>
      );
    }

    return (
      <>
        {separatedLanguages.map((lang: string, index: number) => (
          <div
            key={`DrawerLanguageParams-${index}`}
          >
            {renderLanguageItem(lang, index)}
          </div>
        ))}
      </>
    );
  };

  const contentDrawer = () => (
    <div style={{ fontFamily: 'Roboto' }}>
      <DrawerProfileHeader newStyle={!!NEW_FEATURES.header}>
        {ChangeProfileName()}
        <DrawerHeaderButtons
          profile={profileInfo}
          renderDropDownMenu={props.renderDropDownMenu}
          launchProfileOrbita={props.launchProfileOrbita}
        />
      </DrawerProfileHeader>
      <DrawerDivider />
      <DrawerProfileBody>
        {showNotes()}
        <DrawerDivider />
        <ContainerParams
          style={{
            alignItems: 'baseline',
            paddingTop: 23,
            paddingBottom: 23,
          }}
        >
          {foldersList()}
        </ContainerParams>
        <DrawerDivider />
        <ContainerParams
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => {
            setProxyManagerVisible(true);
          }}
        >
          <DrawerNameParams>
            {translation('proxy.title')}
          </DrawerNameParams>
          <DrawerProxyParams style={{
            width: 385,
            marginLeft: NEW_FEATURES.header ? -31 : -15,
          }}>
            <ProxySelector
              proxy={profileData.proxy}
              proxyEnabled={profileData.proxyEnabled}
              profileId={profileData.id}
              isInDrawer={true}
              isSharedProxy={profileData.role !== 'owner'}
              localProxySelectorLocation='old-drawer'
            />
          </DrawerProxyParams>
        </ContainerParams>

        <DrawerDivider />
        <ContainerParams
          style={{ alignItems: 'baseline', paddingTop: 21, paddingBottom: 12 }}
          // TODO: Create Locales Form
          // onClick={() => showProfileDrawerChild(setProfileDrawerLanguageVisible)}
          onClick={() => {
            setIsLocalesManagerVisible(true);
          }}
        >
          <DrawerNameParams>
            {translation('language.title')}
          </DrawerNameParams>
          <DrawerLanguageParams>
            {renderLanguages()}
          </DrawerLanguageParams>
          <ArrowDrawer />
        </ContainerParams>
        <DrawerDivider />
        <ContainerParams
          style={{
            paddingTop: 21,
            paddingBottom: 20,
            cursor: 'pointer',
          }}
          onClick={() => {
            showProfileDrawerChild(setProfileDrawerTimezoneVisible);
          }}
        >
          <DrawerNameParams>
            {translation('timezone.title')}
          </DrawerNameParams>
          <DrawerParams style={{ marginLeft: 1 }}>
            {profileData?.timezone?.fillBasedOnIp
              ? translation('timezone.fillBasedOnIpTxt')
              : `${profileTimezone?.name} (${profileTimezone?.offset})`}
          </DrawerParams>
          <ArrowDrawer />
        </ContainerParams>
        <DrawerDivider />
        <ContainerParams
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            cursor: 'pointer',
          }}
          onClick={() => {
            showProfileDrawerChild(setProfileDrawerGeolocationVisible);
          }}
        >
          <DrawerNameParams>
            {translation('geolocation.title')}
          </DrawerNameParams>
          <DrawerParams style={{ marginLeft: 1 }}>
            {profileData?.geolocation?.mode === 'block'
              ? translation('quickSettings.geolocation.block.label')
              : translation('quickSettings.geolocation.allow.label')}
          </DrawerParams>
          <ArrowDrawer />
        </ContainerParams>
        <DrawerDivider />
        <ContainerParams
          style={{
            paddingTop: 20,
            paddingBottom: 20,
          }}
          onClick={() => profileData.os !== 'android' && setIsResolutionEditOpen(true)}
        >
          <DrawerNameParams>
            {translation('base.screenResolution')}
          </DrawerNameParams>
          <DrawerParams>
            {!isResolutionEditOpen ? profileData.navigator?.resolution : (
              <div style={{ marginLeft: -9 }} onClick={e => e.stopPropagation()}>
                <ResolutionManager
                  updateResolution={changeProfileResolution}
                  isDisabled={profileData.os === 'android'}
                  resolution={profileData.navigator?.resolution}
                  width='200px'
                  autoOpen={true}
                  onClose={(): void => setIsResolutionEditOpen(false)}
                />
              </div>
            )}
          </DrawerParams>
        </ContainerParams>
        <DrawerDivider />
        <ContainerParams
          style={{
            alignItems: 'baseline',
            paddingTop: 20,
            paddingBottom: cookies.length ? 20 : 21,
          }}
          onClick={() => {
            setIsCookiesListModalVisible(true);
          }}
        >
          <DrawerNameParams>
            {translation('base.cookies')}
          </DrawerNameParams>
          <DrawerCookiesParams>
            {cookiesLoading ? <NoCookiesSpan style={{ opacity: 1, borderColor: 'transparent' }}>
              {translation('quickSettings.cookies.loadingTxt')}
            </NoCookiesSpan> : null}

            {!cookiesLoading && !cookies.length && <NoCookiesSpan>
              {translation('quickSettings.cookies.emptyTxt')}
                                                   </NoCookiesSpan>}

            {!cookiesLoading && cookies.map((cookie: any, index: number) => (
              <div
                key={`DrawerCookiesParams-${index}`}
              >
                <div
                  style={{ marginTop: -2 }}
                >
                  <DrawerCookiesParamUrl>
                    {cookie.cookie}
                  </DrawerCookiesParamUrl>
                  <DrawerCookiesParamCount>
                    {cookie.count}
                  </DrawerCookiesParamCount>
                </div>
              </div>
            ))}
            {cookies.length >= 10 && <DrawerCookiesParamUrl>
              ...
            </DrawerCookiesParamUrl>}
          </DrawerCookiesParams>
          <ArrowDrawer />
        </ContainerParams>
        <DrawerDivider />
        <ContainerParamsFingerprint>
          <DrawerNameParams style={{ alignItems: 'normal' }}>
            {translation('base.fingerprint')}
          </DrawerNameParams>
          <ButtonFingerprint onClick={refreshFingerprint} isFingerprintRefreshing={isFingerprintRefreshing}>
            {isFingerprintRefreshing ? <Icon type='loading' style={{ fontSize: 16, marginTop: -3 }} /> : translation('quickSettings.fingerprint.refreshActionTxt')}
          </ButtonFingerprint>
          {FingerprintResult()}
          <FingerprintText>
            {translation('quickSettings.fingerprint.help')}
          </FingerprintText>
        </ContainerParamsFingerprint>
      </DrawerProfileBody>
    </div>
  );

  const drawerProxy = (
    <DrawerProxy
      profileInfo={{
        ...profileData,
        proxy: proxyRes,
      }}
      changeVisibility={closeProfileDrawerСhildren}
      updateProfileInfoProxy={updateProfileProxy}
      setPasteProxy={setPasteProxy}
      showProxyManager={() => setProxyManagerVisible(true)}
    />
  );

  const showContent = () => {
    if (profileDrawerProxyVisible) {
      return (
        <>
          <Drawer
            bodyStyle={{ padding: 0 }}
            visible={isProfileDrawerOpen}
            placement={'right'}
            maskStyle={{
              backgroundColor: 'inherit',
            }}
            mask={false}
            onClose={() => {
              closeProfileDrawerСhildren();
              closeProfilesSettings();
            }}
            width={475}
            closable={false}
          >
            {drawerProxy}
          </Drawer>
        </>
      );
    }

    if (profileDrawerLanguageVisible) {
      return (
        <>
          <Drawer
            bodyStyle={{ padding: 0 }}
            visible={isProfileDrawerOpen}
            placement={'right'}
            maskStyle={{
              backgroundColor: 'inherit',
            }}
            mask={false}
            onClose={() => {
              closeProfileDrawerСhildren();
              closeProfilesSettings();
            }}
            width={475}
            closable={false}
          >
            <DrawerLanguage
              profileInfo={profileData}
              changeVisibility={closeProfileDrawerСhildren}
            />
          </Drawer>
        </>
      );
    }

    if (profileDrawerTimezoneVisible) {
      return (
        <>
          <Drawer
            bodyStyle={{ padding: 0 }}
            visible={isProfileDrawerOpen}
            placement='right'
            maskStyle={{
              backgroundColor: 'inherit',
            }}
            mask={false}
            onClose={() => {
              closeProfileDrawerСhildren();
              closeProfilesSettings();
            }}
            width={475}
            closable={false}
          >
            <DrawerTimezone
              profileInfo={profileData}
              changeVisibility={closeProfileDrawerСhildren}
              updateProfileTimezone={updateProfileTimezone}
            />
          </Drawer>
        </>
      );
    }

    if (profileDrawerGeolocationVisible) {
      return (
        <>
          <Drawer
            bodyStyle={{ padding: 0 }}
            visible={isProfileDrawerOpen}
            placement='right'
            width={475}
            maskStyle={{
              backgroundColor: 'inherit',
            }}
            mask={false}
            onClose={() => {
              closeProfileDrawerСhildren();
              closeProfilesSettings();
            }}
            closable={false}
          >
            <DrawerGeolocation
              profileInfo={profileData}
              changeVisibility={closeProfileDrawerСhildren}
              updateProfileGeolocation={updateProfileGeolocation}
            />
          </Drawer>
        </>
      );
    }

    return (
      <>
        <Drawer
          bodyStyle={{ padding: 0 }}
          visible={isProfileDrawerOpen}
          placement='right'
          maskStyle={{
            backgroundColor: 'inherit',
          }}
          mask={false}
          onClose={closeProfilesSettings}
          width={475}
          closable={false}
          drawerStyle={{ overflow: 'auto' }}
        >
          {contentDrawer()}
          <DrawerBackdrop />
        </Drawer>
      </>
    );
  };

  const afterModalClose = () => {
    if (document.getElementsByTagName('body')[0].hasAttribute('style')) {
      document.getElementsByTagName('body')[0].removeAttribute('style');
    }
  };

  return (
    <div onClick={(event): void => event.stopPropagation()} style={{ position: 'relative' }}>
      {showContent()}
      <CookiesList
        id={profileData?.id}
        name={profileData?.name}
        afterClose={afterModalClose}
        cookiesListModalVisible={isCookiesListModalVisible}
        toggleCookiesListModal={({ boolean }: any) => setIsCookiesListModalVisible(boolean)}
        onCookiesRequest={requestCookiesProfile}
      />
      <LocalesManager
        languages={profileData?.navigator?.language || ''}
        languagesBasedOnIp={languagesBasedOnIp}
        localesModalVisible={isLocalesManagerVisible}
        afterClose={afterModalClose}
        toggleLocalesListModal={setProfileNavigatorLanguage}
        autoLang={!!profileData.autoLang}
      />
    </div>
  );
};

export default React.memo(ProfileDrawer);
