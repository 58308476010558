import { TFunction } from 'i18next';

import { GEOPROXY_COUNT_KEY_PREFIX } from '../../../../common/constants/local-storage';
import { PROXY_GROUP_HEADER_ID_POSTFIX } from '../../../state/proxy/proxy-groups/constants';
import { IProxy } from '../components/interfaces/IProxy';

export interface ICustomNameObject {
  countryCode: string;
  postfix: string;
}

// TODO: remove translating after Sergey's tests
const COUNTRIES_KEY_BASE = 'proxy.countries';

export const generateGeoProxyCustomName = (countryCode: string, shouldSaveToStorage = true): string => {
  const storageKey = `${GEOPROXY_COUNT_KEY_PREFIX}${countryCode.toLowerCase()}`;
  const count = +(localStorage.getItem(storageKey) || '0');
  const countNew = count + 1;
  if (shouldSaveToStorage) {
    localStorage.setItem(storageKey, `${countNew}`);
  }

  return `${COUNTRIES_KEY_BASE}.${countryCode.toLowerCase()} - ${countNew}`;
};

const getGeoProxyCustomNameObject = (customName?: string): ICustomNameObject | null => {
  if (!customName) {
    return null;
  }

  if (!(customName.startsWith(`${COUNTRIES_KEY_BASE}.`) && customName.includes(' - '))) {
    return null;
  }

  const [countryCode, postfix] = customName.replace(`${COUNTRIES_KEY_BASE}.`, '').split(' - ');
  if (!(countryCode && countryCode.length === 2 && postfix)) {
    return null;
  }

  return { countryCode: countryCode.toLowerCase(), postfix: ` - ${postfix}` };
};

interface IProxyTitleObject {
  key: string;
  value: string;
  prefix: string;
  postfix: string;
}

const PROXY_TITLE_DEFAULT: IProxyTitleObject = { key: '', value: '', prefix: '', postfix: '' };

export const getProxyTitle = (proxy: IProxy, translation?: TFunction): string => {
  let customNameObject: ICustomNameObject|null;
  let proxyTitleObject: IProxyTitleObject = PROXY_TITLE_DEFAULT;
  switch (true) {
    case !!proxy.id?.endsWith(PROXY_GROUP_HEADER_ID_POSTFIX):
      proxyTitleObject = { ...PROXY_TITLE_DEFAULT, key: `${COUNTRIES_KEY_BASE}.${proxy.country?.toLowerCase()}` };
      break;
    case proxy.mode === 'gologin':
      proxyTitleObject = { ...PROXY_TITLE_DEFAULT, key: `${COUNTRIES_KEY_BASE}.${proxy.autoProxyRegion?.toLowerCase()}`, prefix: 'Free / ' };
      break;
    case proxy.mode === 'tor':
      proxyTitleObject =  { ...PROXY_TITLE_DEFAULT, key: `${COUNTRIES_KEY_BASE}.${proxy.torProxyRegion?.toLowerCase()}`, prefix: 'Tor / ' };
      break;
    case !!proxy.customName:
      customNameObject = getGeoProxyCustomNameObject(proxy.customName);
      if (!customNameObject) {
        proxyTitleObject = { ...PROXY_TITLE_DEFAULT, value: proxy.customName };
        break;
      }

      proxyTitleObject = {
        ...PROXY_TITLE_DEFAULT,
        key: `${COUNTRIES_KEY_BASE}.${customNameObject.countryCode}`,
        postfix: customNameObject.postfix,
      };

      break;
    case !!proxy.host:
      proxyTitleObject = { ...PROXY_TITLE_DEFAULT, value: proxy.host };
      break;
    default:
      break;
  }

  const { key, value, prefix, postfix } = proxyTitleObject;

  const translatedValue = translation ? translation(key) : key;

  return prefix + (value || translatedValue) + postfix;
};
