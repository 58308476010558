import { IProxy } from './components/interfaces/IProxy';
import { IProxyCredentialsNew } from './components/interfaces/proxy-credentials-new.interface';
import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';

export const createProxiesRequest = async (proxies: IProxyCredentialsNew[]): Promise<IProxy[]> => {
  const workspaceId = getCurrentWorkspaceId();
  const url = new URL(`${API_BASE_URL}/proxy/add_proxies`);
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  const body = JSON.stringify({ proxies });

  return http(url.toString(), { method: 'POST', body }).then((response: { body: IProxy[] }) => response.body);
};

export const updateProxyRequest = async (proxyId: string, proxy: IProxyCredentialsNew, profileId = ''): Promise<IProxy> => {
  const url = new URL(`${API_BASE_URL}/proxy/${proxyId}`);
  url.searchParams.append('profileId', profileId);
  const body = JSON.stringify(proxy);

  return http(url.toString(), { method: 'PUT', body }).then((response: { body: IProxy }) => response.body);
};

export const deleteProxiesRequest = async (proxies: string[]): Promise<IProxy> => {
  const body = JSON.stringify({ proxies });

  return http(`${API_BASE_URL}/proxy/delete_proxies`, { method: 'POST', body }).then((response: { body: IProxy }) => response.body);
};

export const getProxyRequest = async (id: string): Promise<IProxy> => http(`${API_BASE_URL}/proxy/${id}`)
  .then((response: { body: IProxy }) => response.body);
