import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupedProxyTitle, UnroupedProxyItemWrapper } from './styles';
import { IUngroupedProxy } from '../../../../../state/proxy/proxy-groups/interfaces';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import CheckProxyBtn from '../../../check-proxy-button';
import ProxyEditButton from '../../../check-proxy-tooltip/proxy-info/proxy-edit-button';
import ProxyFlag from '../../../components/proxy-flag';
import { getIsProxyEditable } from '../../../proxy-helpers';
import { getProxyTitle } from '../../../utils/proxy-title';
import { ProxyEditButtonWrapper } from '../grouped-proxy-item/styles';
import { ProxyGroupStatusDotWrapper } from '../proxy-group-header/styles';
import { ProfilesCounterWrapper } from '../styles/proxy-list-item';

interface IUngroupedProxyItem {
  ungroupedProxy: IUngroupedProxy;
  currentProfileId: string|null;
  handleRowClick: React.MouseEventHandler<HTMLDivElement>;
  handleContextMenu: React.MouseEventHandler<HTMLDivElement>;
  style: React.CSSProperties;
  selectorContainerElement?: React.RefObject<HTMLDivElement>; // TODO: needed?
}

// TODO: add feature-toggle here
const UngroupedProxyItem: React.FC<IUngroupedProxyItem> = (props) => {
  const { ungroupedProxy, currentProfileId, handleRowClick, handleContextMenu, style, selectorContainerElement } = props;

  const { t: translation } = useTranslation();

  const title = getProxyTitle(ungroupedProxy, translation);

  const isProxyEditable = getIsProxyEditable(ungroupedProxy);
  const profilesCount = ungroupedProxy.profilesCount || 0;

  // TODO: why cloned?
  const handleProxyInfoEditClick = (): void => {
    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: ungroupedProxy,
      currentProfileId,
      modalEditingProxyId: ungroupedProxy.id,
      // proxySelectorLocation: '' // TODO: which location?
    });
  };

  const renderLeftControls = (): JSX.Element => {
    console.log('renderLeftControls');
    // if (shouldShowCheckbox) {
    //   return <ProxyItemCheckbox proxy={proxy} />;
    // }

    // TODO: not sure whether should actually reuse
    return (
      <ProxyGroupStatusDotWrapper>
        <CheckProxyBtn
          proxy={ungroupedProxy}
          profileId={currentProfileId || ''}
          proxyTooltipView='proxy-list-item'
          selectorContainerElement={selectorContainerElement}
        />
      </ProxyGroupStatusDotWrapper>
    );
  };

  const renderRightControls = (): JSX.Element|null => {
    if (!isProxyEditable) {
      return null;
    }

    return (
      <ProxyEditButtonWrapper onClick={(event): void => {
        event.preventDefault();
        event.stopPropagation();
      }}>
        <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} />
        <ProfilesCounterWrapper className='proxy-profiles-counter-wrapper'>
          {profilesCount}
        </ProfilesCounterWrapper>
      </ProxyEditButtonWrapper>
    );
  };

  return (
    <UnroupedProxyItemWrapper
      // TODO: needed?
      style={{
        ...style,
        paddingLeft: 0,
        height: 36,
        margin: 4,
      }}
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
    >
      {renderLeftControls()}
      <ProxyFlag region={ungroupedProxy.country} />
      <GroupedProxyTitle>
        {title}
      </GroupedProxyTitle>
      {renderRightControls()}
    </UnroupedProxyItemWrapper>
  );
};

export default UngroupedProxyItem;
