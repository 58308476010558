import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { Icon } from 'antd';

const antDotIconHideStyles = css`
  opacity: 0;

  tr.row-visible-dot & {
    opacity: 1;
  }

  .area-active &,
  tr:not(.row-active):hover & {
    opacity: 1;
  }

  .ant-drawer-body &,
  tr.row-active .allow-hover & {
    opacity: 1;
  }
`;

export const CheckProxyButtonWrapper = styled.div<{
  newStyle: boolean;
  isTooltipVisible: boolean;
  isCheckButtonVisible?: boolean;
}>`
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;

  ${(props): SerializedStyles|null => props.isCheckButtonVisible ? null : antDotIconHideStyles}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    display: ${props.isTooltipVisible || props.isCheckButtonVisible ? 'flex' : 'none'};
    background-color: inherit;
  ` : null}
`;

export const ProxyCheckSpinnerIcon = styled(Icon)`
  color: var(--767676-proxy-manager-check-proxy-button);
  font-size: 16px;
  cursor: pointer;
`;
