import { makeStyles } from '@material-ui/core';
import { message } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';

import { NoGroupsProxyContextMenuDivider, NoGroupsProxyContextMenuWrapper } from './styles';
import { IProfile, IProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfilesList } from '../../../state/profiles-list.atom';
import { showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { useProxyManagerListEntities } from '../../../state/proxy/proxy-groups/proxy-groups.atom';
import { openProxyManager, useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { selectAllProxies, toggleIsProxySelected, updateSelectedProxies, useSelectedProxies } from '../../../state/proxy/selected-proxies.atom';
import {
  hideProxyContextMenu,
  switchConfirmBlockVisible,
  switchIsSelectProxyModeOpened,
  useIsProxyContextMenuVisible,
  useIsSelectProxyModeOpened,
  useProxySelectMenuPosition,
} from '../../../state/proxy-select-menu.atom';
import { IconBinTrashDelete, IconGear } from '../../../ui/gologin-header/icons';
import { IconCheckboxChecked } from '../../../ui/gologin-header/icons/icon-checkbox-checked';
import { IconProxyCopy } from '../../../ui/gologin-header/icons/icon-proxy-copy';
import { IconRefreshTwoArrows } from '../../../ui/gologin-header/icons/icon-refresh-two-arrows';
import { IMenuDivider, IMenuItem } from '../../quickProfiles/profile-dropdown-menu/interfaces';
import { Divider, DividerContainer, ItemRow, itemRowIconProps, PopoverCustom } from '../../quickProfiles/profile-dropdown-menu/styles';
import { PROXY_MODES_WITH_ACTIONS_ALLOWED } from '../constants/settings';
import { copyProxies, getIsProxyArchived, restoreProxy } from '../proxy-helpers';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));

const ProxyContextMenu: React.FC = () => {
  const proxyManagerListEntities = useProxyManagerListEntities();
  const profilesList = useProfilesList();
  const isProxyContextMenuVisible = useIsProxyContextMenuVisible();
  const position = useProxySelectMenuPosition();
  const { selectedProxy, selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { currentProfileId, proxySelectorLocation } = useProxyManagerState();

  const classes = useStyles();

  let currentProfile: IProfile | null = null;
  if (currentProfileId) {
    currentProfile = profilesList.find(({ id }) => id === currentProfileId) || null;
  }

  const isSharedProxy = currentProfile ? currentProfile.role !== 'owner' : false;

  useEffect(() => {
    const handleClick = (): void => {
      hideProxyContextMenu();
    };

    const handleOpenContextMenu = (event: MouseEvent): void => {
      const profilesTableEl = document.querySelector('.proxy-popper-container');
      if (profilesTableEl && !profilesTableEl.contains(event.target as Node)) {
        hideProxyContextMenu();
      }
    };

    document.addEventListener('contextmenu', handleOpenContextMenu);
    document.addEventListener('click', handleClick);

    return (): void => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleOpenContextMenu);
    };
  }, []);

  if (!(isProxyContextMenuVisible && selectedProxy)) {
    return null;
  }

  const areActionsAllowed = PROXY_MODES_WITH_ACTIONS_ALLOWED.includes(selectedProxy.mode);

  const handleEditProxy = async (): Promise<void> => {
    let proxyForEdit = selectedProxy;
    if (getIsProxyArchived(selectedProxy)) {
      proxyForEdit = await restoreProxy(selectedProxy);
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxyForEdit,
      currentProfileId,
      modalEditingProxyId: proxyForEdit.id,
      proxySelectorLocation,
    });
  };

  const handleSelectProxy = (): void => {
    toggleIsProxySelected(selectedProxy.id);
    updateSelectedProxies({ lastSelectedProxy: selectedProxy.id });
    if (!isSelectProxyModeOpened) {
      switchIsSelectProxyModeOpened(true);
    }
  };

  const handleSelectAllProxies = (): void => {
    // TODO: type properly before the proxyGroups release
    const proxies = proxyManagerListEntities.filter(entity => entity && entity.host);
    if (selectedProxies.length === proxies.length) {
      updateSelectedProxies({ selectedProxies: [] });
    } else {
      selectAllProxies();
    }
  };

  const handleDeleteProxy = (): void => {
    switchConfirmBlockVisible(true);
  };

  const handleCheckProxy = async (): Promise<void> => {
    let proxyToCheck = selectedProxy;
    if (getIsProxyArchived(selectedProxy)) {
      proxyToCheck = await restoreProxy(selectedProxy);
    }

    const profileId = currentProfileId || '';
    const proxyChecked = await updateProxyStatuses({
      proxies: [proxyToCheck],
      profileId,
      isSharedProxy,
      // TODO: shouldRestoreProxy
    });

    const proxyCheckedFull: IProxy = { ...proxyToCheck, ...proxyChecked, checkDate: moment().toDate() };
    showProxyCheckTooltip({
      profileIds: [profileId],
      proxies: [proxyCheckedFull],
      view: 'proxy-list-item', // TODO: fix in other places
      timeout: 2000,
    });
  };

  const handleCopyProxy = (): void => {
    copyProxies([selectedProxy]);
    message.success(<Trans i18nKey='base.copiedText' />);
  };

  if (!NEW_FEATURES.proxyGroups) {
    return ReactDOM.createPortal(
      <NoGroupsProxyContextMenuWrapper left={position.x} top={position.y}>
        <ul>
          {areActionsAllowed ? (
            <>
              <li onClick={handleSelectProxy}>
                {<Trans i18nKey='proxies.selectProxy' />}
              </li>
              <li onClick={handleCopyProxy}>
                {<Trans i18nKey='proxies.copyProxy' />}
              </li>
              <li onClick={handleDeleteProxy}>
                {<Trans i18nKey='proxies.deleteProxy' />}
              </li>
              <NoGroupsProxyContextMenuDivider />
            </>
          ) : null}
          <li onClick={handleSelectAllProxies}>
            {<Trans i18nKey='proxies.selectAllProxies' />}
          </li>
        </ul>
      </NoGroupsProxyContextMenuWrapper>,
      document.body,
    );
  }

  const actions: (IMenuItem | IMenuDivider)[] = [
    {
      name: 'editProxy',
      translationKey: 'proxies.editProxy',
      icon: <IconGear {...itemRowIconProps} />,
      onAction: handleEditProxy,
      isVisible: areActionsAllowed,
    },
    {
      name: 'deleteProxy',
      translationKey: 'proxies.deleteProxy',
      icon: <IconBinTrashDelete {...itemRowIconProps} />,
      onAction: handleDeleteProxy,
      isVisible: areActionsAllowed,
    },
    {
      divider: true,
      isVisible: areActionsAllowed,
    },
    {
      name: 'selectProxy',
      translationKey: 'proxies.selectProxy',
      icon: <IconCheckboxChecked {...itemRowIconProps} />,
      onAction: handleSelectProxy,
      isVisible: areActionsAllowed,
    },
    {
      name: 'selectAllProxies',
      translationKey: 'proxies.selectAllProxies',
      icon: <IconCheckboxChecked {...itemRowIconProps} />,
      onAction: handleSelectAllProxies,
      isVisible: true,
    },
    {
      name: 'checkProxy',
      translationKey: 'proxies.checkProxy',
      icon: <IconRefreshTwoArrows {...itemRowIconProps} />,
      onAction: handleCheckProxy,
      isVisible: areActionsAllowed,
    },
    {
      name: 'copyProxy',
      translationKey: 'proxies.copyProxy',
      icon: <IconProxyCopy {...itemRowIconProps} />,
      onAction: handleCopyProxy,
      isVisible: areActionsAllowed,
    },
  ];

  // TODO: separate commons parts with profile-dropdown-menu
  const isMenuDivider = (menuItem: IMenuItem | IMenuDivider): menuItem is IMenuDivider => (menuItem as IMenuDivider).divider;

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItem = (menuItem: IMenuItem | IMenuDivider): JSX.Element => {
    if (isMenuDivider(menuItem)) {
      return (
        <DividerContainer>
          <Divider />
        </DividerContainer>
      );
    }

    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    return (
      <ItemRow
        key={name}
        isDisabled={isDisabled}
        onClick={(): void => onActionMenuItem(onAction, isDisabled)}
      >
        {icon}
        <span style={{ marginLeft: 8 }}>
          <Trans i18nKey={translationKey || `profiles.menu.${name}`} />
        </span>
      </ItemRow>
    );
  };

  return (
    <PopoverCustom
      open={true}
      onClose={hideProxyContextMenu}
      style={{
        zIndex: 1060,
        top: position.y,
        left: position.x,
      }}
      disableRestoreFocus={true}
      className={classes.popover}
      classes={{
        paper: classes.popoverContent,
      }}
    >
      {actions.filter(action => action.isVisible ?? true).map(renderMenuItem)}
    </PopoverCustom>
  );
};

export default ProxyContextMenu;
