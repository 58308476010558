import { handleUpdateProxiesRequestError } from './handle-proxies-update-error';
import { updateProfileProxyWithErrorHandling } from './update-proxies.operations';
import { determineIsTorOrFreeProxy } from '../../../../common/constants/types';
import { sendActionAnalytics } from '../../../features/common/api';
import { EMPTY_PROXY } from '../../../features/proxy/constants';
import { IProfile, IProxy } from '../../../interfaces';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../../profiles-list.atom';
import { updateProxySelectionDate } from '../proxy-groups/proxy-selection-dates.atom';
import { decrementProxyProfilesCount, incrementProxyProfilesCount } from '../proxy-list.atom';

const getProxyProfileToLink = (profileId: string, proxy: IProxy, profilesListArg?: IProfile[]): IProfile | null => {
  const profilesList = profilesListArg || getProfilesList();
  const profileInfo = profilesList.find(profile => profile.id === profileId);
  if (!profileInfo) {
    return null;
  }

  const { archivedProxy = null } = profileInfo;
  if (archivedProxy) {
    return null;
  }

  const prevProfileProxyId = profileInfo.proxy?.id || '';
  const isSameProxy = prevProfileProxyId === (proxy.id || '');
  if (isSameProxy) {
    return null;
  }

  return profileInfo;
};

const updateLinkedProxyInProxyList = (proxy: IProxy, prevProfileProxyId: string): void => {
  if (proxy.id) {
    updateProxySelectionDate(proxy);
    incrementProxyProfilesCount(proxy?.id);
  }

  if (prevProfileProxyId) {
    decrementProxyProfilesCount(prevProfileProxyId);
  }
};

const updateLinkedProxyInProfilesList = (profileId: string, proxy: IProxy, isTorOrFreeProxy: boolean): void => {
  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    if (profile.id !== profileId) {
      return profile;
    }

    const { archivedProxy } = profile;
    if (archivedProxy) {
      delete profile.archivedProxy;
    }

    let profilesCount = proxy.profilesCount || 0;
    if (isTorOrFreeProxy) {
      profilesCount = 0;
    }

    return {
      ...profile,
      proxy: {
        id: proxy.id,
        customName: proxy.customName,
        mode: proxy.mode,
        host: proxy.host,
        port: proxy.port,
        username: proxy.username,
        password: proxy.password,
        country: proxy.country,
        city: proxy.city,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
        profiles: [],
        profilesCount,
      },
      proxyEnabled: proxy.mode !== 'none',
    };
  }));
};

export const linkProfileProxyInState = (profileId: string, proxy: IProxy, profilesListArg?: IProfile[]): void => {
  const profilesList = profilesListArg || getProfilesList();
  const profileToLink = getProxyProfileToLink(profileId, proxy, profilesList);
  if (!profileToLink) {
    return;
  }

  const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxy);
  if (!isTorOrFreeProxy) {
    updateLinkedProxyInProxyList(proxy, profileToLink.proxy?.id || '');
  }

  updateLinkedProxyInProfilesList(profileId, proxy, isTorOrFreeProxy);
};

const unlinkProfileProxyInBackend = async (profileId: string, proxy: IProxy, profilesListArg?: IProfile[]): Promise<void> => {
  const profilesList = profilesListArg || getProfilesList();
  const updateProxyResponse = await updateProfileProxyWithErrorHandling({ profileId, proxy: { ...proxy } })
    .catch(() => setProfilesList(profilesList));

  if (updateProxyResponse?.error) {
    return handleUpdateProxiesRequestError(updateProxyResponse);
  }

  sendActionAnalytics('chose proxy via proxy manager');
};

export const linkProfileProxy = async (profileId: string, proxy: IProxy): Promise<void> => {
  const profilesList = getProfilesList();
  const profileToLink = getProxyProfileToLink(profileId, proxy, profilesList);
  if (!profileToLink) {
    return;
  }

  const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxy);
  if (!isTorOrFreeProxy) {
    updateLinkedProxyInProxyList(proxy, profileToLink.proxy?.id || '');
  }

  updateLinkedProxyInProfilesList(profileId, proxy, isTorOrFreeProxy);
  await unlinkProfileProxyInBackend(profileId, proxy, profilesList);
};

export const unlinkProfileProxyInState = (profileId: string): void => {
  const { id: _unusedId, ...emptyProxy } = EMPTY_PROXY;
  linkProfileProxyInState(profileId, emptyProxy);
};

export const unlinkProfileProxy = async (profileId: string): Promise<void> => {
  const { id: _unusedId, ...emptyProxy } = EMPTY_PROXY;
  await linkProfileProxy(profileId, emptyProxy);
};
