import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { textFont } from '../../../../ui/style-templates';

export const SelectedProxyWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
`;

export const ProxySavedSelectorPlaceholder = styled.span<{ isProxyManagerWithGroups: boolean }>`
  flex-grow: 1;
  display: flex;

  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? null : css`
    margin-left: 8px;
  `}
  
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const ProxySavedSelectorProxy = styled.div`
  width: 100%;
  height: 21px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectedProxyTitleWrapper = styled.span`
  ${textFont()}
  height: 13.5px; // this fixes a jumping pixel bug
  line-height: 1;
`;

export const ProxySavedSelectorUnselectButton = styled.div`
  cursor: pointer;
`;
