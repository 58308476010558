import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GeoProxyTypeIcon from './geoproxy-type-icon';
import { GeoProxyIconWrapper, GroupedProxyItemWrapper, GroupedProxyTitle, ProxyEditButtonWrapper } from './styles';
import { IGroupedProxy } from '../../../../../state/proxy/proxy-groups/interfaces';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import CheckProxyBtn from '../../../check-proxy-button';
import ProxyEditButton from '../../../check-proxy-tooltip/proxy-info/proxy-edit-button';
import { getProxyTitle } from '../../../utils/proxy-title';
import { ProfilesCounterWrapper } from '../styles/proxy-list-item';

interface IGroupedProxyItem {
  groupedProxy: IGroupedProxy;
  currentProfileId: string|null;
  handleRowClick: React.MouseEventHandler<HTMLDivElement>;
  handleContextMenu: React.MouseEventHandler<HTMLDivElement>;
  style: React.CSSProperties;
  selectorContainerElement?: React.RefObject<HTMLDivElement>; // TODO: needed?
}

const GroupedProxyItem: React.FC<IGroupedProxyItem> = (props) => {
  const { groupedProxy, currentProfileId, handleRowClick, handleContextMenu, selectorContainerElement, style } = props;

  const { t: translation } = useTranslation();

  const title = getProxyTitle(groupedProxy, translation);

  // TODO: make a component?
  const renderRightControls = (): JSX.Element|null => {
    const profilesCount = groupedProxy.profilesCount || 0;

    const handleProxyInfoEditClick = (): void => {
      openProxyManager({
        modalView: 'proxy-edit',
        currentProxy: groupedProxy,
        currentProfileId,
        modalEditingProxyId: groupedProxy.id,
        // proxySelectorLocation: '' // TODO: which location?
      });
    };

    return (
      <ProxyEditButtonWrapper onClick={(event): void => {
        event.preventDefault();
        event.stopPropagation();
      }}>
        <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} />
        <ProfilesCounterWrapper className='proxy-profiles-counter-wrapper'>
          {profilesCount}
        </ProfilesCounterWrapper>
      </ProxyEditButtonWrapper>
    );
  };

  return (
    <GroupedProxyItemWrapper
      // TODO: needed?
      style={{
        ...style,
        paddingLeft: 36,
        height: 36,
        margin: 4,
      }}
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
    >
      <CheckProxyBtn
        proxy={groupedProxy}
        profileId={currentProfileId || ''}
        proxyTooltipView='proxy-list-item'
        selectorContainerElement={selectorContainerElement}
      />
      <GeoProxyIconWrapper>
        <TooltipWrapper
          isTransition={true}
          value={<Trans i18nKey={`proxies.connectionTypeTooltip.${groupedProxy.connectionType}`} />}
          placement='top'
          style={{ marginBottom: 8 }}
        >
          <GeoProxyTypeIcon id={groupedProxy.id} connectionType={groupedProxy.connectionType} />
        </TooltipWrapper>
      </GeoProxyIconWrapper>
      <GroupedProxyTitle className='nowrap'>
        {title}
      </GroupedProxyTitle>
      {renderRightControls()}
    </GroupedProxyItemWrapper>
  );
};

export default GroupedProxyItem;
