import styled from '@emotion/styled';

export const SavedProxySearchWrapper = styled.div`
  width: 100%;
  height: 21px;
  display: flex;
  align-items: center;
`;

export const StatusDotWrapper = styled.div`
  width: 16px;
  margin-right: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ProxyFlagWrapper = styled.div`
  width: 14px;
  height: 10x;
  margin-right: 9px;
`;
