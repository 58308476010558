import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProxySavedSelectorPlaceholder, ProxySavedSelectorProxy, ProxySavedSelectorUnselectButton, SelectedProxyWrapper } from './styles';
import { onProfileSettingsProxySelect, useProfileSettingsProxyId } from '../../../../state/proxy/proxy-in-profile-settings.atom';
import { getProxyByArtificialProxyId, useProxyListProxyById } from '../../../../state/proxy/proxy-list.atom';
import { ProxySelectorLocation } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconClose } from '../../../../ui/gologin-header/icons';
import CheckProxyBtn from '../../check-proxy-button';
import ProxyFlag from '../../components/proxy-flag';
import { getProxyTitle } from '../../utils/proxy-title';
import { iconProps } from '../constants';
import { ProxyFlagWrapper, StatusDotWrapper } from '../saved-proxy-search/styles';

interface ISelectedProxy {
  editingProfileId: string|null;
  proxyManagerTargetRef: React.RefObject<HTMLButtonElement>;
}

const SelectedProxy: React.FC<ISelectedProxy> = (props) => {
  const { editingProfileId, proxyManagerTargetRef } = props;

  const profileSettingsProxyId = useProfileSettingsProxyId();
  let proxyToRender = useProxyListProxyById(profileSettingsProxyId);

  const { t: translation } = useTranslation();

  if (!proxyToRender && profileSettingsProxyId) {
    proxyToRender = getProxyByArtificialProxyId(profileSettingsProxyId);
  }

  if (!(profileSettingsProxyId && proxyToRender)) {
    return (
      <ProxySavedSelectorPlaceholder>
        <Trans i18nKey='profileSettingsPage.proxyTab.savedProxyPlaceholder' />
      </ProxySavedSelectorPlaceholder>
    );
  }

  const title = getProxyTitle(proxyToRender, translation);

  const handleClickUnselect: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onProfileSettingsProxySelect(null);
  };

  return (
    <ProxySavedSelectorProxy>
      <SelectedProxyWrapper>
        <StatusDotWrapper>
          <CheckProxyBtn
            proxy={proxyToRender}
            profileId={editingProfileId || ''}
            proxyTooltipView={ProxySelectorLocation.profileSettingsPage}
            selectorContainerElement={proxyManagerTargetRef}
          />
        </StatusDotWrapper>
        <ProxyFlagWrapper>
          <ProxyFlag region={proxyToRender.country} />
        </ProxyFlagWrapper>
        <span style={{ height: '13.5px', fontSize: '14px', lineHeight: 1, letterSpacing: '0.25px', fontFamily: 'Roboto' }}>
          {title}
        </span>
      </SelectedProxyWrapper>
      <ProxySavedSelectorUnselectButton onClick={handleClickUnselect}>
        <IconClose {...iconProps} />
      </ProxySavedSelectorUnselectButton>
    </ProxySavedSelectorProxy>
  );
};

export default SelectedProxy;
