import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const showCheckbox = (): SerializedStyles => css`
  .MuiCheckbox-root {
    opacity: 1;
  }
`;

export const CheckboxWrapper = styled('div')<{ checked?: boolean }>`
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border: 1px solid var(--D9D9D9-antd);
`;

export const IndeterminateIcon = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--CDCDCD-profile-table);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
`;

export const CheckedIcon = styled('span')`
  &::before {
    content: '';
    position: absolute;
    border-right: 2px solid;
    border-bottom: 2px solid;
    width: 5px;
    height: 7px;
    transform: rotate(45deg);
    top: 2px;
    left: 4px;
  }
`;

export const CheckboxContainer = styled('div')<{
  hasPadding?: boolean;
  checked?: boolean;
  isShownOnlyOnHover?: boolean;
  isFilledOnSelection?: boolean;
  isDisabled?: boolean;
}>`
  ${(props): SerializedStyles | '' => props.hasPadding ? css`
    padding: 16px 6px 15px 12px;
  ` : ''}

  ${CheckboxWrapper} {
    ${({ checked, isFilledOnSelection }): SerializedStyles|null => isFilledOnSelection ? css`
      background-color: inherit;
      border-color: ${checked ? 'var(--00A987-profile-table)' : 'var(--CDCDCD-profile-table)'};
    ` : css`
      background-color: ${checked ? 'var(--00B86D-antd)' : 'inherit'};
      border-color: ${checked ? 'var(--00B86D-antd)' : 'var(--CDCDCD-profile-table)'};
    `}
  }

  ${CheckedIcon} {
    &::before {
      border-color: ${({ isFilledOnSelection }): string => isFilledOnSelection ? 'var(--00A987-profile-table)' : 'var(--FFFFFF-antd)'};
    }
  }

  :hover {
    ${showCheckbox()}
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: transparent;
    border: 0;
    opacity: 1;
    background-color: inherit;
    border-color: var(--00A987-profile-table);
  }

  .MuiCheckbox-root {
    opacity: ${({ isShownOnlyOnHover }): number => isShownOnlyOnHover ? 0 : 1};
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
    color: transparent;
    padding: 0;
  }

  .MuiCheckbox-indeterminate {
    opacity: 1;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }

  ${({ isDisabled }): SerializedStyles|null => isDisabled ? css`
    cursor: not-allowed;

    .PrivateSwitchBase-input-6 {
      cursor: not-allowed;
    }

    ${CheckboxWrapper} {
      cursor: not-allowed;
      background-color: var(--F5F5F5-antd);
      border-color: var(--D9D9D9-antd) !important;
    }

    ${CheckedIcon} {
      &::before {
        cursor: not-allowed;
        border-color: rgba(0, 0, 0, 0.25);
      }
    }
  ` : null}
`;

export const gologinTableCheckboxStyles = css`
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--00A987-profile-table);
  }

  .ant-checkbox-wrapper .ant-checkbox-wrapper-checked {
    :hover .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }

    :focus .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }
  }

  .ant-checkbox-wrapper {
    :hover .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }

    :focus .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }
  }

  .ant-checkbox {
    opacity: 0;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-table-row:hover .ant-checkbox,
  .ant-table-selection-column:hover .ant-checkbox {
    opacity: 1;
  }

  .ant-checkbox-indeterminate {
    opacity: 1;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);

    .ant-checkbox-inner::after {
      background-color: var(--CDCDCD-profile-table);
    }
  }

  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--00A987-profile-table);
  }

  .ant-checkbox-wrapper:focus .ant-checkbox-inner {
    border-color: var(--00A987-profile-table);
  }

  .ant-checkbox-checked {
    opacity: 1;
    background-color: inherit;
    border-color: var(--00A987-profile-table);

    .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }

    .ant-checkbox-inner::after {
      border: 2px solid var(--00A987-profile-table);
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-wrapper {
      :hover .ant-checkbox-inner {
        border-color: var(--00A987-profile-table);
      }

      :focus .ant-checkbox-inner {
        border-color: var(--00A987-profile-table);
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }
  }

  .ant-checkbox {
    padding: 0;
  }

  .ant-checkbox {
    .ant-table-thead > tr > th {
      border-bottom: 0 solid var(--E8E8E7-profile-table);
    }
  }
`;

export const SimpleCheckboxContainer = styled('div')<{
  isDisabled?: boolean;
  isHidden?: boolean;
  isFillingSpace?: boolean;
  innerPadding?: string;
}>`
  ${({ isFillingSpace }): SerializedStyles | null => isFillingSpace ? css`
    width: 100%;
    height: 100%;
  ` : null}

  .MuiCheckbox-root {
    opacity: ${({ isHidden }): number => isHidden ? 0 : 1};
    padding: ${({ innerPadding }): string => innerPadding || '0'};

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiIconButton-colorSecondary:hover,
  .MuiIconButton-colorSecondary.Mui-checked:hover {
    background-color: transparent;
  }

  ${({ isDisabled }): SerializedStyles | null => isDisabled ? css`
    cursor: not-allowed;
  ` : null}
`;

