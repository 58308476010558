import { API_BASE_URL } from '../../../../common/constants/constants';
import { IGeolocationProxyFullData, IGeolocationProxyPrices } from '../../../interfaces';
import { http } from '../../../services';
import { fetchWithTimeout } from '../../../services/http/client';

const TRAFFIC_DATA_REQUEST_TIMEOUT = 15 * 1000;

// eslint-disable-next-line no-shadow
export enum TrafficDataErrors {
  timeout = 'timeout',
  other = 'error',
}

export const getTrafficData = async (): Promise<IGeolocationProxyFullData | TrafficDataErrors> =>
  fetchWithTimeout<IGeolocationProxyFullData>(`${API_BASE_URL}/users-proxies/geolocation/traffic`, {
    method: 'GET',
    timeout: TRAFFIC_DATA_REQUEST_TIMEOUT,
  }).catch((error: unknown) => {
    const isError = error instanceof Error;
    if (isError && error.name === 'AbortError') {
      return TrafficDataErrors.timeout;
    }

    return TrafficDataErrors.other;
  });

interface IGetPaymentURL {
  dataGbCount: number;
  dataType: keyof IGeolocationProxyPrices;
  path: string;
}

export const getPaymentURL = (params: IGetPaymentURL): Promise<{ checkoutUrl: string }> =>
  http(`${API_BASE_URL}/billing/proxies/checkout_session_data`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then((res: any) => res.body);
