import styled from '@emotion/styled';

export const SelectedProxyWrapper = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
`;

export const ProxySavedSelectorPlaceholder = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const ProxySavedSelectorProxy = styled.div`
  width: 100%;
  height: 21px;
  margin-right: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProxySavedSelectorUnselectButton = styled.div`
  cursor: pointer;
`;
