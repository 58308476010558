import React from 'react';
import { Trans } from 'react-i18next';

import { GroupedProxyManagerProxyStatusWrapper, NoGroupsProxyManagerProxyStatusWrapper, ProxyStatusDescription } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IconCheck } from '../../../../ui/gologin-header/icons';
import IconCheckCircle from '../../../../ui/icons/IconCheckCircle';

const ProxyConnectedStatus: React.FC = () => {
  if (!NEW_FEATURES.proxyGroups) {
    return (
      <NoGroupsProxyManagerProxyStatusWrapper>
        <IconCheckCircle />
      </NoGroupsProxyManagerProxyStatusWrapper>
    );
  }

  return (
    <GroupedProxyManagerProxyStatusWrapper>
      <IconCheck
        padding={0}
        margin='0 6px 0 0'
        iconColor='var(--98989F-header)'
      />
      <ProxyStatusDescription>
        <Trans i18nKey='proxies.connected' />
      </ProxyStatusDescription>
    </GroupedProxyManagerProxyStatusWrapper>
  );
};

export default ProxyConnectedStatus;
