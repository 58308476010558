import { PROXY_GROUP_ADD_BUTTON_ID_POSTFIX, PROXY_GROUP_HEADER_ID_POSTFIX, PROXY_MANAGER_LIST_SECTION_ID_POSTFIX } from './constants';
import { GeoProxyType } from '../../../../common/constants/types';
import { IProxy, ProxyMode } from '../../../interfaces';

export const PROXY_SECTION_TITLES = <const>{
  availableProxies: 'availableProxies',
  unavailableProxies: 'unavailableProxies',
};

export type ProxySectionTitle = keyof typeof PROXY_SECTION_TITLES;

export type ProxySection = {
  id: string;
  title: ProxySectionTitle;
  isContextMenuEnabled: boolean;
}

export type ProxySectionsObject = Record<ProxySectionTitle, ProxySection>;

export interface IProxyManagerListEntityBase {
  id: string;
  groupId: string;
  mode: ProxyMode;
  country: string;
  selectionDate: number;
}

export interface IProxyGroupHeader extends IProxyManagerListEntityBase {
  types: GeoProxyType[];
  isAvailable: boolean;
  createdAt: number;
}

export interface IProxyGroupAddButton extends IProxyManagerListEntityBase {
  types: GeoProxyType[];
  isAvailable: boolean;
}

type IProxyWithoutIdAndCountry = Omit<IProxy, 'id'|'country'>;
export interface IGroupedProxy extends IProxyManagerListEntityBase, IProxyWithoutIdAndCountry {}
export interface IUngroupedProxy extends Omit<IProxyManagerListEntityBase, 'groupId'>, IProxyWithoutIdAndCountry {
  groupId: null;
}

export type IProxyManagerListEntity = ProxySection | IProxyGroupHeader | IGroupedProxy | IProxyGroupAddButton | IUngroupedProxy;

export interface IProxyGroup {
  groupId: string;
  header: IProxyGroupHeader;
  proxies: IGroupedProxy[];
  addButton: IProxyGroupAddButton;
  isAvailable: boolean;
}

export const PROXY_GROUP_MODES = <const>{
  geolocationProxies: 'geolocationProxies',
  gologinProxies: 'gologinProxies',
  torProxies: 'torProxies',
  userProxies: 'userProxies',
};

export const PROXY_GROUP_MODES_LIST: ProxyGroupMode[] = Object.values(PROXY_GROUP_MODES);

export const PROXY_GOLOGIN_GROUP_MODES_LIST = [
  PROXY_GROUP_MODES.geolocationProxies,
  PROXY_GROUP_MODES.torProxies,
  PROXY_GROUP_MODES.gologinProxies,
];

export type GroupedProxyGroupMode = 'geolocationProxies';
export type UngroupedProxyGroupMode = 'gologinProxies'|'torProxies'|'userProxies';
export type ProxyGroupMode = GroupedProxyGroupMode|UngroupedProxyGroupMode;

export type IProxyGroupsObject = Record<UngroupedProxyGroupMode, IUngroupedProxy[]> &
  Record<GroupedProxyGroupMode, Record<string, IProxyGroup>> &
  Record<typeof PROXY_SECTION_TITLES['unavailableProxies'], Record<string, IProxyGroup>>;

export const getIsProxyManagerListSection = (proxyEntity: IProxyManagerListEntity): proxyEntity is ProxySection =>
  proxyEntity.id?.endsWith(PROXY_MANAGER_LIST_SECTION_ID_POSTFIX);

export const getIsProxyGroup = (proxyEntity: IProxyGroup|IProxyManagerListEntity): proxyEntity is IProxyGroup => !!proxyEntity.groupId;

export const getIsProxyGroupHeader = (proxyEntity: IProxyManagerListEntity): proxyEntity is IProxyGroupHeader =>
  proxyEntity.id?.endsWith(PROXY_GROUP_HEADER_ID_POSTFIX);

export const getIsProxyGroupAddButton = (proxyEntity: IProxyManagerListEntity): proxyEntity is IProxyGroupAddButton =>
  proxyEntity.id?.endsWith(PROXY_GROUP_ADD_BUTTON_ID_POSTFIX);
