import { Popper, PopperProps } from '@material-ui/core';
import React, { memo, useCallback, useEffect } from 'react';

import ProxyManagerForm from './proxy-edit-view/proxy-manage-form';
import ProxyList, { IProxyList } from './proxy-list';
import { useProfilesList } from '../../../state/profiles-list.atom';
import { closeProxyManager, ProxySelectorLocation, useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import RemovingConfirm from '../components/removing-confirm';
import { EMPTY_PROXY } from '../proxy-helpers';
import ProxyPopoverWrapper from '../proxy-popover-wrapper';

const ProxyManager: React.FC = () => {
  const profilesList = useProfilesList();
  const {
    modalView,
    currentProfileId,
    currentProxy: currentProxyInManager,
    containerElement,
    proxySelectorLocation,
  } = useProxyManagerState();

  const handleGlobalKeyDown = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeProxyManager();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown, true);
    };
  }, []);

  const currentProfile = profilesList.find(profile => profile.id === currentProfileId);
  const currentProxy = currentProxyInManager || currentProfile?.proxy || EMPTY_PROXY;

  const renderContent = (): JSX.Element => {
    const props: IProxyList = { currentProxy, localProxyElementLocation: 'no-location' };
    switch (true) {
      case modalView === 'proxy-list':
        return <ProxyList {...props} />;
      default:
        return <ProxyManagerForm />;
    }
  };

  const popperProps: Omit<PopperProps, 'children'> = {
    open: !!modalView,
    style: { zIndex: 1060, margin: '0 24px 0 -24px' },
    disablePortal: false,
    anchorEl: containerElement,
    placement: 'bottom-start',
    modifiers: {
      preventOverflow: { enabled: true, boundariesElement: 'window' },
      offset: { enabled: true },
    },
  };

  if (proxySelectorLocation === ProxySelectorLocation.profileSettingsPage) {
    popperProps.style = {
      ...popperProps.style,
      margin: '10px 0 10px 0',
    };
  }

  return (
    <Popper {...popperProps}>
      <ProxyPopoverWrapper>
        {renderContent()}
        <RemovingConfirm />
      </ProxyPopoverWrapper>
    </Popper>
  );
};

export default memo(ProxyManager);
