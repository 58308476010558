import { DEPRECATED_GEOPROXY_TYPES, GEOPROXY_TYPES } from './constants';

// `resident` is correct
export type GeoProxyType = typeof GEOPROXY_TYPES[number];
export type DeprecatedGeoProxy = typeof DEPRECATED_GEOPROXY_TYPES[number];

export const getIsGeoProxyType = (value: string): value is GeoProxyType => GEOPROXY_TYPES.reduce((acc, type) => {
  if (acc || type === value) {
    return true;
  }

  return false;
}, false);
