import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyListWrapper } from './styles';
import { BtnAdd, BtnIcon, BtnText, EmptyListButtonsEl, EmptyListEl, EmptyListTitleEl } from './styles/empty-list';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { cleareProxySearch } from '../../../../state/proxy/proxy-search.atom';
import IconPlusEmptyList from '../../../../ui/icons/IconPlusEmptyList';
import { GROUPED_PROXY_MANAGER_SIZE, UNGROUPED_PROXY_MANAGER_SIZE } from '../styles';

type EmptyListProps = {
  proxySelectorLocation: string | null;
}

const EmptyList: React.FC<EmptyListProps> = (props) => {
  const { proxySelectorLocation } = props;

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    cleareProxySearch();
    openProxyManager({
      modalView: 'proxy-add',
      currentProxy: null,
      currentProfileId: null,
      proxySelectorLocation,
    });
  };

  const PROXY_MANAGER_HEIGHT = NEW_FEATURES.proxyGroups ? GROUPED_PROXY_MANAGER_SIZE.height : UNGROUPED_PROXY_MANAGER_SIZE.height;

  return (
    <ProxyListWrapper height={PROXY_MANAGER_HEIGHT} hasVerticalMargin={false}>
      <EmptyListEl>
        <EmptyListTitleEl>
          <Trans i18nKey='proxies.noProxyFound' />
        </EmptyListTitleEl>
        <EmptyListButtonsEl>
          <BtnAdd onClick={handleAddClick}>
            <BtnIcon>
              <IconPlusEmptyList />
            </BtnIcon>
            <BtnText>
              <Trans i18nKey='proxies.addProxy' />
            </BtnText>
          </BtnAdd>
        </EmptyListButtonsEl>
      </EmptyListEl>
    </ProxyListWrapper>
  );
};

export default EmptyList;
