import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setProxiesTableList } from './proxy-table/proxies-table-list.atom';
import { IProxy } from '../../features/proxy/components/interfaces/IProxy';

export const proxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadedAtom = atom<boolean>(false);

const setProxyList = (data: IProxy[]): void => getDefaultStore().set(proxyListAtom, data);
export const getProxyList = (): IProxy[] => getDefaultStore().get(proxyListAtom);
export const setIsProxyListLoaded = (value: boolean): void => getDefaultStore().set(isProxyListLoadedAtom, value);

export const useProxyList = (): IProxy[] => useAtomValue(proxyListAtom);

export const useIsProxyListLoaded = (): boolean => useAtomValue(isProxyListLoadedAtom);

export const updateProxyList = (proxies: IProxy[]): void => setProxyList(proxies);

export const removeProxiesFromList = (proxyIdsToDelete: string[]): void => {
  const proxyList = getProxyList();
  updateProxyList(proxyList.filter(proxy => !proxyIdsToDelete.includes(proxy.id)));
};

export const upsertOneProxy = (newProxy: IProxy): void => {
  const proxyList = getProxyList();
  let hasProxyChanged = false;
  const updatedProxies = proxyList.map((proxy) => {
    if (proxy.id !== newProxy.id) {
      return proxy;
    }

    hasProxyChanged = true;
    newProxy = {
      ...proxy,
      ...newProxy,
      profilesCount: proxy.profilesCount,
    };

    return newProxy;
  });

  if (!hasProxyChanged) {
    updatedProxies.unshift({ ...newProxy, checkInProgress: true });
  }

  updateProxyList(updatedProxies);
};

export const pushProxies = (newProxies: IProxy[]): void => {
  const proxyList = getProxyList();
  updateProxyList([...proxyList, ...newProxies]);
};

const updateProxyInList = (proxiesInList: IProxy[], proxyItem: IProxy): void => {
  const proxyInListIndex = proxiesInList.findIndex(proxy => proxy.id === proxyItem.id);
  const isInvisible = proxyItem.id && proxyInListIndex === -1 && proxyItem.isInvisible;

  if (isInvisible) {
    const proxyToAdd: IProxy = { ...proxyItem, isInvisible };
    const newProxyList = [...proxiesInList];
    newProxyList.push(proxyToAdd);

    return updateProxyList(newProxyList);
  }

  const newProxyList = [...proxiesInList];
  if (proxyInListIndex > -1) {
    newProxyList[proxyInListIndex] = { ...newProxyList[proxyInListIndex], ...proxyItem };
  } else {
    // proxy needs to be updated after check,
    // if it is not listed (for various reasons such as profile transferred with it)
    newProxyList.push({
      ...proxyItem,
      id: proxyItem.id || '',
      profilesCount: 0,
      mode: proxyItem.mode || 'http',
      host: proxyItem.host || '',
      port: proxyItem.port || 80,
      isInvisible: true,
    });
  }

  updateProxyList(newProxyList);
};

export const updateProxyItem = (proxyItem: IProxy): void => {
  const proxyList = getProxyList();
  updateProxyInList(proxyList, proxyItem);
};

export const updateProxyItems = (proxyItems: IProxy[]): void => {
  const proxyList = getProxyList();
  proxyItems.map(proxyItem => updateProxyInList(proxyList, proxyItem));
};

export const clearProxyLists = (): void => {
  updateProxyList([]);
  setProxiesTableList([]);
};

export const incrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const newProxiesList = proxyList.map((proxyItem) => {
    if (proxyItem?.id === proxyId) {
      proxyItem.profilesCount ? proxyItem.profilesCount += 1 : proxyItem.profilesCount = 1;
    }

    return proxyItem;
  });

  updateProxyList(newProxiesList);
};

export const decrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const updatedProxies = proxyList.map((proxyItem) => {
    if (proxyItem.id === proxyId && proxyItem.profilesCount) {
      proxyItem.profilesCount -= 1;
    }

    return proxyItem;
  });

  updateProxyList(updatedProxies);
};

export const useProxyListProxyById = (proxyId: string | null): IProxy | null => {
  const proxyList = useProxyList();
  if (!proxyId) {
    return null;
  }

  return proxyList.find(proxyInList => proxyInList.id === proxyId) || null;
};

export const getProxyListProxyById = (proxyId: string): IProxy | null => {
  const proxyList = getProxyList();

  return proxyList.find(proxyInList => proxyInList.id === proxyId) || null;
};

export const updateProxyInListById = (
  proxyId: string,
  partialUpdate: Partial<IProxy>,
): void => {
  const proxyList = getProxyList();
  const newProxyList = proxyList.map(proxy => {
    if (proxy.id === proxyId) {
      return { ...proxy, ...partialUpdate };
    }

    return proxy;
  });

  updateProxyList(newProxyList);
};

export const getProxyByArtificialProxyId = (artificialProxyId: string | null): IProxy | null => {
  if (!artificialProxyId) {
    return null;
  }

  const proxyList = getProxyList();
  const [mode, region] = artificialProxyId.split('-');
  const field: keyof IProxy = mode === 'gologin' ? 'autoProxyRegion' : 'torProxyRegion';

  return proxyList.find(proxy => proxy.mode === mode && proxy[field] === region) || null;
};
