/* eslint-disable id-length */
import css, { SerializedStyles } from '@emotion/css/macro';
import styled from '@emotion/styled/macro';

import { ProfilesCounterWrapper } from './proxy-profiles-counter/styles';
import { textFont } from '../../../../ui/style-templates';
import { GroupedProxyManagerProxyStatusWrapper } from '../../proxy-connection-button/proxy-connected-status/styles';
import { UnlinkButtonWrapper } from '../../proxy-connection-button/proxy-unlink-button/styles';
import { ProxyEditButtonWrapper } from '../../proxy-edit-button/styles';

export const ProxyListWrapper = styled.div<{ height: number; hasVerticalMargin: boolean }>`
  height: ${(props): number => props.height}px;
  margin: ${(props): React.CSSProperties['margin'] => props.hasVerticalMargin ? '3px 0' : 0};

  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const ProxyInteractiveProfilesCounterWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ProxyEditButtonWrapper} {
    display: none;
  }
`;

export const NoGroupsProxyRightControlsWrapper = styled.div`
  margin: 0 24px 0 8px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * + * {
    margin-left: 14px;
  }
`;

export const proxyListItemActiveStyles = css`
  background-color: var(--F2F2F2-proxy-list-item-hovered);

  ${UnlinkButtonWrapper},
  ${ProxyEditButtonWrapper} {
    display: flex;
  }

  ${ProfilesCounterWrapper},
  ${GroupedProxyManagerProxyStatusWrapper} {
    display: none;
  }
`;

export const ProxyItemBase = styled.div<{ isAlwaysOpaque: boolean; isFailed?: boolean; isHighlighted?: boolean }>`
  ${textFont()};

  cursor: pointer;

  ${(props): SerializedStyles | null => props.isAlwaysOpaque ? null : css`
    & > *:not(${NoGroupsProxyRightControlsWrapper}, ${ProxyInteractiveProfilesCounterWrapper}) {
      opacity: ${props.isFailed && !props.isHighlighted ? 0.5 : 1};
    }

    &:hover > * {
      opacity: 1;
    }
  `}
`;

export const ProxyFlagBlockEl = styled('div')<{ isFailed?: boolean; newStyle: boolean }>`
  white-space: nowrap;
  display: flex;
  align-items: center;

  ${(props): SerializedStyles => css`
    opacity: ${(props.isFailed) ? '0.5' : '1'};
  `}

  tr:hover, .gologin-table-row & {
    opacity: 1;
  }

  background-color: ${(props): string | null => props.newStyle ? 'inherit' : null};
`;
