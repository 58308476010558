import React, { memo } from 'react';
import { areEqual } from 'react-window';

import GroupedProxyItem from './grouped-proxy-item';
import ProxyGroupAddButton from './proxy-group-add-button';
import ProxyGroupHeader from './proxy-group-header';
import ProxySectionItem from './proxy-section-item';
import UngroupedProxyItem from './ungrouped-proxy-item';
import { determineIsProxyTruthy } from '../../../../../common/constants/types';
import { isNotNull } from '../../../../../common/typescript/predicates';
import { IProxy, IArchivedProxy } from '../../../../interfaces';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  getIsProxyGroupAddButton,
  getIsProxyGroupHeader,
  getIsProxyManagerListSection,
  IProxyManagerListEntity,
} from '../../../../state/proxy/proxy-groups/interfaces';
import {
  closeProxyManager,
  getProxyManagerState,
  useProxyManagerState,
} from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { linkProfileProxy } from '../../../../state/proxy/proxy-operations/link-proxy.operations';
import { toggleIsProxySelected, updateSelectedProxies, useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { openProxyContextMenu, updateProxySelectMenuPosition, useIsSelectProxyModeOpened } from '../../../../state/proxy-select-menu.atom';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../constants';
import { getIsProxyArchived, restoreProxy, selectProxiesWithShift } from '../../proxy-helpers';
import { getNoIdProxyId } from '../../utils/proxy-id';

type ProxyRowProps = {
  data: {
    proxyManagerEntities: (IProxy|IArchivedProxy|IProxyManagerListEntity|null)[];
    currentProxy?: IProxy;
    localProxyElementLocation: string | null;
  };
  index: number;
  style: React.CSSProperties;
}

const ProxyRow: React.FC<ProxyRowProps> = (props) => {
  const { data, index, style } = props;

  const { currentProfileId } = useProxyManagerState();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();

  const { proxyManagerEntities, currentProxy, localProxyElementLocation } = data;
  let proxyEntity = proxyManagerEntities[index];
  // to keep the last proxy visible right above the proxy-manager multi-select panel
  if (!proxyEntity) {
    return <div />;
  }

  if (getIsProxyArchived(currentProxy) && proxyEntity.id === currentProxy.id) {
    proxyEntity = currentProxy;
  }

  const areCheckboxesVisible = !!selectedProxies.length;
  const styleBase: React.CSSProperties = { ...style, width: 'auto', right: 0 };

  const handleRowClick: React.MouseEventHandler<Element> = async (event) => {
    let proxyToLink = proxyEntity;
    if (getIsProxyArchived(proxyEntity)) {
      proxyToLink = await restoreProxy(proxyEntity);
    }

    const { handleProxySelect } = getProxyManagerState();
    event.preventDefault();

    if (isSelectProxyModeOpened) {
      if (!event.shiftKey) {
        return toggleIsProxySelected(proxyToLink.id);
      }

      return selectProxiesWithShift({
        shiftKey: event.shiftKey,
        selectedProxies,
        proxiesToSelectFrom: proxyManagerEntities.filter(isNotNull),
        proxy: proxyToLink,
        lastSelectedProxy,
      });
    }

    if (handleProxySelect) {
      let proxyId = proxyEntity.id;
      if (!proxyId && determineIsProxyTruthy(proxyToLink)) {
        proxyId = getNoIdProxyId(proxyToLink);
      }

      handleProxySelect(proxyId);

      return;
    }

    if (currentProfileId) {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: 'select-proxy-manager-proxy' });

      linkProfileProxy(currentProfileId, proxyToLink);
      closeProxyManager();
    }
  };

  const handleContextMenu: React.MouseEventHandler<Element> = (event) => {
    event.preventDefault();
    updateSelectedProxies({ selectedProxy: proxyEntity });
    updateProxySelectMenuPosition({ x: event.pageX, y: event.pageY });
    openProxyContextMenu();
  };

  const isCurrentProxy = currentProxy ? currentProxy.id === proxyEntity.id : false;

  if (!NEW_FEATURES.proxyGroups) {
    return (
      <UngroupedProxyItem
        key={proxyEntity.id}
        ungroupedProxy={proxyEntity}
        currentProfileId={currentProfileId}
        isCurrentProxy={isCurrentProxy}
        isCheckboxVisible={areCheckboxesVisible}
        handleRowClick={handleRowClick}
        handleContextMenu={handleContextMenu}
        localProxyElementLocation={localProxyElementLocation}
        style={styleBase}
      />
    );
  }

  if (getIsProxyManagerListSection(proxyEntity)) {
    return (
      <ProxySectionItem
        id={proxyEntity.id}
        title={proxyEntity.title}
        isContextMenuEnabled={proxyEntity.isContextMenuEnabled}
        style={styleBase}
      />
    );
  }

  if (getIsProxyGroupHeader(proxyEntity)) {
    return (
      <ProxyGroupHeader
        key={proxyEntity.id}
        proxyGroupHeader={proxyEntity}
        currentProfileId={currentProfileId}
        availableTypes={proxyEntity.types}
        style={styleBase}
      />
    );
  }

  if (getIsProxyGroupAddButton(proxyEntity)) {
    return (
      <ProxyGroupAddButton
        key={proxyEntity.id}
        country={proxyEntity.country || DEFAULT_SELECTED_GEOPROXY_COUNTRY}
        groupId={proxyEntity.groupId}
        availableTypes={proxyEntity.types}
        style={styleBase}
      />
    );
  }

  if (proxyEntity.groupId) {
    return (
      <GroupedProxyItem
        key={proxyEntity.id}
        groupedProxy={proxyEntity}
        currentProfileId={currentProfileId}
        isCheckboxVisible={areCheckboxesVisible}
        handleRowClick={handleRowClick}
        handleContextMenu={handleContextMenu}
        style={styleBase}
        localProxyElementLocation={localProxyElementLocation}
      />
    );
  }

  return (
    <UngroupedProxyItem
      key={proxyEntity.id}
      ungroupedProxy={proxyEntity}
      currentProfileId={currentProfileId}
      isCurrentProxy={isCurrentProxy}
      isCheckboxVisible={areCheckboxesVisible}
      handleRowClick={handleRowClick}
      handleContextMenu={handleContextMenu}
      localProxyElementLocation={localProxyElementLocation}
      style={styleBase}
    />
  );
};

export default memo(ProxyRow, areEqual);
