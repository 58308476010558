import { message } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FolderRow,
  SearchFolderInput,
  ContainerIcon,
  FolderNameText,
  FoundText, FolderContainer,
} from './styles';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import { requestWorkspace } from '../../../features/common/api';
import {
  addFolder,
  createWorkspaceFolder,
  removeFolder,
  removeWorkspaceFolder,
  renameFolder,
  renameWorkspaceFolder,
} from '../../../features/folders/api';
import { searchBold } from '../../../features/newProfile/components/folders-select/search';
import { IFolder } from '../../../interfaces';
import { userContext, workspaceContext } from '../../../state';
import CreateFolderForm from '../create-folder-form';
import FolderSubmenu from '../folder-submenu';
import { IconSearch, IconMeatballs, IconPlus } from '../icons';
import { AddFolderContainer, Text } from '../styles';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverItemRow, GologinPopoverItemRowStatic } from '../../gologin-popover/gologin-popover-item-row';

interface IFoldersMenu {
  anchorElFolders: HTMLElement | null;
  setAnchorElFolders: (state: null) => void;
  onClick: (folder: IFolder) => void;
  setFolderToShare: (folder: IFolder | null) => void;
  setShareFolderVisible: (state: boolean) => void;
}

const FoldersMenu: FC<IFoldersMenu> = ({ anchorElFolders, setAnchorElFolders, onClick, setFolderToShare, setShareFolderVisible }) => {
  const [anchorElFolderSubmenu, setAnchorElFolderSubmenu] = React.useState<HTMLElement | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [addFolderInput, setAddFolderInput] = useState<string>('');
  const [hoveredFolder, setHoveredFolder] = useState<IFolder|null>(null);

  const { folders, updateFolders, updateSelectedFolder, selectedFolder } = useContext(userContext);
  const { id: workspaceId, updateWorkspace } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const refreshWorkspace = async (): Promise<void> => {
    const workspaceData = await requestWorkspace(workspaceId);
    updateWorkspace({ ...workspaceData });
  };

  const doRemoveFolder = (folder: IFolder, oldFolders: IFolder[] = [], oldSelectedFolder = ''): void => {
    setAnchorElFolderSubmenu(null);
    clear();

    let cachedFolders = folders.filter(f => f.id !== folder.id);

    if (oldFolders.length) {
      cachedFolders = oldFolders;
      localStorage.setItem('SelectedFolder', oldSelectedFolder);
      updateSelectedFolder(oldSelectedFolder);
    }

    if (!cachedFolders.length) {
      setAnchorElFolders(null);
    }

    localStorage.setItem('folders-order', JSON.stringify(cachedFolders));
    updateFolders(cachedFolders);

    if (selectedFolder === folder.name && !oldFolders.length) {
      localStorage.setItem('SelectedFolder', '');
      updateSelectedFolder('');
    }
  };

  const onRemoveFolder = async (): Promise<void> => {
    if (!hoveredFolder) {
      return;
    }

    const targetFolder = folders.find(folder => folder.id === hoveredFolder.id);

    if (!targetFolder) {
      return;
    }

    const oldFolderList = [...folders];
    const oldSelectedFolder = selectedFolder;

    doRemoveFolder(targetFolder);

    try {
      if (NEW_FEATURES.workspaces) {
        await removeWorkspaceFolder(workspaceId, hoveredFolder.name);
        refreshWorkspace();
      } else {
        await removeFolder(hoveredFolder.name);
      }
    } catch (err) {
      doRemoveFolder(targetFolder, oldFolderList, oldSelectedFolder);
    }
  };

  const doRenameFolder = (folder: IFolder, newName: string): void => {
    setAnchorElFolderSubmenu(null);

    const newFolderArray = folders.map(f => ({
      ...f,
      name: f.id === folder.id ? newName : f.name,
    }));

    localStorage.setItem('folders-order', JSON.stringify(newFolderArray));

    if (selectedFolder === folder.name) {
      localStorage.setItem('SelectedFolder', newName);
      updateSelectedFolder(newName);
    }

    updateFolders(newFolderArray);
    clear();
  };

  const onRenameFolder = async (folderName: string): Promise<void> => {
    const newFolderName = folderName.replace(/\s+/g, ' ');
    if (!hoveredFolder) {
      return;
    }

    if (!(newFolderName.trim() || '')) {
      message.error(translation('notifications.error.emptyFolder'));

      return;
    }

    if (newFolderName === hoveredFolder.name) {
      clear();

      return;
    }

    const existingFolder = folders.find(folder => folder.name === newFolderName);
    if (existingFolder) {
      message.error(`Folder "${newFolderName}" already exists`);

      return;
    }

    const targetFolder = folders.find(folder => folder.id === hoveredFolder.id);

    if (!targetFolder) {
      return;
    }

    doRenameFolder(targetFolder, newFolderName);

    try {
      if (NEW_FEATURES.workspaces) {
        await renameWorkspaceFolder(workspaceId, targetFolder.name, newFolderName);
        refreshWorkspace();
      } else {
        await renameFolder(targetFolder.name, newFolderName);
      }

      message.success(translation('notifications.success.renameFolder'));
    } catch (err) {
      doRenameFolder(targetFolder, targetFolder.name);
    }
  };

  const createNewFolder = async (folderName: string): Promise<void> => {
    const inputFolderName = folderName.replace(/\s+/g, ' ').trim();

    if (!inputFolderName) {
      message.error(translation('notifications.error.emptyFolder'));

      return;
    }

    const existingFolder = folders.find(folder => folder.name === inputFolderName);
    if (existingFolder) {
      message.error(`Folder "${inputFolderName}" already exists`);

      return;
    }

    let createdFolder;
    if (NEW_FEATURES.workspaces) {
      createdFolder = await createWorkspaceFolder(workspaceId, inputFolderName);
      refreshWorkspace();
    } else {
      createdFolder = await addFolder(inputFolderName, []);
    }

    onClose();

    const maxFolderOrder = folders.reduce<number>((acc, folder) => {
      const folderOrder = folder.order ?? 0;
      if (acc < folderOrder) {
        return folderOrder;
      }

      return acc;
    }, 0);

    const newFolderOrder = maxFolderOrder + 1;
    const newFoldersList = [...folders, { ...createdFolder, order: newFolderOrder }];
    localStorage.setItem('folders-order', JSON.stringify(newFoldersList));
    updateFolders(newFoldersList);
    onClick(createdFolder);
  };

  const onKeyPressInput = (e: React.KeyboardEvent, folderName: string): void => {
    if (e.key !== 'Enter') {
      return;
    }

    const existingFolder = filteredFolders.find(folder => folder.name === folderName);

    if (existingFolder) {
      onClick(existingFolder);
      onClose();

      return;
    }

    e.preventDefault();
    createNewFolder(folderName);
  };

  const onClickMeatballs = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setAnchorElFolderSubmenu(event.currentTarget);
  };

  const clear = (): void => {
    setHoveredFolder(null);
    setFolderToShare(null);
    setAddFolderInput('');
    setSearchInput('');
    setAnchorElFolderSubmenu(null);
  };

  const onClose = (): void => {
    setAnchorElFolders(null);
    clear();
  };

  const openModalShareFolder = (): void => {
    setShareFolderVisible(true);
    setHoveredFolder(null);
    setAddFolderInput('');
    setSearchInput('');
    setAnchorElFolders(null);
    setAnchorElFolderSubmenu(null);
  };

  const filteredFolders = !searchInput ? folders : folders.filter(f => f.name.toLowerCase()?.includes(searchInput.toLowerCase()));

  return (
    <GologinPopover
      anchorEl={anchorElFolders}
      onClose={onClose}
    >
      <GologinPopoverItemRowStatic style={{ display: 'flex' }}>
        <IconSearch styleType={'lightGray'} padding={0} margin={'0 8px 0 0'} iconColor='var(--B5B5BA-header-folders-menu)' />
        <SearchFolderInput
          value={searchInput}
          placeholder={translation('folders.menu.searchFolders')}
          onChange={(e): void => setSearchInput(e.target.value)}
          onKeyPress={(e): void => onKeyPressInput(e, searchInput)}
          autoFocus={true}
        />
      </GologinPopoverItemRowStatic>
      <FolderContainer>
        {filteredFolders.map(item => (
          <FolderRow
            key={item.name}
            onClick={(): void => {
              onClick(item);
              onClose();
            }}
            isSelected={!!(item.name === selectedFolder || (anchorElFolderSubmenu && hoveredFolder?.id === item.id))}
            onMouseOver={(): void => {
              setHoveredFolder(item);
              setFolderToShare(item);
            }}
            onMouseLeave={(): false | void => !anchorElFolderSubmenu && setHoveredFolder(null)}
          >
            <FolderNameText>
              {searchInput ? (
                <FoundText>
                  {searchBold(searchInput, item.name)}
                </FoundText>
              ) : item.name}
            </FolderNameText>
            {hoveredFolder?.id === item.id ? (
              <div style={{ display: 'flex' }}>
                <ContainerIcon onClick={onClickMeatballs}>
                  <IconMeatballs
                    padding={0}
                    styleType={anchorElFolderSubmenu ? 'darkGray' : 'activeGray'}
                    iconColor={anchorElFolderSubmenu ? 'var(--2B2B31-header-folders-menu)' : 'var(--98989F-header-folders-menu)'}
                    iconHoveredColor='var(--2B2B31-header-folders-menu)'
                  />
                </ContainerIcon>
              </div>
            ) : null}
          </FolderRow>
        ))}
        {searchInput && !folders.find(f => f.name === searchInput) ? (
          <GologinPopoverItemRowStatic
            style={{ display: 'block' }}
            onClick={(): Promise<void> => createNewFolder(searchInput)}
          >
            <AddFolderContainer
              colorIconType='activeGray'
              iconColor='var(--98989F-header-folders-menu)'
              iconHoveredColor='var(--2B2B31-header-folders-menu)'
              iconType='stroke'
              textColor='var(--767676-header-folders-menu)'
              textHoveredColor='var(--2B2B31-header-folders-menu)'
            >
              <IconPlus
                padding={0}
                margin={'0 10px 0 0'}
              />
              <Text>
                {translation('folders.menu.add')}
                {' '}
                {searchInput}
              </Text>
            </AddFolderContainer>
          </GologinPopoverItemRowStatic>
        ) : null}
      </FolderContainer>

      {!searchInput ? (
        <GologinPopoverItemRowStatic style={{ display: 'block' }}>
          <CreateFolderForm folderName={addFolderInput} setFolderName={setAddFolderInput} onKeyPress={onKeyPressInput} />
        </GologinPopoverItemRowStatic>
      ) : null}

      {hoveredFolder && folders.length ? (
        <FolderSubmenu
          anchorEl={anchorElFolderSubmenu}
          setAnchorEl={setAnchorElFolderSubmenu}
          folder={hoveredFolder}
          setFolder={setHoveredFolder}
          onRemoveFolder={onRemoveFolder}
          onRenameFolder={onRenameFolder}
          openModalShareFolder={openModalShareFolder}
        />
      ) : null}

    </GologinPopover>
  );
};

export default FoldersMenu;
