import React, { memo } from 'react';
import { areEqual } from 'react-window';

import GroupedProxyItem from './grouped-proxy-item';
import ProxyGroupAddButton from './proxy-group-add-button';
import ProxyGroupHeader from './proxy-group-header';
import UngroupedProxyItem from './ungrouped-proxy-item';
import { isNotNull } from '../../../../../common/typescript/predicates';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { getIsProxyGroupAddButton, getIsProxyGroupHeader, IProxyManagerListEntity } from '../../../../state/proxy/proxy-groups/interfaces';
import { useProxyManagerListEntities } from '../../../../state/proxy/proxy-groups/proxy-groups.atom';
import {
  closeProxyManager,
  getProxyManagerState,
  useProxyManagerState,
} from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { selectProfileProxy } from '../../../../state/proxy/proxy-operations/select-proxies.operations';
import { toggleIsProxySelected, updateSelectedProxies, useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { openProxyContextMenu, updateProxySelectMenuPosition, useIsSelectProxyModeOpened } from '../../../../state/proxy-select-menu.atom';
import { IProfileProxy } from '../../components/interfaces/IProfileProxy';
import { IProxy } from '../../components/interfaces/IProxy';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../constants';
import { EMPTY_PROXY, selectProxiesWithShift } from '../../proxy-helpers';
import { getNoIdProxyId } from '../../utils/proxy-id';

interface IProxyRow {
  data: {
    // TODO: add feature-toggle for this `IProxy|IProxyManagerListEntity`
    proxyManagerEntities: (IProxy|IProxyManagerListEntity|null)[];
    currentProxy?: IProfileProxy;
    localProxyElementLocation: string|null;
  };
  index: number;
  style: React.CSSProperties;
}

const ProxyRow: React.FC<IProxyRow> = (props) => {
  const { data, index, style } = props;
  const { proxyManagerEntities, currentProxy, localProxyElementLocation } = data;

  const proxyManagerListEntities = useProxyManagerListEntities();
  const { currentProfileId = '' } = useProxyManagerState();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();

  const proxyEntity = proxyManagerEntities[index];
  const styleBase: React.CSSProperties = { ...style, width: 'auto', right: 0 };

  // to keep the last proxy visible right above the proxy-manager multi-select panel
  if (!proxyEntity) {
    return <div />;
  }

  let proxyItem: IProxy = { ...EMPTY_PROXY, ...proxyEntity };
  if (currentProxy?.country === proxyEntity.country && currentProxy?.mode === proxyEntity.mode) {
    proxyItem = { ...proxyEntity, ...currentProxy };
  }

  const handleRowClick: React.MouseEventHandler<Element> = (event) => {
    const { handleProxySelect } = getProxyManagerState();
    event.preventDefault();

    if (isSelectProxyModeOpened) {
      toggleIsProxySelected(proxyItem.id);

      return selectProxiesWithShift({
        shiftKey: event.shiftKey,
        selectedProxies,
        proxiesToSelectFrom: proxyManagerListEntities.filter(isNotNull),
        proxy: proxyItem,
        lastSelectedProxy,
      });
    }

    if (handleProxySelect) {
      let proxyId = proxyEntity.id;
      if (!proxyId) {
        proxyId = getNoIdProxyId(proxyEntity);
      }

      handleProxySelect(proxyId);

      return;
    }

    if (currentProfileId) {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: 'select-proxy-manager-proxy' });

      selectProfileProxy(currentProfileId, proxyItem);
      closeProxyManager();
    }
  };

  const handleContextMenu: React.MouseEventHandler<Element> = (event) => {
    event.preventDefault();
    updateSelectedProxies({ selectedProxy: proxyEntity });
    updateProxySelectMenuPosition({ x: event.pageX, y: event.pageY });
    openProxyContextMenu();
  };

  const isProxyManagerWithoutGroups = !('groupId' in proxyEntity);
  if (isProxyManagerWithoutGroups) {
    return (
      <UngroupedProxyItem
        key={proxyEntity.id}
        ungroupedProxy={proxyEntity}
        currentProfileId={currentProfileId}
        handleRowClick={handleRowClick}
        handleContextMenu={handleContextMenu}
        style={styleBase}
      />
    );
  }

  if (getIsProxyGroupHeader(proxyEntity)) {
    return (
      <ProxyGroupHeader
        key={proxyEntity.id}
        proxyGroupHeader={proxyEntity}
        currentProfileId={currentProfileId}
        availableTypes={proxyEntity.types}
        style={styleBase}
      />
    );
  }

  if (getIsProxyGroupAddButton(proxyEntity)) {
    return (
      <ProxyGroupAddButton
        key={proxyEntity.id}
        country={proxyEntity.country || DEFAULT_SELECTED_GEOPROXY_COUNTRY}
        groupId={proxyEntity.groupId}
        availableTypes={proxyEntity.types}
        style={styleBase}
      />
    );
  }

  if (proxyEntity.groupId) {
    return (
      <GroupedProxyItem
        key={proxyEntity.id}
        groupedProxy={proxyEntity}
        currentProfileId={currentProfileId}
        handleRowClick={handleRowClick}
        handleContextMenu={handleContextMenu}
        style={styleBase}
        // selectorContainerElement={localProxyElementLocation} // TODO: needed?
      />
    );
  }

  return (
    <UngroupedProxyItem
      key={proxyEntity.id}
      ungroupedProxy={proxyEntity}
      currentProfileId={currentProfileId}
      handleRowClick={handleRowClick}
      handleContextMenu={handleContextMenu}
      style={styleBase}
    />
  );
};

export default memo(ProxyRow, areEqual);
