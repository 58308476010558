/* eslint-disable id-length */
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { GEOPROXY_COUNTRY_KEY_NAME, GEOPROXY_TYPE_ADDED_LAST_KEY_NAME } from '../../../common/constants/local-storage';
import { GeoProxyType, getIsGeoProxyType } from '../../../common/constants/types';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY, DEFAULT_SELECTED_GEOPROXY_TYPE } from '../../features/proxy/constants';

const geoProxyLastSelectedCountryAtom = atom<string>(
  localStorage.getItem(GEOPROXY_COUNTRY_KEY_NAME) ?? DEFAULT_SELECTED_GEOPROXY_COUNTRY,
);

const initGeoProxyLastSelectedTypeAtom = (): GeoProxyType => {
  let geoProxyLastSelectedType: GeoProxyType|null = null;
  const geoProxyLastSelectedTypeInStorage = localStorage.getItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME);
  if (geoProxyLastSelectedTypeInStorage && getIsGeoProxyType(geoProxyLastSelectedTypeInStorage)) {
    geoProxyLastSelectedType = geoProxyLastSelectedTypeInStorage;
  }

  return geoProxyLastSelectedType ?? DEFAULT_SELECTED_GEOPROXY_TYPE;
};

const geoProxyLastSelectedTypeAtom = atom<GeoProxyType>(initGeoProxyLastSelectedTypeAtom());

const geoProxyLastSelectedCountryPersistentAtom = atom(
  (get) => get(geoProxyLastSelectedCountryAtom),
  (_get, set, newCountry: string) => {
    set(geoProxyLastSelectedCountryAtom, newCountry);
    localStorage.setItem(GEOPROXY_COUNTRY_KEY_NAME, newCountry);
  },
);

const geoProxyLastSelectedTypePersitentAtom = atom(
  (get) => get(geoProxyLastSelectedTypeAtom),
  (_get, set, newType: GeoProxyType) => {
    set(geoProxyLastSelectedTypeAtom, newType);
    localStorage.setItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME, newType);
  },
);

export const setGeoProxyLastSelectedCountry = (country: string): void => getDefaultStore().set(geoProxyLastSelectedCountryPersistentAtom, country);
export const useGeoProxyLastSelectedCountry = (): string => useAtomValue(geoProxyLastSelectedCountryPersistentAtom);

export const setGeoProxyLastSelectedType = (newType: GeoProxyType): void => getDefaultStore().set(geoProxyLastSelectedTypePersitentAtom, newType);
export const getGeoProxyLastSelectedType = (): GeoProxyType => getDefaultStore().get(geoProxyLastSelectedTypePersitentAtom);
export const useGeoProxyLastSelectedType = (): GeoProxyType => useAtomValue(geoProxyLastSelectedTypePersitentAtom);
