import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { IProxy } from '../../../../interfaces';
import { IBasicTableEntity } from '../../../../state/profiles-list.atom';
import { useProxyList } from '../../../../state/proxy/proxy-list.atom';
import { TextCell } from '../../table/styles';

interface IProps {
  profileAtom: IBasicTableEntity['atom'];
  paramName: keyof IProxy;
}

const ProxyParamCell: FC<IProps> = (props) => {
  const {
    profileAtom,
    paramName,
  } = props;

  let { proxy } = useAtomValue(profileAtom);
  const proxyList = useProxyList();

  // TODO: this logic is cloned in many places
  let proxyRes = proxy;
  if (proxyRes.id) {
    proxyRes = proxyList.find((listedProxy) => listedProxy.id === proxyRes.id);
    if (!proxyRes) {
      proxyRes = proxy;
    }
  }

  proxy = proxyRes;

  if (!proxy || proxy.mode === 'none') {
    return (
      <div></div>
    );
  }

  if (NEW_FEATURES.header) {
    return (
      <TextCell>
        {proxy[paramName]}
      </TextCell>
    );
  }

  return (
    <div>
      {proxy[paramName]}
    </div>
  );
};

export default ProxyParamCell;
