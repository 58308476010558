import { PROXY_GROUP_ADD_BUTTON_ID_POSTFIX, PROXY_GROUP_HEADER_ID_POSTFIX } from './constants';
import { GeoProxyType } from '../../../../common/constants/types';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { ProxyMode } from '../../../interfaces';

export interface IProxyManagerListEntityBase {
  id: string;
  groupId: string;
  mode: ProxyMode;
  country: string;
  selectionDate: number;
}

export interface IProxyGroupHeader extends IProxyManagerListEntityBase {
  types: GeoProxyType[];
}

export interface IProxyGroupAddButton extends IProxyManagerListEntityBase {
  types: GeoProxyType[];
}

type IProxyWithoutIdAndCountry = Omit<IProxy, 'id'|'country'>;
export interface IGroupedProxy extends IProxyManagerListEntityBase, IProxyWithoutIdAndCountry {}
export interface IUngroupedProxy extends Omit<IProxyManagerListEntityBase, 'groupId'>, IProxyWithoutIdAndCountry {
  groupId: null;
}

export type IProxyManagerGroupedEntity = IProxyGroupHeader|IGroupedProxy|IProxyGroupAddButton;
export type IProxyManagerListEntity = IUngroupedProxy|IProxyManagerGroupedEntity;

export interface IProxyGroup {
  groupId: string;
  header: IProxyGroupHeader;
  proxies: IGroupedProxy[];
  addButton: IProxyGroupAddButton;
}

export type GroupedProxyGroupMode = 'geolocationProxies';
export type UngroupedProxyGroupMode = 'gologinProxies'|'torProxies'|'userProxies';
export type ProxyGroupMode = GroupedProxyGroupMode|UngroupedProxyGroupMode;

export type IProxyGroupsObject = Record<UngroupedProxyGroupMode, IUngroupedProxy[]> & Record<GroupedProxyGroupMode, Record<string, IProxyGroup>>;

export const getIsProxyGroup = (proxyEntity: IProxyGroup|IUngroupedProxy): proxyEntity is IProxyGroup => !!proxyEntity.groupId;

export const getIsProxyGroupHeader = (proxyEntity: IProxyManagerListEntity): proxyEntity is IProxyGroupHeader =>
  proxyEntity.id?.endsWith(PROXY_GROUP_HEADER_ID_POSTFIX);

export const getIsProxyGroupAddButton = (proxyEntity: IProxyManagerListEntity): proxyEntity is IProxyGroupAddButton =>
  proxyEntity.id?.endsWith(PROXY_GROUP_ADD_BUTTON_ID_POSTFIX);
