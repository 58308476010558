import { IProxyModalSize } from '../interfaces/proxy-modal-size.interface';

export const UNGROUPED_PROXY_MANAGER_SIZE: IProxyModalSize = {
  width: 330,
  height: 278,
};

export const GROUPED_PROXY_MANAGER_SIZE: IProxyModalSize = {
  width: 320,
  height: 271,
};
