import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { useGeoProxyLastSelectedType } from '../../../../state/proxy/geoproxy-form-data.atom';
import { useProxyContextMenuProxy } from '../../../../state/proxy/proxy-context-menu.atom';
import { createGeoProxy } from '../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { hideProxyContextMenu } from '../../../../state/proxy-select-menu.atom';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../constants';

const PROXY_GROUP_ADD_BUTTON_STYLES: React.CSSProperties = {
  height: 36,
  margin: '4px 0 4px 42px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
};

interface IProxyGroupAddButton {
  country: string;
  groupId: string;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupAddButton: React.FC<IProxyGroupAddButton> = (props) => {
  const { country = DEFAULT_SELECTED_GEOPROXY_COUNTRY, groupId, availableTypes, style } = props;

  const proxyContextMenuProxy = useProxyContextMenuProxy();
  const geoProxyLastSelectedType = useGeoProxyLastSelectedType();

  const handleClick = async (): Promise<void> => {
    if (proxyContextMenuProxy) {
      hideProxyContextMenu();
    }

    if (!availableTypes.includes(geoProxyLastSelectedType)) {
      message.error(<Trans i18nKey='tableProfiles.notification.proxyNotFoundForCountryAndType' />);
      // TODO:
      // sendReactErrorToSentry({
      //   transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      //   message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      //   tags: [
      //     [PROXY_ID_TAG, proxy.id],
      //     [GEOPROXY_TYPE_TAG, proxy.connectionType],
      //     [PROXY_COUNTRY_TAG, proxy.country],
      //     [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      //   ],
      // });

      return;
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country,
      connectionType: geoProxyLastSelectedType,
      profileId: '',
      checkTooltipView: 'proxy-list-item', // TODO: can be other places as well
    });

    if (typeof geoProxyFormSubmitResult === 'string') {
      message.error(<Trans i18nKey={geoProxyFormSubmitResult} />);
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{ ...style, ...PROXY_GROUP_ADD_BUTTON_STYLES }}
    >
      <IconWrapperWithDescription
        iconColor='var(--B5B5BA-proxy-manager-placeholder)'
        iconHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        textColor='var(--81818A-proxy-manager-placeholder)'
        textHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        iconType='stroke'
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        <Trans i18nKey='proxies.proxyGroupAddButton' />
      </IconWrapperWithDescription>
    </div>
  );
};

export default ProxyGroupAddButton;
