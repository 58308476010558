import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { IInputWithPopover } from './interfaces';
import { PopoverInputContainer } from './styles';
import { IPosition } from '../modern-select/interfaces';
import { Mask, OptionsList } from '../modern-select/styles';

export const InputWithPopover: FC<IInputWithPopover> = (props) => {
  const {
    popupSlot, slotInputDecoration, slotInputInactive, anchorEl, optionsVisible, setOptionsVisible, onCancel, needCancelMask, popupMargin = '',
  } = props;

  const DEFAULT_POSITION: IPosition = { top: 0, left: 0 };
  const [optionsPosition, setOptionsPosition] = useState<IPosition>(DEFAULT_POSITION);
  const [inputPosition, setInputPosition] = useState<IPosition>(DEFAULT_POSITION);

  const inputRef = useRef<HTMLDivElement|null>(null);
  const optionsRef = useRef<HTMLDivElement|null>(null);

  useLayoutEffect(() => {
    const handleWindowResize = (): void => {
      if (!(optionsVisible && inputRef.current && optionsRef.current)) {
        return;
      }

      const inputCurrentPosition = inputRef.current.getBoundingClientRect();
      const relativeComponent = inputRef.current.parentElement?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const optionsHeight = optionsRef.current.offsetHeight;

      if (!relativeComponent) {
        return;
      }

      const marginFromElement = 8;
      const optionsLeftMargin = 16;
      const { top, left } = relativeComponent;
      let newTop = top + inputCurrentPosition.height + marginFromElement;

      if (windowHeight - inputCurrentPosition.bottom < (optionsHeight + marginFromElement)) {
        const relativeComponentHeight = inputCurrentPosition.top - top;
        newTop = inputCurrentPosition.top - optionsHeight - relativeComponentHeight - marginFromElement;
      }

      setInputPosition({ top: inputCurrentPosition.top, left: inputCurrentPosition.left });
      setOptionsPosition({ top: newTop, left: left - optionsLeftMargin });
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [optionsVisible]);

  const showPopover = (): void => {
    setOptionsVisible(true);
  };

  const renderPopover = (): JSX.Element|null => {
    if (!optionsVisible) {
      return null;
    }

    return ReactDOM.createPortal(
      <>
        <PopoverInputContainer style={{ ...inputPosition }}>
          {slotInputInactive({ autoFocus: true })}
          {slotInputDecoration ? slotInputDecoration() : null}
        </PopoverInputContainer>
        <OptionsList
          id='new-select-options'
          ref={optionsRef}
          optionsVisible={true}
          style={{ ...optionsPosition, margin: popupMargin, width: 'auto', maxHeight: '450px', cursor: 'default' }}
          onClick={(): void => anchorEl?.focus()}
        >
          {popupSlot()}
        </OptionsList>
        {optionsVisible ? <Mask onClick={(): void => {
          onCancel();

          if (needCancelMask) {
            setOptionsVisible(false);
          }
        }} /> : null}
      </>,
      document.body,
    );
  };

  return (
    <>
      <PopoverInputContainer
        style={{
          visibility: optionsVisible ? 'hidden' : 'visible',
          position: 'inherit',
          zIndex: optionsVisible ? 300 : 100,
        }}
      >
        <div ref={inputRef}>
          {slotInputInactive({ onClick: showPopover })}
        </div>
      </PopoverInputContainer>
      {renderPopover()}
    </>
  );
};
