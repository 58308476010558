import React, { MouseEvent } from 'react';
import { Trans } from 'react-i18next';

import { closeProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconPaste } from '../../../../../ui/gologin-header/icons';
import IconBack from '../../../../../ui/icons/IconBack';
import IconClean from '../../../../../ui/icons/IconClean';
import IconCopyProxy from '../../../../../ui/icons/IconCopyProxy';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyListHeaderBtnContainer, ProxyListHeaderBtnLeft, ProxyListHeaderBtnRight } from '../../proxy-list/styles/proxy-list';
import { FooterBtn, FooterMidBtnsContainer, ProxyEditFormFooterWrapper } from '../styles';

interface IProxyEditFormFooter {
  clearForm: () => void;
  copyProxy: () => void;
  handlePasteProxy: () => Promise<void|true>;
  handleClickSubmit: React.FormEventHandler<HTMLFormElement>;
}

const ProxyEditFormFooter: React.FC<IProxyEditFormFooter> = (props) => {
  const { clearForm, copyProxy, handlePasteProxy, handleClickSubmit } = props;

  const handleCancelClick = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    closeProxyManager();
  };

  return (
    <ProxyEditFormFooterWrapper>
      <ProxyListHeaderBtnContainer>
        <ProxyListHeaderBtnLeft onClick={handleClickSubmit}>
          <div style={{ marginRight: 10 }}>
            <IconBack />
          </div>
          <Trans i18nKey='proxies.done' />
        </ProxyListHeaderBtnLeft>
      </ProxyListHeaderBtnContainer>
      <FooterMidBtnsContainer>
        <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.pasteProxy' />}>
          <FooterBtn
            onClick={handlePasteProxy}
            type='button'
          >
            <IconPaste
              padding={0}
              iconColor='var(--CDCDCD-proxy-manager-update-form)'
              iconHoveredColor='var(--00A987-proxy-manager)'
            />
          </FooterBtn>
        </TooltipWrapper>
        <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.clearForm' />}>
          <FooterBtn
            onClick={clearForm}
            type='button'
          >
            <IconClean />
          </FooterBtn>
        </TooltipWrapper>
        <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.copyProxy' />}>
          <FooterBtn
            onClick={copyProxy}
            type='button'
          >
            <IconCopyProxy />
          </FooterBtn>
        </TooltipWrapper>
      </FooterMidBtnsContainer>
      <ProxyListHeaderBtnContainer>
        <ProxyListHeaderBtnRight onClick={handleCancelClick}>
          <Trans i18nKey='proxies.cancel' />
        </ProxyListHeaderBtnRight>
      </ProxyListHeaderBtnContainer>
    </ProxyEditFormFooterWrapper>
  );
};

export default ProxyEditFormFooter;
