import { Icon, message, Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { OptionProps } from 'antd/es/select';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GeoProxyType } from '../../../../../common/constants/types';
import { IProxyTraffic } from '../../../../interfaces';
import { useIsTrafficDataLoading, useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { getVpnUfoCountries, getVpnUfoProxy } from '../api';
import ProxyChecker from '../components/proxy-checker';
import {
  LoaderDivProxy,
  Component,
  GetProxyBtn,
  InLineComponent,
  RadioButton,
  RowComponent,
  SubTitle,
} from '../domElements';
import { IGetProxy, IProxyChecked } from '../interfaces';
import { checkProxy, PROXY_CHECKED_INIT } from '../utils/check-proxy';

const { Option } = Select;

const DEFAULT_TRAFFIC_DATA: Pick<IProxyTraffic, 'trafficLimitBytes'|'trafficUsedBytes'> = {
  trafficLimitBytes: 0,
  trafficUsedBytes: 0,
};

type GeolocationProxyFormProps = {
  profileInfo: any;
  profileId?: string;
  updateProfileInfo: (params: any) => any;
  setCurrentDisplay: (params: 'form'|'result') => void;
  setProxyCurrent: (params: any) => void;
  setInitialCheck: (params: boolean) => void;
  setProxyLoading: (params: boolean) => void;
  isSaveButtonPressed?: boolean;
  geolocationProxy: any;
  setIsGeolocationScreen?: (arg: boolean) => void;
  saveProfile?: () => void;
  isCreateProfile?: boolean;
  connection: GeoProxyType;
  setConnection: (arg: GeoProxyType) => void;
  currentDisplay: 'result'|'form';
  currentProxyCountry: string;
  setCurrentProxyCountry: (arg: string) => void;
  isTrialOrFreePlan: boolean;
}

export const GeolocationProxyForm: React.FC<GeolocationProxyFormProps> = (props) => {
  const {
    profileInfo, profileId, updateProfileInfo, setCurrentDisplay, setProxyCurrent, setInitialCheck, setProxyLoading,
    isSaveButtonPressed, geolocationProxy, setIsGeolocationScreen, saveProfile, isCreateProfile, connection, setConnection,
    currentDisplay, currentProxyCountry, setCurrentProxyCountry, isTrialOrFreePlan,
  } = props;

  const [country, setCountry] = useState<string>('');
  const [isAbleToUseMobileProxy, setIsAbleToUseMobileProxy] = useState<boolean>(true);
  const [isAbleToUseResidentProxy, setIsAbleToUseResidentProxy] = useState<boolean>(true);
  const [disableSelects, setDisableSelects] = useState<boolean>(true);
  const [proxyChecked, setProxyChecked] = useState<IProxyChecked>(PROXY_CHECKED_INIT);
  const [countryOptions, setCountryOptions] = useState<JSX.Element[]>([]);

  const { t: translation } = useTranslation();

  const trafficData = useTrafficData();
  const isTrafficDataLoading = useIsTrafficDataLoading();

  useEffect(() => {
    if (isTrialOrFreePlan) {
      setConnection(GeoProxyType.DataCenter);
    }

    setCurrentProxyCountry(geolocationProxy.country);
  }, []);

  useEffect(() => {
    setDisableSelects(true);

    if (isTrialOrFreePlan) {
      setIsAbleToUseResidentProxy(false);
      setIsAbleToUseMobileProxy(false);

      checkTrafficData()
        .catch(() => null)
        .finally(() => getCountryList().catch(() => null));
    } else {
      getCountryList().catch(() => null);
    }
  }, [connection]);

  // Весь треш, который происходит в этом компоненте связан с одной фичей - создать geo прокси если юзер выбрал меню
  // gologin proxy и нажал save или create profile, не находя прокси вручную. Достигается это следующим методом - мы нажимаем на save и функция
  // saveProfile не отрабатывает, потому что мы делаем там return в зависимости от условий. Условий 2 штуки - мы находимся
  // на экране gologin proxy и у нас открыта форма выбора прокси. после того как мы сделали return в saveProfile, в случае с
  // vpnUfo мы ждем когда создастся прокси и вызываем saveProfile еще раз из этого компонента, чтобы сохранить в профиль.
  // В случае с luminati - мы делаем фейковую прокси на беке и вызываем saveProfile(это изза того, что люминати прокси - долго ищутся),
  // затем на беке находится нормальная прокси и подставляется в профиль

  useEffect(() => {
    if (isSaveButtonPressed && isCreateProfile) {
      updateProfileStateAndCreate().catch(() => null);

      return;
    }

    if (!isSaveButtonPressed || (geolocationProxy.country && (currentDisplay === 'result' || disableSelects))) {

      return;
    }

    let getProxyOptions: IGetProxy = {
      isQuickSave: true,
      isFake: true,
    };

    if (connection !== GeoProxyType.DataCenter) {
      getProxy().then(() => {
        setIsGeolocationScreen && setIsGeolocationScreen(false);
        saveProfile && saveProfile();
      });

      return;
    }

    getProxy({}, getProxyOptions)
      .then((result) => {
        // eslint-disable-next-line no-undefined
        if (result.port === undefined) {
          return;
        }

        updateProfileInfo({
          proxyEnabled: true,
          proxy: {
            ...profileInfo.proxy,
            port: result.port,
            host: result.host,
            username: result.username,
            password: result.password,
            mode: 'geolocation',
          },
        });

        getProxyOptions = {
          isQuickSave: true,
          isFake: false,
          username: result.username,
          password: result.password,
          fakeProxyId: result.fakeProxyId,
        };

        getProxy({}, getProxyOptions).catch(() => null);
        saveProfile && saveProfile();
      });
  }, [isSaveButtonPressed]);

  const updateProfileStateAndCreate = async (): Promise<void> => {
    await updateProfileInfo({
      geoProxyInfo: {
        connection,
        country,
      },
    });

    saveProfile && saveProfile();
  };

  const checkTrafficData = async (): Promise<void> => {
    if (!trafficData.availableForPurchase.length || isTrafficDataLoading) {
      return;
    }

    const { residentTrafficData = DEFAULT_TRAFFIC_DATA, mobileTrafficData = DEFAULT_TRAFFIC_DATA } = trafficData;
    if (residentTrafficData.trafficUsedBytes < residentTrafficData.trafficLimitBytes) {
      setIsAbleToUseResidentProxy(true);
    }

    if (mobileTrafficData.trafficUsedBytes < mobileTrafficData.trafficLimitBytes) {
      setIsAbleToUseMobileProxy(true);
    }
  };

  const filterOption = (input: string, option: ReactElement<OptionProps>): boolean =>
    (option.props.value || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    (option.props.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getProxy = async (event: any = {}, options: IGetProxy = {}, isWithCheck = true): Promise<any> => {
    setDisableSelects(true);
    setProxyLoading(true);
    setProxyChecked(PROXY_CHECKED_INIT);
    event.stopPropagation && event.stopPropagation();
    event.preventDefault && event.preventDefault();

    isWithCheck && updateProfileInfo({
      geoProxyInfo: {
        isLoading: true,
      },
    });

    let optionsToCheckProxy = {};
    const isCurrentProxyCountryAvailable = countryOptions.find((option) => option.key === currentProxyCountry);

    const parameters: any = {
      countryCode: isCurrentProxyCountryAvailable ? currentProxyCountry : country,
      browserId: profileId || '',
      isMobile: connection === GeoProxyType.Mobile,
      isDC: connection === GeoProxyType.DataCenter,
    };

    const resultProxy = await getVpnUfoProxy(parameters).catch((error: any) =>
      error.message || error.body?.message,
    );

    if (options.isFake) {
      return resultProxy;
    }

    if (options.isQuickSave) {
      return;
    }

    if (!(resultProxy && Object.keys(resultProxy).length && typeof resultProxy !== 'string')) {
      message.error(resultProxy);
      setDisableSelects(false);
      setProxyLoading(false);

      return;
    }

    const { proxy } = profileInfo;
    updateProfileInfo({
      proxyEnabled: true,
      proxy: {
        ...proxy,
        ...resultProxy,
        mode: 'geolocation',
      },
    });

    optionsToCheckProxy = {
      proxy: resultProxy,
      setProxyChecked: (res: any) => setProxyCurrent({
        ...res,
        connectionType: connection,
        trafficUsed: resultProxy.trafficUsed || '',
        trafficLimit: resultProxy.trafficLimit || 0,
      }),
    };

    const processCbs = (): null => null;
    const resCbs = (): void => {
      setDisableSelects(false);
      setProxyLoading(false);
      isWithCheck && updateProfileInfo({
        geoProxyInfo: {
          isLoading: false,
        },
      });
    };

    const successCbs = (): void => {
      setCurrentDisplay('result');
      setInitialCheck(true);
    };

    checkProxy(optionsToCheckProxy, processCbs, resCbs, translation, successCbs);
  };

  const changeProxyCountry = (countryCode: string): void => {
    setCurrentProxyCountry('');
    setCountry(countryCode);
  };

  const getCountryList = async (): Promise<void> => {
    let query = '';
    if (connection === GeoProxyType.Mobile) {
      query = '?isMobile=true';
    }

    const onlyDCisAvailable = !(isAbleToUseMobileProxy || isAbleToUseResidentProxy);
    const isDC = connection === GeoProxyType.DataCenter || onlyDCisAvailable;
    if (isDC) {
      query = '?isDC=true';
    }

    setDisableSelects(true);
    const countryList = await getVpnUfoCountries(query);
    const [firstCountry = { countryCode: 'no options available' }] = countryList;
    const { countryCode: firstCountryCode } = firstCountry;
    setCountry(firstCountryCode);
    const countryOptionsArray: JSX.Element[] = countryList.map((countryFromRequest) => (
      <Option
        key={countryFromRequest.countryCode}
        value={countryFromRequest.countryCode}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {countryFromRequest.countryName}
      </Option>
    ));

    setCountryOptions(countryOptionsArray);
    setDisableSelects(false);
  };

  const getContent = (): JSX.Element => {
    if (disableSelects) {
      return (
        <LoaderDivProxy>
          <Icon type='loading' />
        </LoaderDivProxy>
      );
    }

    const isCurrentProxyCountryAvailable = countryOptions.find((option) => option.key === currentProxyCountry);

    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <RowComponent>
            <InLineComponent>
              <SubTitle>
                {translation('base.country')}
              </SubTitle>
              <Select
                style={{ width: 188 }}
                value={isCurrentProxyCountryAvailable ? currentProxyCountry : country}
                onChange={(targetCountry: string): void => changeProxyCountry(targetCountry)}
                showSearch={true}
                filterOption={filterOption}
                disabled={disableSelects}
              >
                {countryOptions}
              </Select>
            </InLineComponent>
          </RowComponent>
          <Component>
            <SubTitle>
              {translation('base.type')}
            </SubTitle>
            <Radio.Group
              value={connection}
              disabled={disableSelects}
              onChange={(event: RadioChangeEvent): void => setConnection(event.target.value)}
            >
              <RadioButton value={GeoProxyType.Resident} disabled={!isAbleToUseResidentProxy}>
                <Trans i18nKey={`proxyTypes.${GeoProxyType.Resident}`} />
              </RadioButton>
              <RadioButton style={{ width: 100 }} value={GeoProxyType.Mobile} disabled={!isAbleToUseMobileProxy}>
                <Trans i18nKey={`proxyTypes.${GeoProxyType.Mobile}`} />
              </RadioButton>
              <RadioButton value={GeoProxyType.DataCenter}>
                <Trans i18nKey={`proxyTypes.${GeoProxyType.DataCenter}`} />
              </RadioButton>
            </Radio.Group>
          </Component>
          <Component style={{ flexDirection: 'row', alignItems: 'center' }}>
            <GetProxyBtn
              type='primary'
              onClick={getProxy}
              disabled={disableSelects}
            >
              {translation('proxy.btnSaveProxy')}
            </GetProxyBtn>
          </Component>
        </div>
        <ProxyChecker
          isLoading={false}
          proxyChecked={proxyChecked}
          setCurrentDisplay={setCurrentDisplay}
          geolocationShowOption={true}
          initialCheck={false}
          setProxyChecked={setProxyCurrent}
        />
      </div>
    );
  };

  return getContent();
};
