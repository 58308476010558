import React from 'react';

import { ProxyConnectedStatusWrapper, ProxyTitleWrapper, UnroupedProxyItemWrapper } from './styles';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { useIsProxyRestoring } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IUngroupedProxy } from '../../../../../state/proxy/proxy-groups/interfaces';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import CheckProxyBtn from '../../../check-proxy-button';
import ProxyConnectionButton from '../../../proxy-connection-button';
import ProxyConnectedStatus from '../../../proxy-connection-button/proxy-connected-status';
import ProxyUnlinkButton from '../../../proxy-connection-button/proxy-unlink-button';
import ProxyEditButton from '../../../proxy-edit-button';
import ProxyFlag from '../../../proxy-flag';
import { getIsProxyEditable, makeProxyTitle, getIsProxyArchived, restoreProxy } from '../../../proxy-helpers';
import { ProxyStatusWrapper } from '../../../styles';
import ProxyItemCheckbox from '../proxy-item-checkbox';
import ProxyProfilesCounter from '../proxy-profiles-counter';
import { NoGroupsProxyRightControlsWrapper, ProxyInteractiveProfilesCounterWrapper } from '../styles';

const preventDefaultAndPropagation: React.MouseEventHandler<Element> = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

type UngroupedProxyItemProps = {
  ungroupedProxy: IUngroupedProxy;
  currentProfileId: string|null;
  isCurrentProxy: boolean;
  isCheckboxVisible: boolean;
  handleRowClick: React.MouseEventHandler<HTMLDivElement>;
  handleContextMenu: React.MouseEventHandler<HTMLDivElement>;
  localProxyElementLocation: string | null;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
}

const UngroupedProxyItem: React.FC<UngroupedProxyItemProps> = (props) => {
  const {
    ungroupedProxy,
    currentProfileId,
    isCurrentProxy,
    isCheckboxVisible,
    handleRowClick,
    handleContextMenu,
    localProxyElementLocation,
    selectorContainerElement,
    style,
  } = props;

  const isProxyRestoring = useIsProxyRestoring(ungroupedProxy.id);

  const title = makeProxyTitle(ungroupedProxy);
  const isProxyEditable = getIsProxyEditable(ungroupedProxy);
  const profilesCounter = ungroupedProxy.profilesCount || 0;
  const finalStyle: React.CSSProperties = {
    ...style,
    paddingLeft: 0,
    height: 38,
    margin: '1px 4px',
  };

  if (!NEW_FEATURES.proxyGroups) {
    finalStyle.height = 41;
    finalStyle.margin = 0;
  }

  const handleProxyInfoEditClick: React.MouseEventHandler<Element> = async (event) => {
    preventDefaultAndPropagation(event);
    let proxyToEdit = ungroupedProxy;
    if (getIsProxyArchived(ungroupedProxy)) {
      proxyToEdit = await restoreProxy(ungroupedProxy);
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxyToEdit,
      currentProfileId,
      modalEditingProxyId: proxyToEdit.id,
      proxySelectorLocation: localProxyElementLocation,
    });
  };

  const renderLeftControls = (): JSX.Element => {
    if (isCheckboxVisible) {
      return (
        <ProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
          <ProxyItemCheckbox
            proxy={ungroupedProxy}
            isCheckboxVisible={isProxyEditable}
          />
        </ProxyStatusWrapper>
      );
    }

    return (
      <ProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
        <CheckProxyBtn
          proxy={ungroupedProxy}
          profileId={currentProfileId || ''}
          proxyTooltipView='proxy-list-item'
          selectorContainerElement={selectorContainerElement}
        />
      </ProxyStatusWrapper>
    );
  };

  const renderRightControls = (): JSX.Element => {
    if (!NEW_FEATURES.proxyGroups) {
      return (
        <NoGroupsProxyRightControlsWrapper>
          <ProxyProfilesCounter
            profilesCounter={profilesCounter}
            isProxyEditable={isProxyEditable}
          />
          {isProxyEditable ? (
            <ProxyEditButton
              handleProxyInfoEditClick={handleProxyInfoEditClick}
              isProxyRestoring={isProxyRestoring}
            />
          ): null}
          {isCurrentProxy ? (
            <ProxyConnectionButton
              profileId={currentProfileId || ''}
              unlinkIconType='cross'
            />
          ) : null}
        </NoGroupsProxyRightControlsWrapper>
      );
    }

    if (isCurrentProxy) {
      return (
        <ProxyConnectedStatusWrapper>
          {isProxyEditable ? (
            <ProxyEditButton
              handleProxyInfoEditClick={handleProxyInfoEditClick}
              isProxyRestoring={isProxyRestoring}
            />
          ) : null}
          <ProxyUnlinkButton
            profileId={currentProfileId || ''}
            iconType='cross'
          />
          <ProxyConnectedStatus />
        </ProxyConnectedStatusWrapper>
      );
    }

    return (
      <ProxyInteractiveProfilesCounterWrapper onClick={preventDefaultAndPropagation}>
        {isProxyEditable ? (
          <ProxyEditButton
            handleProxyInfoEditClick={handleProxyInfoEditClick}
            isProxyRestoring={isProxyRestoring}
          />
        ) : null}
        <ProxyProfilesCounter
          profilesCounter={profilesCounter}
          isProxyEditable={isProxyEditable}
        />
      </ProxyInteractiveProfilesCounterWrapper>
    );
  };

  return (
    <UnroupedProxyItemWrapper
      style={finalStyle}
      isAlwaysOpaque={false}
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
      isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}
      isFailed={ungroupedProxy.status === false}
      isHighlighted={NEW_FEATURES.proxyGroups ? isCurrentProxy : false}
    >
      {renderLeftControls()}
      <ProxyFlag
        countryCode={ungroupedProxy.country}
        width={NEW_FEATURES.proxyGroups ? 18 : 14}
      />
      <ProxyTitleWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroups}>
        {title}
      </ProxyTitleWrapper>
      {renderRightControls()}
    </UnroupedProxyItemWrapper>
  );
};

export default UngroupedProxyItem;
