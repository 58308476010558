import { GeoProxyType } from '../../../../common/constants/types';
import { IGeolocationProxy } from '../../../interfaces';

export const proxyTrafficData: Record<GeoProxyType, keyof IGeolocationProxy> = {
  mobile: 'mobileTrafficData',
  resident: 'residentTrafficData',
  dataCenter: 'dataCenterTrafficData',
};

export const proxyTypeToRender: Record<GeoProxyType, string> = {
  mobile: 'Mobile',
  resident: 'Residential',
  dataCenter: 'Data center',
};
