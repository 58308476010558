import React from 'react';
import { Trans } from 'react-i18next';

import GeoProxyProxyTypesList from './geo-proxy-types-list';
import { BuyProxyPopoverCustom, Description, ItemsContainer, Title, TrafficRequestErrorText, UnderlinedText } from './styles';
import { history } from '../../../services';
import { useIsTrafficDataLoading, useTrafficData } from '../../../state/proxy/traffic-data.atom';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { IconSpinner } from '../icons';

type BuyProxyProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const BuyProxy: React.FC<BuyProxyProps> = (props) => {
  const { anchorEl, onClose } = props;

  const theme = useCurrentTheme();
  const trafficData = useTrafficData();
  const isTrafficDataLoading = useIsTrafficDataLoading();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const onClickToNewProfile = (): void => {
    onClose();
    history.push('/newProfile?proxy=geolocation');
  };

  const showContent = (): JSX.Element => {
    if (isTrafficDataLoading) {
      return (
        <div style={{ fontSize: 24, textAlign: 'center' }}>
          <IconSpinner
            size={24}
            padding={0}
          />
        </div>
      );
    }

    if (!trafficData.availableForPurchase.length) {
      return (
        <TrafficRequestErrorText>
          <Trans i18nKey='modals.proxyTrafficModal.trafficRequestError' />
        </TrafficRequestErrorText>
      );
    }

    return (
      <>
        <Title>
          <Trans i18nKey='trafficModal.availableForNow' />
        </Title>
        <ItemsContainer>
          <GeoProxyProxyTypesList trafficData={trafficData} />
        </ItemsContainer>
        <Description>
          <Trans i18nKey='trafficModal.subject' />
          &nbsp;
          <UnderlinedText onClick={onClickToNewProfile}>
            <Trans i18nKey='trafficModal.subjectLink' />
          </UnderlinedText>
        </Description>
      </>
    );
  };

  return (
    <BuyProxyPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ zIndex: 1100 }}
      isDarkTheme={isDarkTheme}
    >
      {showContent()}
    </BuyProxyPopoverCustom>
  );
};

export default BuyProxy;

