import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxyFormContainerEl = styled('div')<{ addMaxWidth: boolean; newStyle: boolean; isEditProxy: boolean }>`
  display: flex;
  align-items: center;

  ${(props): any => css`
    ${(props.addMaxWidth) ? '' : `
      max-width: 100%;
    `};
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    background-color: inherit;
  ` : null}
`;

export const ProxyFormEl = styled('form')`
  position: relative;
  height: 16px;
  display: block;
`;

export const ProxySelectorLeftControlsWrapper = styled.div<{ isProxyManagerWithGroups: boolean; isFailed?: boolean }>`
  margin-left: ${(props): number => props.isProxyManagerWithGroups ? -38 : -32}px;

  white-space: nowrap;
  display: flex;
  align-items: center;

  ${(props): SerializedStyles => css`
    opacity: ${props.isFailed ? 0.5 : 1};
  `}

  tr:hover, .gologin-table-row & {
    opacity: 1;
  }
`;

export const ProxyTitleBlockEl = styled('div')<{ isFailed?: boolean }>`
  height: 16px;

  display: flex;
  align-items: center;

  letter-spacing: 0.25px;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props): SerializedStyles => css`
    opacity: ${props.isFailed ? 0.5 : 1};
  `}

  .ant-drawer-body & {
    max-width: 246px;
  }

  .proxy-popper-container  & {
    min-width: 0;
  }

  .nowrap {
    line-height: normal;
    height: 16px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ProxyTitleWrapperEl = styled('div')<{ newStyle: boolean; noProxy?: boolean }>`
  ${(props): SerializedStyles => css`
    ${(props.noProxy) ? '' : `
      width: auto;
      min-width: calc(100% - 48px);
      padding-top: 2px;
    `};
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    padding-top: 0;
    width: auto;
    min-width: calc(100% - 48px);
  ` : null}

  .proxy-popper & {
    padding-top: 0;
  }
`;
