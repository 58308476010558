import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { proxyListAtom } from './proxy-list.atom';
import { IProxy } from '../../interfaces';
import { filterProxies } from '../../utils/proxy-string';

export const proxySearchAtom = atom<string>('');
export const searchedProxiesAtom = atom<IProxy[]>((get) => {
  const proxyList = get(proxyListAtom);
  const searchValue = get(proxySearchAtom);

  return filterProxies(proxyList, searchValue);
});

const setProxySearch = (searchValue: string): void => getDefaultStore().set(proxySearchAtom, searchValue);

export const useSearchedProxies = (): IProxy[] => useAtomValue(searchedProxiesAtom);

export const searchProxy = (searchValue: string): void => setProxySearch(searchValue);
export const cleareProxySearch = (): void => setProxySearch('');
