import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getProxyList, updateProxyItem, upsertOneProxy } from './proxy-list.atom';
import { closeProxyManager, getProxyManagerState, openProxyManagerListView } from './proxy-manager-modal-status.atom';
import { handleUpdateProxiesRequestError } from './proxy-operations/handle-proxies-update-error';
import { updateProxyWithErrorHandling } from './proxy-operations/update-proxies.operations';
import { closeProxySettingsModal } from './proxy-settings-modal.atom';
import { mapAndSetProxiesTableList } from './proxy-table/proxies-table-list.atom';
import { createProxiesRequest } from '../../features/proxy/api';
import { DEFAULT_PROXY_FORM_VALUES } from '../../features/proxy/constants';
import { handleProxiesLimitError } from '../../features/proxy/utils/handle-proxies-limit-error';
import { IProxy } from '../../interfaces';
import { isProxyDataValid } from '../../utils/proxy.validation-message';

const proxyFormAtom = atom<IProxy>(DEFAULT_PROXY_FORM_VALUES);

const getProxyForm = (): IProxy => getDefaultStore().get(proxyFormAtom);
export const setProxyForm = (proxy: IProxy): void => getDefaultStore().set(proxyFormAtom, proxy);
export const useProxyForm = (): IProxy => useAtomValue(proxyFormAtom);

interface ISubmitParams {
  proxyId: string;
  isProxyPage: boolean;
  profileId?: string;
}

export const handleProxyFormSubmit = async ({ proxyId, profileId = '', isProxyPage }: ISubmitParams): Promise<IProxy|void> => {
  const {
    customName,
    mode,
    host,
    port,
    username,
    password,
    changeIpUrl,
  } = getProxyForm();

  const { modalView } = getProxyManagerState();
  const proxyList = getProxyList();

  if (!(host || port || username || password)) {
    return isProxyPage ? closeProxySettingsModal() : closeProxyManager();
  }

  const isProxyValid = isProxyDataValid({ host, port: port.toString() });
  if (!isProxyValid) {
    return;
  }

  const proxyItem = {
    mode,
    customName: (customName || '').trim(),
    host: host.replaceAll(' ', ''),
    port: parseInt(`${port}`.replaceAll(' ', ''), 10),
    username: (username || '').replaceAll(' ', ''),
    password: (password || '').replaceAll(' ', ''),
    changeIpUrl: (changeIpUrl || '').replaceAll(' ', ''),
  };

  if (proxyId && modalView === 'proxy-edit') {
    const selectedProxy = proxyList.find(listedProxy => listedProxy.id === proxyId);

    if (
      selectedProxy &&
      selectedProxy.mode === proxyItem.mode &&
      selectedProxy.customName === proxyItem.customName &&
      selectedProxy.host === proxyItem.host &&
      selectedProxy.port === proxyItem.port &&
      selectedProxy.username === proxyItem.username &&
      selectedProxy.password === proxyItem.password &&
      selectedProxy.changeIpUrl === proxyItem.changeIpUrl
    ) {
      return isProxyPage ? closeProxySettingsModal() : openProxyManagerListView();
    }
  }

  let newProxy: IProxy;
  if (proxyId) {
    newProxy = await updateProxyWithErrorHandling({ proxyId: proxyId, proxy: proxyItem, profileId });
    if (newProxy?.error) {
      handleUpdateProxiesRequestError(newProxy);

      return;
    }

    // something with perfomance (long timing to save edited proxy)
    if (!isProxyPage) {
      updateProxyItem(newProxy);
    }

    mapAndSetProxiesTableList((prevProxies) => prevProxies.map((prevProxy) => {
      if (prevProxy.id === newProxy.id) {
        return { ...prevProxy, ...newProxy };
      }

      return prevProxy;
    }), [], isProxyPage);
  } else {
    const proxyCreationResult = await createProxiesRequest([proxyItem]).catch(error => handleProxiesLimitError(error));
    if (!Array.isArray(proxyCreationResult)) {
      return;
    }

    [newProxy] = proxyCreationResult;
    upsertOneProxy(newProxy);
  }

  isProxyPage ? closeProxySettingsModal() : openProxyManagerListView();

  return newProxy;
};
