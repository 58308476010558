import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconProxyResident: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_11681_146928)'>
        <path d='M3.5 15.5H12.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.5 12.5V15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.5 12.5V15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.5 9.5H15.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M14.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V11.5C0.5 12.0523 0.947715 12.5 1.5 12.5H14.5C15.0523 12.5 15.5 12.0523 15.5 11.5V1.5C15.5 0.947715 15.0523 0.5 14.5 0.5Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  </IconWrapper>
);
