import { MS_IN_SECOND } from './constants/constants';
import {
  convertProfileToArtificialProxyId,
  convertProxyFromArtificialProxyId,
  convertFromArtificialProxyIds,
  convertProfileFromArtificialProxyId,
} from '../app/features/proxy/utils/proxy-id';
import { ITag, TAG_FIELDS } from '../app/features/tags/interfaces/tag.interface';
import { IProfile, IProxy } from '../app/interfaces';
import { ACCESS_TOKEN } from '../app/services/http/config';

export const wait = (delay: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, delay));

export const safeParseJSON = <T>(data: string): T|null => {
  try {
    const result = JSON.parse(data);

    return result;
  } catch (error) {
    return null;
  }
};

export const safeStringify = <T>(data: T): string | null => {
  try {
    const result = JSON.stringify(data);

    return result;
  } catch (error) {
    return null;
  }
};

export const getCurrentTimestampForSentry = (): number => (performance.now() + performance.timeOrigin) / MS_IN_SECOND;

export const isUserAuthenticated = (): boolean => !!localStorage.getItem(ACCESS_TOKEN);

export const normalizeTags = (tags: any): ITag[] => tags.map((tag: any) => {
  const tagId = tag._id || tag.id;
  delete tag._id;

  return {
    field: TAG_FIELDS[0],
    ...tag,
    id: tagId,
  };
});

export const normalizeProfilesData = (profiles: IProfile[]): IProfile[] => profiles.map((profile) => {
  if (!profile.tags) {
    profile.tags = [];
  }

  profile.tags = normalizeTags(profile.tags);
  profile = convertProfileToArtificialProxyId(profile);

  return {
    ...profile,
    order: profile.order ?? 1000,
  };
});

export const normalizeProfile = (profile: IProfile): IProfile => {
  const normalizedProfile = convertProfileFromArtificialProxyId(profile);

  return normalizedProfile;
};

export const denormalizeProfile = (profile: IProfile): IProfile => {
  const denormalizedProfile = convertProfileToArtificialProxyId(profile);

  return denormalizedProfile;
};

export const normalizeProxyIds = (proxyIds: string[]): string[] => {
  const normalizedProxyIds = convertFromArtificialProxyIds(proxyIds);

  return normalizedProxyIds;
};

export const normalizeProxy = <ProxyType extends Pick<IProxy, 'id'>>(proxy: ProxyType): ProxyType => {
  const normalizedProxy = convertProxyFromArtificialProxyId(proxy);

  return normalizedProxy;
};
