import moment from 'moment';

import { upsertProxyAfterCreating } from './create-proxies.operations';
import { selectProfileProxy, selectProfileProxyInState, unselectProfileProxyInState } from './select-proxies.operations';
import { GeoProxyType } from '../../../../common/constants/types';
import { getVpnUfoProxy } from '../../../features/profileSettingsComponents/proxyTab/api';
import { IVpnUfoProxyOptions } from '../../../features/profileSettingsComponents/proxyTab/interfaces/vpn-ufo.interfaces';
import { updateProxyRequest } from '../../../features/proxy/api';
import { IProfileProxy } from '../../../features/proxy/components/interfaces/IProfileProxy';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import {
  GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
  GEOPROXY_TYPE_TAG,
  PROXY_CHECK_ERROR_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_ID_TAG,
} from '../../../features/proxy/constants';
import { mockGeoProxyId } from '../../../features/proxy/utils/proxy-id';
import { generateGeoProxyCustomName } from '../../../features/proxy/utils/proxy-title';
import { IGeneratedGeoProxy } from '../../../interfaces';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { pushProxies, removeProxiesFromList, updateProxyInListById, updateProxyItem } from '../proxy-list.atom';
import { setProxyManagerCurrentProxy } from '../proxy-manager-modal-status.atom';

const getGeoProxyWithCredentials = async (
  country: string,
  connectionType: GeoProxyType,
  profileId: string,
): Promise<IGeneratedGeoProxy> => {
  const parameters: IVpnUfoProxyOptions = {
    countryCode: country,
    browserId: profileId,
    isMobile: connectionType === 'mobile',
    isDC: connectionType === 'dataCenter',
  };

  return getVpnUfoProxy(parameters);
};

interface IGeoProxySubmitParams {
  groupId: string;
  profileId: string;
  country: string;
  connectionType: GeoProxyType;
  checkTooltipView: ProxyCheckTooltipView;
}

interface IGeoProxyMockParams {
  country: string;
  connectionType: GeoProxyType;
  profilesCount: number;
  groupId: string;
}

// TODO: somehow remove while keeping the new proxies correctly sorted (depends on Sergey's decision on sorting)
let counter = 0;
const mockGeoProxy = ({ country, connectionType, profilesCount, groupId }: IGeoProxyMockParams): IProfileProxy => {
  counter--;
  const proxyId = mockGeoProxyId();
  const customName = generateGeoProxyCustomName(country);

  return {
    id: proxyId,
    groupId,
    host: '',
    port: 80,
    connectionType,
    mode: 'geolocation',
    customName,
    country,
    profilesCount,
    selectionDate: counter,
  };
};

const handleGeoProxyWithCredentialsError = (mockedProxy: IProfileProxy, profileId: string): void => {
  removeProxyStatuses([mockedProxy], [profileId]);
  removeProxiesFromList([mockedProxy.id]);
  if (profileId) {
    unselectProfileProxyInState(profileId);
  }
};

const checkGeoProxy = async (proxy: IProxy, profileId: string, checkTooltipView: ProxyCheckTooltipView): Promise<string|void> => {
  const checkedProxy = await updateProxyStatuses([proxy], profileId, false).catch(() => ({ status: false, error: '' })); // TODO: wtb shared?
  const checkedFullProxy: IProxy = { ...proxy, ...checkedProxy, checkDate: moment().toDate() };
  if (!(checkedProxy && checkedProxy.status)) {
    sendReactErrorToSentry({
      transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      tags: [
        [PROXY_ID_TAG, proxy.id],
        [GEOPROXY_TYPE_TAG, proxy.connectionType],
        [PROXY_COUNTRY_TAG, proxy.country],
        [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      ],
    });

    return 'tableProfiles.notification.proxyNotFoundForCountryAndType';
  }

  showProxyCheckTooltip({
    profileIds: [profileId || ''],
    proxies: [checkedFullProxy],
    view: checkTooltipView,
    timeout: 2000,
  });
};

type TranslationKey = string;

export const createGeoProxy = async ({
  groupId,
  profileId,
  country,
  connectionType,
  checkTooltipView,
}: IGeoProxySubmitParams): Promise<IProxy|TranslationKey|null> => {
  const profilesCount = profileId ? 1 : 0;
  const mockedProxy = mockGeoProxy({
    country,
    connectionType,
    groupId,
    profilesCount,
  });

  addProxyStatuses([mockedProxy], [profileId]);
  if (profileId) {
    upsertProxyAfterCreating([mockedProxy]); // TODO: replace by `pushProxies` and move from condition
    selectProfileProxyInState(profileId, mockedProxy);
  } else {
    pushProxies([mockedProxy]);
  }

  const geoProxyWithCredentialsResponse = await getGeoProxyWithCredentials(country, connectionType, profileId).catch((error) => {
    handleGeoProxyWithCredentialsError(mockedProxy, profileId);
    let errorMessage = 'tableProfiles.notification.trafficLimit';
    if (typeof error?.body?.message === 'string') {
      errorMessage = error.body.message;
    }

    return errorMessage;
  });

  if (typeof geoProxyWithCredentialsResponse === 'string') {
    return geoProxyWithCredentialsResponse;
  }

  const proxyWithCredentials = geoProxyWithCredentialsResponse;
  const fullProxy: IProxy = {
    ...mockedProxy,
    ...proxyWithCredentials,
    mode: 'geolocation',
  };

  const checkResult = await checkGeoProxy(fullProxy, profileId, checkTooltipView);
  if (typeof checkResult === 'string') {
    handleGeoProxyWithCredentialsError(mockedProxy, profileId);

    return checkResult;
  }

  updateProxyInListById(mockedProxy.id, fullProxy);
  removeProxyStatuses([mockedProxy], [profileId]);
  if (profileId) {
    setProxyManagerCurrentProxy(fullProxy);
    selectProfileProxy(profileId, fullProxy);
  }

  const proxyCreated = await updateProxyRequest(
    fullProxy.id,
    fullProxy,
    profileId,
  );

  updateProxyItem({ ...fullProxy, ...proxyCreated });

  return { ...proxyWithCredentials, profilesCount };
};
