import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { theme } from '../../../proxy-helpers';

const proxyListItemHoveredStyles = css`
  color: ${theme.colors.primary};
  outline: none;
  text-decoration: none;
  background: var(--F9F9F9-proxy-manager-list-item);

  .proxy-flag-block,
  .flag-item,
  .proxy-title {
    opacity: 1;
    color: ${theme.colors.primary};
  }

  .flag-item {
    color: var(--D9D9D9-proxy-manager-list-item);
  }

  .proxy-selected {
    display: none;
  }

  .proxy-update-btn {
    display: flex;

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  .proxy-unlink-button {
    display: flex;
  }

  .proxy-circle-button {
    display: none;
  }
`;

// const oldProxyListItemEl = css`
// ${optionCommonStyles}
// padding: 6px 0;

// ${isCurrent ? css`
//   background: #F9F9F9;
// ` : null}

// &:hover {
//   color: #36363D;
//   background: #F2F2F2;
// }
// `;

// TODO: export?
export const proxyListItemActiveStyles = css`
  background-color: var(--F2F2F2-proxy-list-item-active);

  .proxy-update-btn {
    display: flex;
  }

  .proxy-group-expand-button,
  .proxy-profiles-counter-wrapper {
    display: none;
  }
`;

export const ProxyListItemEl = styled.a<{
  isProxyGroups: boolean;
  shouldHoverTheEntireRow: boolean;
  isCurrent: boolean;
  isHoverable?: boolean;
  maxWidth?: number;
}>`
  ${({
    isProxyGroups,
    shouldHoverTheEntireRow,
    isCurrent,
    isHoverable,
    maxWidth,
  }): SerializedStyles => isProxyGroups ? css`
    height: 36px;
    padding: 0 0 0 4px;
    border-radius: 4px;
    border: 0.5px solid red;
    margin-bottom: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
      ${shouldHoverTheEntireRow ? css`
        ${proxyListItemActiveStyles}  
      ` : css`
        .proxy-item-wrapper {
          ${proxyListItemActiveStyles}
        }
      `}
    }
  ` : css`
    max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
    height: 41px;
    border-top: 1px solid var(--EBEBEB-proxy-manager-list-item);

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    color: var(--222222-proxy-manager-list-item);

    & .proxy-title {
      padding-right: ${theme.spacing(2)}px;
    }

    &:first-of-type {
      border-top: 1px solid transparent;
    }

    .proxy-selected {
      color: ${theme.colors.primary};
    }

    .proxy-update-btn {
      display: none;
      color: var(--767676-proxy-manager-list-item);
    }

    .proxy-unlink-button {
      display: none;
    }

    .proxy-circle-button {
      display: flex;
    }

    &:hover {
      ${isHoverable ? proxyListItemHoveredStyles : null}
    }
  `}
`;

export const ProxyListItemInGroup = styled.a<{
  shouldHoverTheEntireRow: boolean;
  isCurrent: boolean;
  isHoverable?: boolean;
  maxWidth?: number;
}>`
  padding: 0 0 0 4px;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    ${(props): SerializedStyles => props.shouldHoverTheEntireRow ? css`
      ${proxyListItemActiveStyles}
    ` : css`
      .proxy-item-wrapper {
        ${proxyListItemActiveStyles}
      }
    `}
  }
`;

export const ProxyListItemInnerEl = styled('div')<{ isProxyGroups: boolean; newStyle: boolean; isHoverable?: boolean }>`
  max-width: 278px;
  padding: ${(props): string => props.isProxyGroups ? '10px 16px 10px 36px' : '10px 16px'};
  ${(props): SerializedStyles|null => props.isProxyGroups ? css`
    border-radius: 4px;
  ` : null}
  margin-left: ${(props): string => props.newStyle && !props.isProxyGroups ? '16px' : '0'};

  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props): SerializedStyles|null => props.isHoverable && !props.isProxyGroups ? null : css`
    .proxy-title {
      color: var(--222222-proxy-manager-list-item);
    }
  `}

  ${(props): SerializedStyles|null => props.isProxyGroups ? css`
    width: 271px;
    max-width: none;
    flex-shrink: 1;
  ` : null}
`;

export const ProxyButtonsContainerEl = styled('div')`
  display: flex;
  align-items: flex-end;
  padding-right: 24px;
  justify-content: flex-end;
  cursor: default;
  flex-shrink: 0;

  & > * + * {
    margin-left: 14px;
  }
`;

export const ProxyButtonEl = styled('div')<{ success?: boolean }>`
  width: 18px;
  height: 18px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${(props): SerializedStyles => css`
    ${(props.success) ? `
      color: ${theme.colors.primary};
    `: ''}
  `}

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const RemoveButtonEl = styled('div')`
  width: 18px;
  height: 18px;
  padding: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }

  & > svg {
    width: 18px;
    height: 18px;
  }
`;

export const ProxyFlagBlockEl = styled('div')<{ isFailed?: boolean; newStyle: boolean }>`
  white-space: nowrap;
  display: flex;
  align-items: center;

  ${(props): SerializedStyles => css`
    opacity: ${(props.isFailed) ? '0.5' : '1'};
  `}

  tr:hover, .gologin-table-row & {
    opacity: 1;
  }

  background-color: ${(props): string|null => props.newStyle ? 'inherit' : null};
`;

export const ProfilesCounterWrapper = styled.span`
  color: var(--B5B5BA-proxy-profiels-counter);
`;
