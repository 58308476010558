import { atom, getDefaultStore } from 'jotai';

import { IGroupedProxy, IProxyManagerListEntity, IUngroupedProxy } from './interfaces';
import { PROXY_SELECTION_DATES_KEY_NAME } from '../../../../common/constants/local-storage';
import { getProxyGroupId } from '../../../features/proxy/utils/proxy-id';

type ProxySelectionDates = Record<IProxyManagerListEntity['id'], number>;
const DEFAULT_PROXY_SELECTION_DATES: ProxySelectionDates = {};

const initDefaultProxySelectionDatesAtom = (): ProxySelectionDates => {
  const selectionDatesJson = localStorage.getItem(PROXY_SELECTION_DATES_KEY_NAME);
  if (!selectionDatesJson) {
    return DEFAULT_PROXY_SELECTION_DATES;
  }

  let selectionDates = DEFAULT_PROXY_SELECTION_DATES;
  try {
    selectionDates = JSON.parse(selectionDatesJson);
  } catch (error) {
    void null;
  }

  return selectionDates;
};

const proxySelectionDatesAtom = atom<ProxySelectionDates>(initDefaultProxySelectionDatesAtom());

export const proxySelectionDatesPersistentAtom = atom(
  (get) => get(proxySelectionDatesAtom),
  (get, set, datesUpdated: ProxySelectionDates) => {
    const proxySelectionDatesCurrent = get(proxySelectionDatesAtom);
    const proxySelectionDatesUpdated: ProxySelectionDates = { ...proxySelectionDatesCurrent, ...datesUpdated };
    set(proxySelectionDatesAtom, proxySelectionDatesUpdated);

    try {
      const proxySelectionDatesUpdatedJson = JSON.stringify(proxySelectionDatesUpdated);
      localStorage.setItem(PROXY_SELECTION_DATES_KEY_NAME, proxySelectionDatesUpdatedJson);
    } catch (error) {
      void null;
    }
  },
);

const setProxySelectionDates = (datesUpdated: ProxySelectionDates): void =>
  getDefaultStore().set(proxySelectionDatesPersistentAtom, datesUpdated);

export const updateProxySelectionDate = (proxy: IGroupedProxy|IUngroupedProxy): void => {
  if (proxy.mode === 'none') {
    return;
  }

  const incrementKeys = [];
  if (proxy.id) {
    incrementKeys.push(proxy.id);
  }

  if (proxy.groupId) {
    incrementKeys.push(proxy.groupId);
  } else {
    const country = proxy.country || proxy.autoProxyRegion || proxy.torProxyRegion;
    if (country) {
      const groupId = getProxyGroupId(proxy.mode, country);
      incrementKeys.push(groupId);
    }
  }

  const dateNew = +(new Date());
  const newSelectionDates = Object.fromEntries(incrementKeys.map(key => ([key, dateNew])));
  setProxySelectionDates(newSelectionDates);
};
