import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IProxy } from '../../features/proxy/components/interfaces/IProxy';
import { DEFAULT_PROXY_FORM_VALUES } from '../../features/proxy/constants';

const proxyFormAtom = atom<IProxy>(DEFAULT_PROXY_FORM_VALUES);

export const getProxyForm = (): IProxy => getDefaultStore().get(proxyFormAtom);
export const setProxyForm = (proxy: IProxy): void => getDefaultStore().set(proxyFormAtom, proxy);
export const useProxyForm = (): IProxy => useAtomValue(proxyFormAtom);
