import styled from '@emotion/styled';

export const EmptyProxyFlagWrapper = styled.div`
  display: inline-block;
  text-align: center;

  & > svg {
    vertical-align: top;
  }
`;
