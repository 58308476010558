import styled from '@emotion/styled';

export const RemovingConfirmEl = styled('section')`
  width: 100%;
  height: 100%;
  
  background: var(--FFFFFF-proxy-manager-removing-confirm);
  
  position: absolute;
  left: 0;
  top: 0;
  z-index: 300;
  
  display: flex;
  align-items: center;
`;

export const RemovingConfirmContentEl = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const RemovingConfirmTitleEl = styled('div')`
  text-align: center;
  width: 100%;
`;

export const RemovingConfirmButtonsEl = styled('div')`
  text-align: center;
  width: 100%;
  margin-top: 16px;
`;

export const BtnDelete = styled('button')`
  color: var(--767676-proxy-manager-removing-confirm);
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 8px;
  padding: 3px 12px;
  border: 1px solid var(--CDCDCD-proxy-manager-removing-confirm);
  border-radius: 14px;

  &:hover {
    color: var(--F5222D-proxy-manager-removing-confirm);
    border: 1px solid var(--F5222D-proxy-manager-removing-confirm);
  }
`;

export const BtnCancel = styled('button')`
  color: var(--CDCDCD-proxy-manager-removing-confirm);
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0 8px;
  padding: 3px 0;

  &:hover {
    color: var(--222222-proxy-manager-removing-confirm);
  }
`;
