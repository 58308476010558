import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { NEW_FEATURES } from '../../../../../../../feature-toggle';
import { optionsListCommonStyles } from '../../../../../ui/modern-select/styles';
import { theme } from '../../../proxy-helpers';
import { GROUPED_PROXY_MANAGER_SIZE, UNGROUPED_PROXY_MANAGER_SIZE } from '../../styles';

const [PROXY_LIST_WIDTH, PROXY_LIST_HEIGHT] = NEW_FEATURES.proxyGroups ?
  [UNGROUPED_PROXY_MANAGER_SIZE.width, UNGROUPED_PROXY_MANAGER_SIZE.height]:
  [GROUPED_PROXY_MANAGER_SIZE.width, GROUPED_PROXY_MANAGER_SIZE.height];

export const ProxyListContainer = styled.div`
  width: ${PROXY_LIST_WIDTH}px;
  height: ${PROXY_LIST_HEIGHT}px;
  overflow-y: scroll;
`;

export const ProxyListHeaderBtnContainer = styled('div')`
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const ProxyListContentEl = styled.div<{ isProxyGroups: boolean }>`
  height: ${({ isProxyGroups }): string => isProxyGroups ? '260px' : '278px'};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ${({ isProxyGroups }): SerializedStyles|null => isProxyGroups ? css`
    ${optionsListCommonStyles}
    display: flex;
  ` : null}
`;

export const ProxyListHeaderEl = styled('header')`
  height: 56px;
  padding: 16px 16px 0;
  display: flex;
  border-top: 1px solid var(--EBEBEB-proxy-manager-list);
`;

export const ProxyListHeaderBtnLeft = styled('button')`
  color: var(--767676-proxy-manager-list);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-right: 11px;
  background: none;
  border: none;
  padding: 1px 0 0;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &:focus,
  &:visited {
    color: var(--767676-proxy-manager-list);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: ${theme.colors.primary};
    outline: none;
    text-decoration: none;
  }
`;

export const ProxyListHeaderBtnRight = styled('a')`
  color: var(--767676-proxy-manager-list);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background: none;
  border: none;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 1px;
  padding-right: 5px;

  &:focus,
  &:visited {
    color: var(--767676-proxy-manager-list);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: ${theme.colors.primary};
    outline: none;
    text-decoration: none;
  }
`;
