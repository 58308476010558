import moment from 'moment';

import { isNotNull } from '../../../../common/typescript/predicates';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { setProxyStatuses } from '../../proxies.context/api';
import { IProxyStatusParams } from '../../proxies.context/interfaces/proxy-status-params.interface';
import { getProxyList, updateProxyInListById } from '../proxy-list.atom';

// TODO: kinda the same with updateProxyStatuses
export const checkProxies = async (proxies: IProxy[]): Promise<void> => {
  const proxyList = getProxyList();

  const proxiesChecked: (IProxyStatusParams|null)[] = await Promise.all(proxies.map(async proxy => {
    if (!proxy.id) {
      return null;
    }

    const currentProxyFromList = proxyList.find(proxyFromList => proxyFromList.id === proxy.id);
    if (!currentProxyFromList || currentProxyFromList.checkDate) {
      return null;
    }

    updateProxyInListById(proxy.id, { checkInProgress: true });
    const statusParams = await getProxyStatusParams({ ...proxy });
    updateProxyInListById(proxy.id, {
      ...statusParams,
      checkDate: moment().toDate(),
      checkInProgress: false,
    });

    if (!statusParams) {
      return null;
    }

    return {
      id: proxy.id,
      status: statusParams.status,
      country: statusParams.country || proxy.country,
      city: statusParams.city || proxy.city,
      error: statusParams.error,
      checkDate: moment().unix(),
      lastIp: statusParams.origin,
    };
  }));

  setProxyStatuses(proxiesChecked.filter(isNotNull)).catch(() => null);
};
