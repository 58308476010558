import { message } from 'antd';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProxyManagerListEntities } from '../../../state/proxy/proxy-groups/proxy-groups.atom';
import { selectAllProxies, updateSelectedProxies, useSelectedProxies } from '../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible, switchIsSelectProxyModeOpened, useIsSelectProxyModeOpened } from '../../../state/proxy-select-menu.atom';
import { IconBinTrashDelete, IconClose } from '../../../ui/gologin-header/icons';
import { IconProxyCopy } from '../../../ui/gologin-header/icons/icon-proxy-copy';
import IconSelectAll from '../../../ui/gologin-header/icons/icon-select-all';
import { IIconWrapper } from '../../../ui/gologin-header/icons/wrapper';
import TooltipWrapper from '../../../ui/tooltip-custom/tooltip-wrapper';
import { copyProxies } from '../proxy-helpers';
import { FooterBtn } from '../proxy-manager/proxy-edit-view/styles';
import { ButtonsContainerEl, ProxyListMenuEl } from '../styles/proxy-list-menu';

const ProxyListMenu: FC = () => {
  const { selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const proxyManagerListEntities = useProxyManagerListEntities();

  const { t: translation } = useTranslation();

  const handleCancel = (): void => {
    updateSelectedProxies({
      selectedProxy: null,
      selectedProxies: [],
    });
    switchIsSelectProxyModeOpened(false);
  };

  const handleSelectAll = (): void => {
    // TODO: type properly before the proxyGroups release
    const proxies = proxyManagerListEntities.filter((entity) => entity && entity.host);
    if (selectedProxies.length === proxies.length) {
      updateSelectedProxies({
        selectedProxies: [],
      });
    } else {
      selectAllProxies();
    }
  };

  const handleDelete = (): void => {
    if (!selectedProxies.length) {
      message.error(translation('proxies.noSelectedProxies'));

      return;
    }

    updateSelectedProxies({
      selectedProxy: null,
    });
    switchConfirmBlockVisible(true);
  };

  const handleCopy = (): void => {
    // TODO: type properly before the proxyGroups release
    const proxies = proxyManagerListEntities.filter(proxyEntity => proxyEntity && selectedProxies.includes(proxyEntity.id));

    if (proxies.length) {
      copyProxies(proxies);
      message.success(translation('base.copiedText'));
    }
  };

  const footerIconProps: IIconWrapper = {
    padding: 0,
    styleType: 'lightGray',
    iconColor: NEW_FEATURES.proxyGroups ?
      'var(--B5B5BA-header-account-menu)' : 'var(--CDCDCD-proxy-manager-update-form)',
    iconHoveredColor: NEW_FEATURES.proxyGroups ?
      'var(--767676-proxy-group-header-arrow)' : 'var(--00A987-proxy-manager)',
  };

  const footerButtons = [{
    title: translation('proxies.selectAllProxies'),
    onClick: handleSelectAll,
    icon: <IconSelectAll {...footerIconProps} />,
  }, {
    title: (selectedProxies) ? translation('proxies.copyProxy') : translation('proxies.selectProxiesToCopy'),
    onClick: handleCopy,
    icon: <IconProxyCopy {...footerIconProps} />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    title: (selectedProxies) ? translation('proxies.deleteProxies') : translation('proxies.selectProxiesToDelete'),
    onClick: handleDelete,
    icon: <IconBinTrashDelete {...footerIconProps} />,
    tooltipTrigger: (selectedProxies) ? 'hover' : 'click',
  }, {
    title: translation('proxies.cancel'),
    onClick: handleCancel,
    icon: <IconClose {...footerIconProps} />,
  }];

  if (!isSelectProxyModeOpened) {
    return null;
  }

  return (
    <ProxyListMenuEl>
      <ButtonsContainerEl>
        {footerButtons.map((button): JSX.Element => (
          <TooltipWrapper key={button.title} isTransition={true} value={<Trans i18nKey={button.title} />}>
            <FooterBtn
              onClick={button.onClick}
              type='button'
            >
              {button.icon}
            </FooterBtn>
          </TooltipWrapper>
        ))}
      </ButtonsContainerEl>
    </ProxyListMenuEl>
  );
};

export default ProxyListMenu;
