import * as Sentry from '@sentry/react';

import { LOAD_TRAFFIC_DATA_TRANSACTION } from '../../../features/proxy/constants';
import { getTrafficData } from '../../../ui/gologin-header/buy-proxy/api';
import { TRAFFIC_DATA_DEFAULT, updateTrafficData } from '../traffic-data.atom';

export const loadTrafficData = async (): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_TRAFFIC_DATA_TRANSACTION });

  const trafficData = await getTrafficData();
  const normalizedTrafficData = typeof trafficData === 'string' ?
    TRAFFIC_DATA_DEFAULT : trafficData;

  updateTrafficData(normalizedTrafficData);

  transaction.finish();
};
